import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchProductClients = createAsyncThunk<
  { isLastPage: boolean; list: ProductClientDocument[]; total: number },
  { productId: string; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchProductClients`,
  async (
    { productId, ownerId },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const { productClientsListSize } = getState().product;
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('status', '==', 'active')
        .orderBy('createdAt', 'desc')
        .limit(productClientsListSize);

      const res = await ref.get();
      const list = res.docs.map((doc) => firestoreDateMapper<ProductClientDocument>(doc));

      if (!res.empty) {
        firestoreSubscriptions.lastProductClientCursor = res.docs[res.docs.length - 1];
      }

      const totalActiveRef = await db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('status', '==', 'active')
        .orderBy('createdAt', 'desc')
        .get();

      return {
        list,
        isLastPage: list.length < productClientsListSize,
        total: totalActiveRef.size,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
