import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductContentDocument,
} from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import {
  subscribeToProductContentFailed,
  subscribeToProductContentStarted,
  subscribeToProductContentSuccess,
} from '../reducer';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type ProductId = string;

export const subscribeToProductContent = createAsyncThunk<
  void,
  { productId: ProductId; ownerId?: string },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/subscribeToProductContent`,
  async ({ productId, ownerId }, { dispatch, extra: { db, firestoreSubscriptions, auth } }) => {
    try {
      dispatch(subscribeToProductContentStarted());
      const user = auth().currentUser;
      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      firestoreSubscriptions.productContentListener?.();

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

      firestoreSubscriptions.productContentListener = ref.onSnapshot(
        (snapshot) => {
          dispatch(
            subscribeToProductContentSuccess(
              snapshot.exists ? firestoreDateMapper<ProductContentDocument>(snapshot) : null
            )
          );
        },
        () => {
          dispatch(subscribeToProductContentFailed());
        }
      );
    } catch {
      dispatch(subscribeToProductContentFailed());
    }
  }
);
