import { ProductDocumentV2, RequestStatus } from '@mailingr/data-models';

export interface ProductsReducer {
  productsInfo: null;
  productsInfoStatus: null;
  productsListStatus: null | RequestStatus;
  productsListData: ProductDocumentV2[];
  addingNewProduct: boolean;
  productsError: null | Error;
  searchTerm: string | null;
}

export const PRODUCTS_REDUCER_NAME = 'Products';
