import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AutomationSettings,
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  settings: AutomationSettings;
};

export const updateProductAutomationSettings = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<unknown>
>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationSettings`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error('product automation data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);

      await ref.update({
        settings: { ...(data?.settings || {}), ...payload.settings },
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
