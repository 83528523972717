import { createAsyncThunk } from '@reduxjs/toolkit';

import { IntegrationClient } from '@mailingr/data-models';
import { cloudFunctionErrorHandler } from '@mailingr/utils';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload = {
  name: string;
  client: IntegrationClient;
  id: string;
};

export const refreshIntegrateWithExternalApi = createAsyncThunk<
  void,
  ActionParams<Payload, string>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/refreshIntegrateWithExternalApi`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-integrateWithExternalApi')({
        ...payload,
        refresh: true,
      });

      analytics.track('refresh_integrate_with_external_api', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.(cloudFunctionErrorHandler(e).code);

      return rejectWithValue(e);
    }
  }
);
