import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { validate } from 'uuid';

import { CreatorStatsDocument, FIRESTORE_COLLECTION, ProductDocument } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { ADMIN_REDUCER_NAME } from '../types';

type Payload = {
  searchTerm: string;
  isEmail?: boolean;
};

export const searchProductOrOwner: AsyncThunk<
  CreatorStatsDocument[],
  Payload,
  AsyncThunkCreator<string>
> = createAsyncThunk<CreatorStatsDocument[], Payload, AsyncThunkCreator<string>>(
  `${ADMIN_REDUCER_NAME}/searchProductOrOwner`,
  async ({ searchTerm, isEmail }, { extra: { db } }) => {
    let ref = db
      .collection(FIRESTORE_COLLECTION.REPORTS)
      .where('creatorId', '>=', searchTerm)
      .where('creatorId', '<=', searchTerm + '~')
      .withConverter(getDocumentConverter<CreatorStatsDocument>());

    if (isEmail || !validate(searchTerm)) {
      ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .where('creatorEmail', '>=', searchTerm)
        .where('creatorEmail', '<=', searchTerm + '~')
        .withConverter(getDocumentConverter<CreatorStatsDocument>());
    }

    if (searchTerm.startsWith('prod_')) {
      const productRef = db
        .collectionGroup(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .where('id', '==', searchTerm)
        .orderBy('createdAt', 'desc')
        .withConverter(getDocumentConverter<ProductDocument>());

      const snapshot = await productRef.get();

      const [doc] = snapshot.docs;

      const [_, ownerId] = doc.ref.path.split('/');

      ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .where('creatorId', '>=', ownerId)
        .where('creatorId', '<=', ownerId + '~')
        .withConverter(getDocumentConverter<CreatorStatsDocument>());
    }

    const data = await ref.get();

    return data.docs.map((doc) => doc.data());
  }
);
