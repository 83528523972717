import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, PaymentClient, ProductDocumentV2 } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  enabledPaymentGateways: PaymentClient[];
  paymentsMethods?: ProductDocumentV2['paymentsMethods'];
};

export const updateProductPaymentGateways = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/updateProductPaymentGateways`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { db, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId);

      await ref.update({
        enabledPaymentGateways: payload.enabledPaymentGateways,
        ...(payload.paymentsMethods ? { paymentsMethods: payload.paymentsMethods } : {}),
      });
      analytics.track('update_product_payment_gateways', {
        enabledPaymentGateways: payload.enabledPaymentGateways.join(', '),
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
