import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Button, Divider, Form, Input, Result, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AppStore, userActions, UserStatus } from '@mailingr/app-store';

import { useAppDispatch } from '~/initializeStore';
import * as styles from './LoginByEmail.module.scss';

interface OwnProps {}

type Props = OwnProps;

type LoginFormModel = {
  email: string;
};

const tailLayout = {
  wrapperCol: { offset: 16, span: 8 },
};

const LoginByEmail: FunctionComponent<Props> = () => {
  const { i18n, t } = useTranslation(['auth', 'common']);
  const dispatch = useAppDispatch();
  const [formController] = Form.useForm<LoginFormModel>();
  const [loading, toggleLoader] = useState(false);
  const [showSuccess, toggleSuccess] = useState(false);
  const { status: userStatus, error } = useSelector((store: AppStore) => store.user);
  const queryParams = new URLSearchParams(window.location.search);
  const emailInQuery = queryParams.get('email')?.replace(' ', '+') ?? '';
  const history = useHistory();
  const errorInQuery = queryParams.get('error') ?? null;
  const productId = queryParams.get('productId');

  useEffect(() => {
    if (errorInQuery) {
      dispatch(userActions.logInFailed(errorInQuery));
      const search = new URLSearchParams(window.location.search);
      search.delete('error');
      history.replace({
        search: search.toString(),
      });
    } else {
      dispatch(userActions.resetErrors());
    }
  }, [dispatch, history, errorInQuery]);

  useEffect(() => {
    if (emailInQuery) {
      setTimeout(() => {
        formController.submit();
        history.replace({
          search: '',
        });
      }, 0);
    }
  }, [formController, history, emailInQuery]);

  const onFinish = async (values: LoginFormModel) => {
    const validatedEmail = values.email.trim();
    toggleLoader(true);
    const splitUrl = (import.meta.env.VITE_EMBEDDED_FORM_DOMAIN as string)?.split('://');
    const redirectTo = `${splitUrl[0]}://app.${splitUrl[1]}/courses/${productId}`;
    await dispatch(
      userActions.sendLoginLink(
        validatedEmail,
        `${
          window.location.origin
        }/auth/sign-with-link?email=${validatedEmail}&redirectTo=${encodeURIComponent(redirectTo)}`,
        i18n.language
      )
    );
    toggleLoader(false);
    toggleSuccess(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        {showSuccess ? (
          <div>
            <Result
              status="success"
              title={t<string>('auth:sendLink.successTitle')}
              subTitle={t<string>('auth:sendLink.successMessage')}
              extra={<Link to="/">{t<string>('common:goToLoginPage')}</Link>}
            />
          </div>
        ) : (
          <>
            <Typography.Title level={4} className={styles.header}>
              {t<string>('loginByEmailPanel')}
            </Typography.Title>
            {error && (
              <Alert
                className={styles.alert}
                type={userStatus === UserStatus.HAS_ERROR ? 'error' : 'info'}
                message={
                  userStatus === UserStatus.HAS_ERROR ? (
                    t<string>(`common:firebaseAuthErrors.${error}`)
                  ) : (
                    <Trans t={t} i18nKey="common:startJourney" />
                  )
                }
              />
            )}

            <Form<LoginFormModel>
              name="login-form"
              form={formController}
              layout="vertical"
              onFinish={onFinish}
              className={styles.form}
              size="large"
              validateTrigger={['onSubmit', 'onBlur']}
              initialValues={{ email: emailInQuery }}
            >
              <Form.Item
                name="email"
                label={t<string>('login')}
                rules={[
                  {
                    required: true,
                    message: t<string>('validationErrors.fieldIsRequired'),
                  },
                  {
                    type: 'email',
                    message: t<string>('validationErrors.wrongEmail'),
                    transform: (value) => value.trim(),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className={styles.submitButton}
                  loading={loading}
                >
                  {t<string>('common:button.sendLink')}
                </Button>
              </Form.Item>
            </Form>

            <Divider>{t<string>('common:or')}</Divider>

            <div className={styles.register}>
              <Link to="/auth/register">{t<string>('common:button.register')}</Link>
              <h4 className={styles.registerClaim}>{t<string>('registerClaim')}</h4>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginByEmail;
