import {
  ProductClientDocument,
  ProductClientReferralsInfo,
  RequestStatus,
} from '@mailingr/data-models';

export const NEWSLETTER_REDUCER_NAME = 'Newsletter';

export interface NewsletterReducer {
  status: null | RequestStatus;
  data: ProductClientDocument | null;
  error: null | Error;
  referralProgramStatus: null | RequestStatus;
  referralProgram: ProductClientReferralsInfo;
}
