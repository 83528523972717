import { Community, RequestStatus } from '@mailingr/data-models';

export interface CommunitiesReducer {
  requestStatus: null | RequestStatus;
  data: Community[];
  adding: boolean;
  error: null | Error;
  searchTerm: string | null;
}

export const COMMUNITIES_REDUCER_NAME = 'Communities';
