import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductAutomationLogDocument, RequestStatus } from '@mailingr/data-models';

import { AUTOMATION_LOGS_REDUCER_NAME, AutomationLogsReducer } from './types';

const initialState: AutomationLogsReducer = {
  selectedClient: null,
  clientAutomationLogs: [],
  clientAutomationLogsStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: AUTOMATION_LOGS_REDUCER_NAME,
  reducers: {
    subscribeToClientLogsStarted(
      state,
      { payload }: PayloadAction<{ email: string; productId: string | null }>
    ) {
      state.clientAutomationLogsStatus = RequestStatus.SUBSCRIBING;
      state.selectedClient = payload;
    },
    subscribeToClientLogsSuccess(
      state,
      { payload }: PayloadAction<ProductAutomationLogDocument[]>
    ) {
      state.clientAutomationLogsStatus = RequestStatus.SUBSCRIBED;
      state.clientAutomationLogs = payload;
    },
    subscribeToClientLogsFailed(state, _action: PayloadAction<Error>) {
      state.clientAutomationLogsStatus = RequestStatus.FAILED;
    },
    unsubscribeFromClientLogs(state) {
      state.clientAutomationLogs = [];
      state.selectedClient = null;
      state.clientAutomationLogsStatus = null;
    },
  },
});

export const {
  subscribeToClientLogsStarted,
  subscribeToClientLogsSuccess,
  subscribeToClientLogsFailed,
  unsubscribeFromClientLogs,
} = reducerSlice.actions;
export default reducerSlice.reducer;
