import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import {
  ClientInvoiceData,
  FIRESTORE_COLLECTION,
  OrderDocument,
  ProductClientDocument,
} from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { ActionParams, AsyncThunkCreator } from '../../../index';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';

type Payload = {
  invoiceData: ClientInvoiceData;
  authorId: string;
  productId: string;
  clientEmail?: string;
  updateClientData?: boolean;
  orderId?: string;
  sellDate?: Dayjs;
  issueDate?: Dayjs;
};

export const updateInvoiceData = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${NEWSLETTERS_REDUCER_NAME}/updateInvoiceData`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      if (payload.updateClientData !== false) {
        const ref = db
          .collection(FIRESTORE_COLLECTION.USERS)
          .doc(payload.authorId)
          .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
          .doc(payload.productId)
          .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
          .doc(payload.clientEmail || user.email);

        await ref.withConverter<ProductClientDocument>(getDocumentConverter()).update({
          client: payload.invoiceData,
        });
      }

      if (payload.orderId) {
        const ref = db
          .collection(FIRESTORE_COLLECTION.USERS)
          .doc(payload.authorId)
          .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
          .doc(payload.productId)
          .collection(FIRESTORE_COLLECTION.PRODUCT_ORDERS)
          .doc(payload.orderId);

        const orderToUpdate: Partial<OrderDocument> = {
          client: payload.invoiceData,
        };

        if (payload.issueDate) {
          orderToUpdate.issueDate = payload.issueDate.unix();
        }

        if (payload.sellDate) {
          orderToUpdate.sellDate = payload.sellDate.unix();
        }

        await ref.withConverter<OrderDocument>(getDocumentConverter()).update(orderToUpdate);
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
