import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductClientToImportDocument } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  list: Omit<
    ProductClientToImportDocument,
    | 'importId'
    | 'total'
    | 'count'
    | 'status'
    | 'createdAt'
    | 'updatedAt'
    | 'errorMessage'
    | 'isFree'
    | 'productId'
    | 'ownerId'
  >[];
  productId: string;
};

export const importClientsList = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${PRODUCT_REDUCER_NAME}/importClientsList`,
  async (payload, { rejectWithValue, extra: { auth, functions, analytics } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      await functions.httpsCallable('products-scheduleImportClientsToProducts')({
        clients: payload.list,
        productId: payload.productId,
      });
      analytics.track('import_clients_to_products', {
        productId: payload.productId,
        count: payload.list.length,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
