import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ChannelId,
  CommunityId,
  ContentIdTreeNode,
  FIRESTORE_COLLECTION,
  GroupId,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { updateCommunityChannel } from '../../communityChannels/actions/updateCommunityChannel';
import { COMMUNITY_REDUCER_NAME } from '../types';

interface ApplyCommunityStructurePayload {
  communityId: CommunityId;
  updatedContentTree: ContentIdTreeNode[];
}

const findChannelsInTree = (
  tree: ContentIdTreeNode[],
  parentGroupId: GroupId | null = null
): Map<ChannelId, GroupId | null> => {
  const channelGroups = new Map<ChannelId, GroupId | null>();

  tree.forEach((node) => {
    if (node.type === 'channel') {
      channelGroups.set(node.id, parentGroupId);
    } else if (node.type === 'group') {
      const subGroups = findChannelsInTree(node.children, node.id);
      subGroups.forEach((groupId, channelId) => {
        channelGroups.set(channelId, groupId);
      });
    }
  });

  return channelGroups;
};

export const applyCommunityStructure = createAsyncThunk<
  void,
  ActionParams<ApplyCommunityStructurePayload>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_REDUCER_NAME}/applyCommunityStructure`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth }, dispatch }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged in');
      }

      const communityRef = db.collection(FIRESTORE_COLLECTION.COMMUNITIES).doc(payload.communityId);
      const communityDoc = await communityRef.get();
      const oldContentTree = communityDoc.data()?.contentTree || [];

      const oldChannelGroups = findChannelsInTree(oldContentTree);
      const newChannelGroups = findChannelsInTree(payload.updatedContentTree);

      for (const [channelId, newGroupId] of newChannelGroups) {
        const oldGroupId = oldChannelGroups.get(channelId);
        if (oldGroupId !== newGroupId) {
          dispatch(
            updateCommunityChannel({
              payload: {
                id: channelId,
                groupId: newGroupId,
                communityId: payload.communityId,
              },
            })
          );
        }
      }

      await communityRef.update({
        contentTree: payload.updatedContentTree,
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();
      // eslint-disable-next-line no-console
      console.error({ payload, error: e });

      return rejectWithValue(e);
    }
  }
);
