import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductReferralsDocument,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
} & Partial<ProductReferralsDocument>;

export const updateProductReferrals = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<void>
>(
  `${PRODUCT_REFERRALS_REDUCER_NAME}/updateProductReferrals`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productReferrals;

      if (!data) {
        throw new Error('product referrals data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_REFERRALS);

      await ref.update({
        ...payload,
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue();
    }
  }
);
