import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  guildId: string;
};

export const refreshDiscordIntegration = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/refreshDiscordIntegration`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-refreshIntegrationWithDiscord')(payload);

      analytics.track('refresh_connected_discord_server', {
        userId: user.uid,
        email: user.email,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
