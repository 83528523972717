import firebase from 'firebase/compat';

import { FIRESTORE_COLLECTION, UserActivityStatus } from '@mailingr/data-models';

type SetUserStatusManuallyParamsWithRTDB = {
  database: typeof firebase.database;
  userId?: string;
  state: UserActivityStatus;
};

type SetUserStatusManuallyParamsWithFirestore = {
  firestore: typeof firebase.firestore;
  userId?: string;
  state: UserActivityStatus;
};

export const setUserStatusManually = async (
  params: SetUserStatusManuallyParamsWithRTDB | SetUserStatusManuallyParamsWithFirestore
) => {
  if (!params.userId) {
    return;
  }
  if ('database' in params && params.database) {
    return params.database().ref(`/status/${params.userId}`).set({
      state: params.state,
      lastChanged: params.database.ServerValue.TIMESTAMP,
    });
  } else if ('firestore' in params) {
    return params
      .firestore()
      .collection(FIRESTORE_COLLECTION.USERS_PRESENCE)
      .doc(params.userId)
      .set({
        state: params.state,
        lastChanged: params.firestore.FieldValue.serverTimestamp(),
      });
  }
};
