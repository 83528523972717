import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductReferralsDocument, RequestStatus } from '@mailingr/data-models';

import { fetchReferralsRanking } from './actions/fetchReferralsRanking';
import { updateProductReferrals } from './actions/updateProductReferrals';
import { PRODUCT_REFERRALS_REDUCER_NAME, ProductReferralsReducer } from './types';

const initialState: ProductReferralsReducer = {
  status: null,
  data: null,
  error: null,
  ranking: [],
  rankingStatus: null,
};

const productReferralsSlice = createSlice({
  name: PRODUCT_REFERRALS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductReferralsStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = null;
    },
    subscribeToProductReferralsSuccess(
      state,
      { payload }: PayloadAction<ProductReferralsDocument | null>
    ) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToProductReferralsFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
    },
    unsubscribeFromProductReferrals(state) {
      state.status = null;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProductReferrals.pending, (state) => {
      state.status = RequestStatus.UPDATING;
    });
    builder.addCase(updateProductReferrals.fulfilled, (state) => {
      state.status = RequestStatus.SUBSCRIBED;
    });
    builder.addCase(updateProductReferrals.rejected, (state) => {
      state.status = RequestStatus.FAILED;
    });
    builder.addCase(fetchReferralsRanking.pending, (state) => {
      state.rankingStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchReferralsRanking.fulfilled, (state, { payload }) => {
      state.rankingStatus = RequestStatus.SUCCESS;
      state.ranking = payload;
    });
    builder.addCase(fetchReferralsRanking.rejected, (state) => {
      state.rankingStatus = RequestStatus.FAILED;
    });
  },
});

export const {
  subscribeToProductReferralsFailed,
  subscribeToProductReferralsStarted,
  subscribeToProductReferralsSuccess,
  unsubscribeFromProductReferrals,
} = productReferralsSlice.actions;

export default productReferralsSlice.reducer;
