import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ChannelIdTreeNode,
  ContentIdTreeNode,
  CreateCommunityChannelDTO,
  FIRESTORE_COLLECTION,
  GroupId,
  GroupIdTreeNode,
  parseChannelId,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';

const insertChannelIntoContentTree = (
  contentTree: ContentIdTreeNode[],
  groupId: GroupId | null,
  insertIndex: number | null,
  channel: ChannelIdTreeNode
): ContentIdTreeNode[] => {
  const index = insertIndex ?? contentTree.length;

  if (!groupId) {
    return [...contentTree.slice(0, index), channel, ...contentTree.slice(index)];
  }

  const groupIndex = contentTree.findIndex((node) => node.id === groupId);
  if (groupIndex === -1) {
    return contentTree.map((node) => {
      if (node.type === 'group') {
        return {
          ...node,
          children: insertChannelIntoContentTree(node.children, groupId, index, channel),
        };
      }

      return node;
    });
  }

  return contentTree.map((node) => {
    if (node.id === groupId) {
      const group = node as GroupIdTreeNode;

      return {
        ...group,
        children: [...group.children.slice(0, index), channel, ...group.children.slice(index)],
      };
    }

    return node;
  });
};

export const createCommunityChannel = createAsyncThunk<
  void,
  ActionParams<CreateCommunityChannelDTO>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_CHANNELS_REDUCER_NAME}/createCommunityChannel`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged');
      }

      const communityRef = db.collection(FIRESTORE_COLLECTION.COMMUNITIES).doc(payload.communityId);
      const community = await communityRef.get();

      const channelRef = communityRef.collection(FIRESTORE_COLLECTION.COMMUNITY_CHANNELS).doc();

      await channelRef.set({
        ...payload,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.uid,
        id: parseChannelId(channelRef.id),
      });

      const contentTree: ContentIdTreeNode[] = community.data()?.contentTree || [];
      const updatedTree = insertChannelIntoContentTree(
        contentTree,
        payload.groupId || null,
        payload.index,
        {
          id: parseChannelId(channelRef.id),
          type: 'channel',
        }
      );

      await communityRef.update({
        contentTree: updatedTree,
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();
      // eslint-disable-next-line no-console
      console.error({ payload, error: e });

      return rejectWithValue(e);
    }
  }
);
