import { createAsyncThunk } from '@reduxjs/toolkit';
import Stripe from 'stripe';

import { SubscriptionInterval, SubscriptionTier } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';

type Payload = {
  interval: SubscriptionInterval;
  tier: SubscriptionTier;
};

export const checkSubscriptionInvoice = createAsyncThunk<
  Stripe.Response<Stripe.Invoice>,
  Payload,
  AsyncThunkCreator<number>
>(
  `${SUBSCRIPTION_REDUCER_NAME}/checkSubscriptionInvoice`,
  async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
      const res = await functions.httpsCallable('subscriptions-checkSubscriptionInvoice')(payload);

      return res.data?.body as Stripe.Response<Stripe.Invoice>;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
