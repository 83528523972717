import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translations from '@mailingr/i18n';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: ['en', 'pl'],
    defaultNS: 'common',
    ns: ['common', 'auth'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
