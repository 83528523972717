import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';

import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { AppStore, userActions } from '@mailingr/app-store';

import { useAppDispatch } from '~/initializeStore';

interface OwnProps {}

type Props = PropsWithChildren<OwnProps>;

const FrameAuthChecker: FunctionComponent<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isUserResolved, setIsUserResolved] = useState(false);
  const user = useSelector((store: AppStore) => store.user);
  const userUid = user.data?.uid;

  const handleUserData = async (newUser: firebase.User | null) => {
    if (newUser) {
      if (newUser.uid !== userUid) {
        dispatch(userActions.setLoggedUserData(newUser));
      }
      firebase
        .auth()
        .currentUser?.getIdToken()
        .then(async (token) => {
          window.parent.postMessage(
            { call: 'isLogged', value: { uid: newUser.uid, email: newUser.email, token } },
            '*'
          );
        });
    } else {
      window.parent.postMessage({ call: 'isLoggedOut' }, '*');
    }

    if (history.location.pathname.startsWith('/auth') && newUser) {
      history.push('/');
    }
    if (!isUserResolved) {
      setTimeout(() => {
        setIsUserResolved(true);
      }, 0);
    }
  };

  const debouncedCallback = useDebouncedCallback(handleUserData, 400);

  useEffect(() => {
    (async () => {
      await firebase.auth().onAuthStateChanged(debouncedCallback);
    })();
  }, [debouncedCallback, isUserResolved, userUid]);

  if (!isUserResolved || userUid) {
    return (
      <div
        style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return <>{children}</>;
};

export default FrameAuthChecker;
