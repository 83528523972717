import { createSelector, Selector } from '@reduxjs/toolkit';

import { ProductClientDocument } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getNewsletters = (store: AppStore) => store.newsletters.list;
const getSearchTerm = (store: AppStore) => store.newsletters.searchTerm;

export const getFilteredNewsletters: Selector<AppStore, ProductClientDocument[]> = createSelector(
  [getNewsletters, getSearchTerm],
  (newsletters, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return newsletters;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return newsletters.filter((newsletter) => {
      if (newsletter.product.name.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }
      if (newsletter.author.email.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }
      if (newsletter.author.lastName.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }

      return false;
    });
  }
);
