import { FIRESTORE_COLLECTION, UserDocument } from '@mailingr/data-models';

import { AppThunk } from '../../../index';
import {
  updateUserDetailsFailed,
  updateUserDetailsStarted,
  updateUserDetailsSuccess,
} from '../reducer';

export const updateUserData =
  (
    userData: Partial<UserDocument> & Pick<UserDocument, 'uid'>,
    token?: string,
    onSuccess?: () => void,
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch, getState, { firestore }) => {
    try {
      const ref = firestore().collection(FIRESTORE_COLLECTION.USERS).doc(userData.uid);

      dispatch(updateUserDetailsStarted());
      await ref.update({
        ...userData,
        ...(token ? { mobileFcmTokens: firestore.FieldValue.arrayUnion(token) } : {}),
        updatedAt: firestore.FieldValue.serverTimestamp(),
      });

      onSuccess?.();
      dispatch(updateUserDetailsSuccess());
    } catch (e) {
      onFailure?.();
      dispatch(updateUserDetailsFailed());
    }
  };
