import { useEffect, useMemo, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { useDebouncedCallback } from 'use-debounce';

import { RequestStatus } from '@mailingr/data-models';

const GET_NIP_URL = import.meta.env.VITE_GET_NIP_URL as String;
const POLISH_NIP_LENGTH = 10;

type NipRequestStatus =
  | RequestStatus.FETCHING
  | RequestStatus.SUCCESS
  | RequestStatus.FAILED
  | RequestStatus.IDLE
  | 'notFound';

export function useFetchNip(formController: FormInstance) {
  const [nipFetchStatus, setNipFetchStatus] = useState<NipRequestStatus>(RequestStatus.IDLE);
  const [nip, setNip] = useState('');
  const abortControllerRef = useRef(new AbortController());

  const getNip = async (nip: string) => {
    try {
      setNipFetchStatus(RequestStatus.FETCHING);
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const res = await fetch(`${GET_NIP_URL}?nip=${nip}`, {
        signal: abortControllerRef.current.signal,
      });

      if (!res.ok) {
        setNipFetchStatus(res.status === 404 ? 'notFound' : RequestStatus.FAILED);
      } else {
        const { name = [], street = [], postalCode = [], city = [] } = await res.json();
        formController.setFields([
          { name: 'companyName', value: Array.isArray(name) ? name[0] : name || '' },
          { name: 'street', value: Array.isArray(street) ? street[0] : street || '' },
          {
            name: 'postalCode',
            value: Array.isArray(postalCode) ? postalCode[0] : postalCode || '',
          },
          { name: 'city', value: Array.isArray(city) ? city[0] : city || '' },
        ]);

        setNipFetchStatus(RequestStatus.SUCCESS);
      }
    } catch (e) {
      setNipFetchStatus(RequestStatus.FAILED);
    }
  };

  const fetchNip = useDebouncedCallback(async (nip: string) => {
    if (nip.length >= POLISH_NIP_LENGTH) {
      getNip(nip);
    }
  }, 400);

  useEffect(() => {
    (async () => {
      await fetchNip(nip);
    })();
  }, [nip, fetchNip]);

  const nipHelpKeyText = useMemo(() => {
    switch (nipFetchStatus) {
      case RequestStatus.SUCCESS:
        return 'checkout:nip.success';
      case RequestStatus.FETCHING:
        return 'checkout:nip.loading';
      case RequestStatus.FAILED:
        return 'checkout:nip.rejected';
      case 'notFound':
        return 'checkout:nip.notFound';
      default:
        return 'checkout:nip.help';
    }
  }, [nipFetchStatus]);

  const nipValidStatus: ValidateStatus = useMemo(() => {
    switch (nipFetchStatus) {
      case RequestStatus.SUCCESS:
        return 'success';
      case RequestStatus.FAILED:
        return 'warning';
      case 'notFound':
        return 'warning';
      case RequestStatus.FETCHING:
        return 'validating';
      default:
        return '';
    }
  }, [nipFetchStatus]);

  return { nipFetchStatus, nipHelpKeyText, nipValidStatus, nip, setNip };
}
