import { createAsyncThunk } from '@reduxjs/toolkit';

import { ClientProductCourseProgressDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchAllProductCourseProgress = createAsyncThunk<
  { list: ClientProductCourseProgressDocument[] },
  { productId: string },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_REDUCER_NAME}/fetchAllProductCourseProgress`,
  async ({ productId }, { extra: { db } }) => {
    const ref = db
      .collection(FIRESTORE_COLLECTION.PRODUCT_COURSE_PROGRESS)
      .where('productId', '==', productId)
      .orderBy('numberOfSessions', 'desc')
      .withConverter(getDocumentConverter<ClientProductCourseProgressDocument>());

    const snapshot = await ref.get();
    const list = snapshot.docs
      .map((doc) => doc.data())
      .filter((data) => typeof data.numberOfSessions === 'number');

    return { list };
  }
);
