import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  clientEmail: string;
  resume_at: number | null;
};

export const setExtendAccessPeriod = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REDUCER_NAME}/setExtendAccessPeriod`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('products-extendClientAccessToProduct')(payload);
      analytics.track('extend_access_period', {
        productId: payload.productId,
        authorId: user?.uid ?? '',
        clientEmail: payload.clientEmail,
        extendAbout: payload.resume_at,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
