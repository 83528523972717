import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Group, RequestStatus } from '@mailingr/data-models';

import { COMMUNITY_GROUPS_REDUCER_NAME, CommunityGroupsReducer } from './types';

const initialState: CommunityGroupsReducer = {
  adding: false,
  error: null,
  data: [],
  requestStatus: null,
  searchTerm: null,
};

const communityGroupsSlice = createSlice({
  name: COMMUNITY_GROUPS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToCommunityGroupsStarted(state) {
      state.requestStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToCommunityGroupsSucceeded(state, { payload }: PayloadAction<Group[]>) {
      state.requestStatus = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToCommunityGroupsFailed(state, { payload }: PayloadAction<Error>) {
      state.requestStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromCommunityGroups(state) {
      state.data = [];
      state.error = null;
    },
  },
});

export const {
  subscribeToCommunityGroupsStarted,
  subscribeToCommunityGroupsFailed,
  subscribeToCommunityGroupsSucceeded,
  unsubscribeFromCommunityGroups,
} = communityGroupsSlice.actions;
export default communityGroupsSlice.reducer;
