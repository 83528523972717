import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_RESOURCES_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  resourceId: string;
};

export const removeProductResource = createAsyncThunk<
  void,
  ActionParams<Payload, string>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/removeProductResource`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_RESOURCES)
        .doc(payload.resourceId);

      await ref.delete();

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
