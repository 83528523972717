import { createSelector, Dictionary, Selector } from '@reduxjs/toolkit';
import keyBy from 'lodash.keyby';

import { DiscordIntegration } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getServers = (store: AppStore) => store.productAutomation.data;

export const getServersById: Selector<AppStore, Dictionary<DiscordIntegration>> = createSelector(
  [getServers],
  (automation) => {
    if (!automation?.productIntegrations?.discord?.length) {
      return {};
    }

    return keyBy(automation.productIntegrations.discord, 'guildId');
  }
);
