import { Stripe } from 'stripe';

import { RequestStatus } from '@mailingr/data-models';

export const SUBSCRIPTION_REDUCER_NAME = 'Subscription';

export interface SubscriptionReducer {
  data: Stripe.Subscription | null;
  subscriptionStatus: null | RequestStatus;
}
