import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductUserSettingsConfigDocument, RequestStatus } from '@mailingr/data-models';

import {
  PRODUCT_USER_SETTINGS_CONFIG_REDUCER_NAME,
  ProductUserSettingsConfigReducer,
} from './types';

const initialState: ProductUserSettingsConfigReducer = {
  status: null,
  data: null,
  error: null,
};

const productUserSettingsConfigSlice = createSlice({
  name: PRODUCT_USER_SETTINGS_CONFIG_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductUserSettingsConfigStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = null;
    },
    subscribeToProductUserSettingsConfigSuccess(
      state,
      { payload }: PayloadAction<ProductUserSettingsConfigDocument | null>
    ) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToProductUserSettingsConfigFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
      state.data = null;
    },
    unsubscribeFromProductUserSettingsConfig(state) {
      state.status = null;
      state.data = null;
      state.error = null;
    },
  },
});

export const {
  subscribeToProductUserSettingsConfigFailed,
  subscribeToProductUserSettingsConfigStarted,
  subscribeToProductUserSettingsConfigSuccess,
  unsubscribeFromProductUserSettingsConfig,
} = productUserSettingsConfigSlice.actions;

export default productUserSettingsConfigSlice.reducer;
