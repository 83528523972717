import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  lessonId: string;
  moduleId: string;
  productId: string;
};

export const removeContentLesson = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/removeContentLesson`,
  async ({ productId, moduleId, lessonId }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    if (!data || !data.modules.some((module) => module.id === moduleId)) {
      throw new Error('invalid-module');
    }

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    const batch = db.batch();

    batch.delete(ref.collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId));
    batch.update(ref, {
      modules: data.modules.map((module) =>
        module.id === moduleId
          ? { ...module, lessons: module.lessons.filter((lesson) => lesson.id !== lessonId) }
          : module
      ),
    });

    await batch.commit();

    analytics.track('remove_content_lesson', {
      ownerId: user.uid,
      productId,
      moduleId,
    });
  }
);
