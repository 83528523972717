import { createAsyncThunk } from '@reduxjs/toolkit';

import { cloudFunctionErrorHandler } from '@mailingr/utils';

import { AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload = {
  productId: string;
  serverId: string;
};

export const verifyDiscordIntegration = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${INTEGRATION_REDUCER_NAME}/verifyDiscordIntegration`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-checkDiscordBotIntegrationWithGuild')(payload);

      analytics.track('integrated_with_discord', {
        userId: user.uid,
        email: user.email,
      });
    } catch (e) {
      return rejectWithValue(cloudFunctionErrorHandler(e).code);
    }
  }
);
