import { LessonDocument, ProductContentDocument, RequestStatus } from '@mailingr/data-models';

export interface ProductContentReducer {
  data: null | ProductContentDocument;
  status: null | RequestStatus;
  selectedLesson: LessonDocument | null;
  lessonStatus: null | RequestStatus;
  openedModules: { [moduleId: string]: true };
}

export const PRODUCT_CONTENT_REDUCER_NAME = 'ProductContent';
