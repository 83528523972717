import { createSelector, Selector } from '@reduxjs/toolkit';

import { AppStore } from '../../../index';

const getProductContent = (store: AppStore) => store.productContent.data;

type Result = {
  value: string;
  label: string;
};

export const getModules: Selector<AppStore, Result[]> = createSelector(
  [getProductContent],
  (content) => {
    if (!content) {
      return [];
    }

    return content.modules.map((module) => ({ value: module.id, label: module.name }));
  }
);
