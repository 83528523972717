import { createAsyncThunk } from '@reduxjs/toolkit';

import { ContentModule, FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  modules: ContentModule[];
  productId: string;
  movedLesson?: {
    id: string;
    destinationModuleId: string;
  };
};

export const updateContentModulesOrder = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/updateContentModulesOrder`,
  async ({ productId, modules, movedLesson }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    if (!data?.modules) {
      throw new Error('invalid-modules');
    }

    const batch = db.batch();

    const courseRef = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    batch.update(courseRef, {
      modules,
      updatedAt: new Date(),
    });

    if (movedLesson) {
      const lessonRef = courseRef
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS)
        .doc(movedLesson.id);

      batch.update(lessonRef, {
        moduleId: movedLesson.destinationModuleId,
        updatedAt: new Date(),
      });
    }

    await batch.commit();

    analytics.track('update_content_module', { ownerId: user.uid, productId });
  }
);
