import { createAsyncThunk } from '@reduxjs/toolkit';

import { ClientProductCourseProgressDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchProductCourseProgress = createAsyncThunk<
  { list: ClientProductCourseProgressDocument[]; isLastPage: boolean },
  { productId: string },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_REDUCER_NAME}/fetchProductCourseProgress`,
  async ({ productId }, { extra: { db, firestoreSubscriptions }, getState }) => {
    const { productCourseProgressSize } = getState().product;

    const ref = db
      .collection(FIRESTORE_COLLECTION.PRODUCT_COURSE_PROGRESS)
      .where('productId', '==', productId)
      .orderBy('numberOfSessions', 'desc')
      .limit(productCourseProgressSize)
      .withConverter(getDocumentConverter<ClientProductCourseProgressDocument>());

    const snapshot = await ref.get();
    const list = snapshot.docs.map((doc) => doc.data());

    if (!snapshot.empty) {
      firestoreSubscriptions.lastProductCourseProgressCursor =
        snapshot.docs[snapshot.docs.length - 1];
    }

    return {
      list,
      isLastPage: list.length < productCourseProgressSize,
    };
  }
);
