import { createSelector, Selector } from '@reduxjs/toolkit';

import { CommunityMember } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getData = (store: AppStore) => store.communityMembers.data;
const getSearchTerm = (store: AppStore) => store.communityMembers.searchTerm;

export const getFilteredCommunityMembers: Selector<AppStore, CommunityMember[]> = createSelector(
  [getData, getSearchTerm],
  (members, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return members;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return members.filter((member) => {
      return (
        member.firstName.toLowerCase().includes(searchInLowerCase) ||
        member.lastName?.toLowerCase().includes(searchInLowerCase) ||
        member.email.toLowerCase().includes(searchInLowerCase)
      );
    });
  }
);
