import React, { FunctionComponent, useEffect } from 'react';
import { Alert, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStore, productActions } from '@mailingr/app-store';

import { useAppDispatch } from '~/initializeStore';
import * as styles from './ProductPaywallAlert.module.scss';

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const ProductPaywallLoginAlert: FunctionComponent<Props> = ({ productId }) => {
  const dispatch = useAppDispatch();

  const { checkAccessInfo } = useSelector((store: AppStore) => store.product);

  const { t } = useTranslation(['auth']);

  useEffect(() => {
    if (!checkAccessInfo) {
      dispatch(productActions.checkAccessToProduct({ payload: { productId } }));
    }
  }, [dispatch, checkAccessInfo, productId]);

  if (!checkAccessInfo || checkAccessInfo.hasAccess || checkAccessInfo.reason !== 'not-logged-in') {
    return null;
  }

  return (
    <Alert
      className={styles.alert}
      type="info"
      message={
        <div>
          <p>
            <Trans
              t={t}
              components={{ bold: <strong /> }}
              i18nKey="paywall.alert.description"
              values={{ productName: checkAccessInfo.product.name }}
            />
          </p>
          <p className="px-2">
            <Trans t={t} i18nKey="paywall.alert.callToAction" />
          </p>
          <Button block onClick={() => window.location.assign(checkAccessInfo.product.checkoutUrl)}>
            {t<string>('paywall.alert.callToActionButton')}
          </Button>
        </div>
      }
    />
  );
};

export default ProductPaywallLoginAlert;
