import { createSelector, Selector } from '@reduxjs/toolkit';
import uniqBy from 'lodash.uniqby';

import { EmailClientTag } from '@mailingr/data-models';

import { AppStore } from '../index';

const getIntegrations = (store: AppStore) => store.integration.data;

type Result = {
  value: string;
  label: string;
};

export const getTagsOptions: Selector<AppStore, Result[]> = createSelector(
  [getIntegrations],
  (integration) => {
    if (!integration || !integration.mailers) {
      return [];
    }

    return uniqBy(
      Object.values(integration.mailers)
        .flat()
        .map((account) => {
          return account?.tags.map((tagObj: EmailClientTag) => ({
            value: tagObj.tag,
            label: tagObj.tag,
          }));
        })
        .filter((item): item is { value: string; label: string }[] => !!item?.length)
        .flat(),
      'value'
    );
  }
);
