import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION as COLLECTION,
  CommunityId,
  ContentIdTreeNode,
  GroupId,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';

export const deleteCommunityGroup = createAsyncThunk<
  void,
  ActionParams<{ communityId: CommunityId; groupId: GroupId }>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_GROUPS_REDUCER_NAME}/deleteCommunityGroup`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged in');
      }

      const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
      const community = await communityRef.get();

      const groupRef = communityRef.collection(COLLECTION.COMMUNITY_GROUPS).doc(payload.groupId);

      const channelsRef = db
        .collection(COLLECTION.COMMUNITIES)
        .doc(payload.communityId)
        .collection(COLLECTION.COMMUNITY_CHANNELS)
        .where('groupId', '==', payload.groupId);
      const channelsSnapshot = await channelsRef.get();
      const hasChannels = !channelsSnapshot.empty;

      if (hasChannels) {
        throw new Error('Cannot delete group with existing channels');
      }

      await db.runTransaction(async (transaction) => {
        transaction.delete(groupRef);

        const contentTree: ContentIdTreeNode[] = community.data()?.contentTree || [];
        const updatedTree = contentTree.filter(
          (node) => node.type !== 'group' || node.id !== payload.groupId
        );

        transaction.update(communityRef, {
          contentTree: updatedTree,
          updatedAt: new Date(),
        });
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();
      // eslint-disable-next-line no-console
      console.error({ payload, error: e });

      return rejectWithValue(e);
    }
  }
);
