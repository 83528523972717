import { createAsyncThunk } from '@reduxjs/toolkit';

import { AutomationTaskWithoutTriggers, RefundType } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  clientEmail: string;
  productId: string;
  tasks: AutomationTaskWithoutTriggers[];
  cancelSubscription?: boolean;
  refund?: RefundType;
};

export const archiveProductClient = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${PRODUCT_REDUCER_NAME}/archiveProductClient`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('products-archiveProductClient')(payload);
      analytics.track('archive_product_client', {
        productId: payload.productId,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
