import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DiscountDocument, RequestStatus } from '@mailingr/data-models';

import { fetchDiscounts, updateDiscount } from './actions';
import { DISCOUNT_REDUCER_NAME, DiscountReducer } from './types';

const initialState: DiscountReducer = {
  discounts: [],
  discountsListStatus: null,
  discountAction: null,
  discountsError: null,
};

const discountSlice = createSlice({
  name: DISCOUNT_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToDiscountsStarted(state) {
      state.discountsListStatus = RequestStatus.SUBSCRIBING;
      state.discountsError = null;
    },
    subscribeToDiscountsSuccess(state, { payload }: PayloadAction<DiscountDocument[]>) {
      state.discountsListStatus = RequestStatus.SUBSCRIBED;
      state.discounts = payload;
    },
    subscribeToDiscountsFailed(state, { payload }: PayloadAction<Error>) {
      state.discountsListStatus = RequestStatus.FAILED;
      state.discountsError = payload;
    },
    unsubscribeFromDiscounts(state) {
      state.discountsListStatus = null;
      state.discounts = [];
      state.discountsError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDiscount.pending, (state, { meta }) => {
      state.discountAction = meta.arg.payload
        ? {
            id: meta.arg.payload.id,
            action: meta.arg.payload.action,
          }
        : null;
    });
    builder.addCase(updateDiscount.fulfilled, (state) => {
      state.discountAction = null;
    });
    builder.addCase(updateDiscount.rejected, (state) => {
      state.discountAction = null;
    });

    builder.addCase(fetchDiscounts.pending, (state) => {
      state.discountsListStatus = RequestStatus.FETCHING;
      state.discounts = [];
    });
    builder.addCase(fetchDiscounts.rejected, (state) => {
      state.discountsListStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchDiscounts.fulfilled, (state, { payload }) => {
      state.discountsListStatus = RequestStatus.SUCCESS;
      state.discounts = payload.list;
    });
  },
});

export const {
  subscribeToDiscountsFailed,
  subscribeToDiscountsStarted,
  subscribeToDiscountsSuccess,
  unsubscribeFromDiscounts,
} = discountSlice.actions;

export default discountSlice.reducer;
