import { createSelector, Selector } from '@reduxjs/toolkit';

import { OrderDocument } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getOrders = (store: AppStore) => store.orders.orders;
const getSearchTerm = (store: AppStore) => store.orders.searchTerm;

export const getFilteredOrders: Selector<AppStore, OrderDocument[]> = createSelector(
  [getOrders, getSearchTerm],
  (orders, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return orders;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return orders.filter((order) => {
      if (order.clientEmail.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }
      if (order.client.lastName.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }

      return false;
    });
  }
);
