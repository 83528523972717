import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, NotificationStatus } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../../product/types';

export const markAsRead = createAsyncThunk<void, string, AsyncThunkCreator<number>>(
  `${PRODUCT_REDUCER_NAME}/markAsRead`,
  async (notificationId, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.NOTIFICATIONS)
        .doc(notificationId);

      await ref.update({
        status: NotificationStatus.READ,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
