import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscountDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { DISCOUNT_REDUCER_NAME } from '../types';

export const fetchDiscounts = createAsyncThunk<
  { list: DiscountDocument[] },
  { ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${DISCOUNT_REDUCER_NAME}/fetchDiscounts`,
  async ({ ownerId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.DISCOUNTS)
        .where('status', '==', 'active')
        .orderBy('createdAt', 'desc');

      const res = await ref.get();
      const list = res.docs.map((doc) => firestoreDateMapper<DiscountDocument>(doc));

      return {
        list,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
