import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
  FIRESTORE_COLLECTION,
  IDiscordIntegrationRequested,
  SubscriberIntegrationClient,
} from '@mailingr/data-models';
import { createClientDiscordIntegrationLink } from '@mailingr/utils';

import { AsyncThunkCreator } from '../../../index';
import { USER_REDUCER } from '../reducer';

export const requestClientDiscordIntegration = createAsyncThunk<
  string,
  void,
  AsyncThunkCreator<string>
>(
  `${USER_REDUCER}/requestClientDiscordIntegration`,
  async (_, { rejectWithValue, getState, extra: { firestore, auth, config } }) => {
    try {
      const user = await auth().currentUser;

      if (!user || !user.email) {
        throw new Error('User is not logged');
      }

      if (!config) {
        throw new Error('Config is not provided');
      }

      const userDetails = getState().user.details;

      const data: IDiscordIntegrationRequested = userDetails?.clientIntegrations?.discord
        ? {
            state: userDetails.clientIntegrations.discord.state,
            createdAt: new Date(),
            status: 'requested',
            updatedAt: new Date(),
            source: 'panel',
          }
        : {
            state: uuid(),
            status: 'requested',
            createdAt: new Date(),
            updatedAt: new Date(),
            source: 'panel',
          };

      await firestore()
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .update({
          [`clientIntegrations.${SubscriberIntegrationClient.Discord}`]: data,
        });

      const link = createClientDiscordIntegrationLink({
        state: data.state,
        clientId: config.DISCORD_MAILINGR_CLIENT_ID as string,
        functionDomain: config.APP_FUNCTION_DOMAIN as string,
      });

      return link;
    } catch (e) {
      if (e instanceof Error) {
        throw rejectWithValue(e.message);
      }

      throw rejectWithValue('Unknown error');
    }
  }
);
