import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  ProductDocument,
  ProductDocumentV2,
  ProductVersionMigrator,
} from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { ADMIN_REDUCER_NAME } from '../types';

export const fetchProducts: AsyncThunk<
  ProductDocumentV2[],
  string,
  AsyncThunkCreator<string>
> = createAsyncThunk<ProductDocumentV2[], string, AsyncThunkCreator<string>>(
  `${ADMIN_REDUCER_NAME}/fetchProducts`,
  async (payload, { extra: { db } }) => {
    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(payload)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .where('archived', '==', false)
      .orderBy('created', 'desc')
      .withConverter(getDocumentConverter<ProductDocument>());

    const snapshot = await ref.get();

    return snapshot.docs.map((doc) => ProductVersionMigrator.transformToV2(doc.data()));
  }
);
