import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT, LessonDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import {
  subscribeToProductContentLessonFailed,
  subscribeToProductContentLessonStarted,
  subscribeToProductContentLessonSuccess,
} from '../reducer';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  lessonId: string;
  ownerId?: string;
};

export const subscribeToProductContentLesson = createAsyncThunk<
  void,
  Payload,
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/subscribeToProductContentLesson`,
  async (
    { productId, lessonId, ownerId },
    { dispatch, extra: { db, firestoreSubscriptions, auth } }
  ) => {
    try {
      dispatch(subscribeToProductContentLessonStarted());
      const user = auth().currentUser;
      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      firestoreSubscriptions.productContentLessonListener?.();

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS)
        .doc(lessonId);

      firestoreSubscriptions.productContentLessonListener = ref.onSnapshot(
        (snapshot) => {
          dispatch(
            subscribeToProductContentLessonSuccess(
              snapshot.exists ? firestoreDateMapper<LessonDocument>(snapshot) : null
            )
          );
        },
        () => {
          dispatch(subscribeToProductContentLessonFailed());
        }
      );
    } catch {
      dispatch(subscribeToProductContentLessonFailed());
    }
  }
);
