import { ApiTokenDocument, RequestStatus } from '@mailingr/data-models';

export interface IntegrationApiTokensReducer {
  status: null | RequestStatus;
  data: null | ApiTokenDocument[];
  error: null | Error;
  showProductList: boolean;
}

export const INTEGRATION_API_TOKENS_REDUCER_NAME = 'IntegrationApiTokens';
