import { createAsyncThunk } from '@reduxjs/toolkit';
import keyBy from 'lodash.keyby';

import { ArchivedEmailDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { ARCHIVE_REDUCER_NAME } from '../types';

type Payload = {
  productsIds: string[];
  emails: ArchivedEmailDocument[];
};

export const copyEmailsIntoProducts = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${ARCHIVE_REDUCER_NAME}/copyEmailsIntoProducts`,
  async ({ emails, productsIds }, { getState, extra: { db, analytics } }) => {
    const ref = db.collection(FIRESTORE_COLLECTION.PRODUCTS_ARCHIVE);

    const batch = db.batch();

    const productsByKey = keyBy(getState().products.productsListData, 'id');

    productsIds.forEach((productId) => {
      emails.forEach((email) => {
        const newRef = ref
          .doc(productId)
          .collection(FIRESTORE_COLLECTION.PRODUCT_ARCHIVE_EMAILS)
          .withConverter(getDocumentConverter<ArchivedEmailDocument>())
          .doc();
        batch.set(newRef, {
          ...email,
          productId,
          productSlug: productsByKey[productId].slug || null,
          productName: productsByKey[productId].name ?? '',
        });
      });
    });

    await batch.commit();
    analytics.track('copy_emails_into_another_products', {
      email: emails.length,
      products: productsIds.length,
    });
  }
);
