import { createSelector } from '@reduxjs/toolkit';

import { AppStore } from '../../../index';

const getResources = (store: AppStore) => store.productResources.data;
const getSearchTerm = (store: AppStore) => store.productResources.searchTerm;

export const getFilteredResources = createSelector(
  [getResources, getSearchTerm],
  (resources, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return resources;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return resources.filter((resources) => {
      if (resources.title.toLowerCase().includes(searchInLowerCase)) {
        return true;
      }

      return false;
    });
  }
);
