import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  UpdateProductConfigDTO,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = UpdateProductConfigDTO & { ownerId?: string };

export const updateProductUserSettingsConfig = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/updateProductUSerSettingsConfig`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { analytics, auth, db } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const collectionRef = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(payload.ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG);

      const doc = await collectionRef
        .doc(`${FIRESTORE_DOCUMENT.PRODUCT_USER_SETTINGS_PREFIX}${user.uid}`)
        .get();
      if (doc.exists) {
        await collectionRef
          .doc(`${FIRESTORE_DOCUMENT.PRODUCT_USER_SETTINGS_PREFIX}${user.uid}`)
          .update({ showActivePrices: payload.showActivePrices, updatedAt: new Date() });
      }

      analytics.track('update_product_user_settings_config', {
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
