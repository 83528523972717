import { Action, combineReducers } from '@reduxjs/toolkit';

import { AppStore } from '../index';
import { adminReducer } from './admin';
import { archiveReducer } from './archive';
import { automationLogsReducer } from './automationLogs';
import { communitiesReducer } from './communities';
import { communityReducer } from './community';
import { communityChannelsReducer } from './communityChannels';
import { communityGroupsReducer } from './communityGroups';
import { communityMembersReducer } from './communityMembers';
import { communitySettingsReducer } from './communitySettings';
import { discountReducer } from './discounts';
import { integrationReducer } from './integration';
import { integrationApiTokensReducer } from './integrationApiTokens';
import { newsletterReducer } from './newsletter';
import { newslettersReducer } from './newsletters';
import { notificationsReducer } from './notifications';
import { ordersReducer } from './orders';
import { productReducer } from './product';
import { productAutomationReducer } from './productAutomation';
import { productContentReducer } from './productContent';
import { productFilesReducer } from './productFiles';
import { productOrdersReducer } from './productOrders';
import { productReferralsReducer } from './productReferrals';
import { productResourcesReducer } from './productResources';
import { productsReducer } from './products';
import { productUserSettingsConfigReducer } from './productUserSettingsConfig';
import { statisticsReducer } from './statistics';
import { subscriptionReducer } from './subscription';
import { userReducer } from './user';
import { userReferralsReducer } from './userReferrals';

const rootReducer = combineReducers<AppStore>({
  user: userReducer,
  orders: ordersReducer,
  products: productsReducer,
  product: productReducer,
  notifications: notificationsReducer,
  integration: integrationReducer,
  integrationApiTokens: integrationApiTokensReducer,
  newsletters: newslettersReducer,
  newsletter: newsletterReducer,
  archive: archiveReducer,
  productAutomation: productAutomationReducer,
  statistics: statisticsReducer,
  productResources: productResourcesReducer,
  productFiles: productFilesReducer,
  productReferrals: productReferralsReducer,
  productOrders: productOrdersReducer,
  subscription: subscriptionReducer,
  productContent: productContentReducer,
  admin: adminReducer,
  automationLogs: automationLogsReducer,
  userReferrals: userReferralsReducer,
  discount: discountReducer,
  productUserSettings: productUserSettingsConfigReducer,
  communities: communitiesReducer,
  community: communityReducer,
  communityMembers: communityMembersReducer,
  communityChannels: communityChannelsReducer,
  communityGroups: communityGroupsReducer,
  communitySettings: communitySettingsReducer,
});

const LOG_OUT_ACTION = 'User/logOutSuccess';

export const createRootReducer = (state: AppStore | undefined, action: Action<string>) => {
  let newState = state;
  if (action.type === LOG_OUT_ACTION) {
    newState = undefined;
  }

  return rootReducer(newState, action);
};

export default rootReducer;
