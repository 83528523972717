import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ContentIdTreeNode,
  CreateCommunityGroupDTO,
  FIRESTORE_COLLECTION,
  GroupIdTreeNode,
  parseGroupId,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';

const insertGroupIntoContentTree = (
  contentTree: ContentIdTreeNode[],
  insertIndex: number | null,
  group: GroupIdTreeNode
): ContentIdTreeNode[] => {
  const index = insertIndex ?? contentTree.length;

  return [...contentTree.slice(0, index), group, ...contentTree.slice(index)];
};

export const createCommunityGroup = createAsyncThunk<
  void,
  ActionParams<CreateCommunityGroupDTO>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_GROUPS_REDUCER_NAME}/createCommunityGroup`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged in');
      }

      const communityRef = db.collection(FIRESTORE_COLLECTION.COMMUNITIES).doc(payload.communityId);
      const community = await communityRef.get();

      const groupRef = communityRef.collection(FIRESTORE_COLLECTION.COMMUNITY_GROUPS).doc();

      await groupRef.set({
        ...payload,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.uid,
        id: parseGroupId(groupRef.id),
      });

      const contentTree: ContentIdTreeNode[] = community.data()?.contentTree || [];
      const updatedTree = insertGroupIntoContentTree(contentTree, payload.index, {
        id: parseGroupId(groupRef.id),
        type: 'group',
        children: [],
      });

      await communityRef.update({
        contentTree: updatedTree,
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();
      // eslint-disable-next-line no-console
      console.error({ payload, error: e });

      return rejectWithValue(e);
    }
  }
);
