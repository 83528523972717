import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

import { AppStore, createStore, FirestoreUtils, StoreDependencies } from '@mailingr/app-store';

import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/performance';
import 'firebase/compat/storage';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { queryClient } from './initializeReactQuery';
import { WebAnalyticsService } from './services/WebAnalyticsService';

const {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DATABASE_URL,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_MEASUREMENT_ID,
  VITE_FIREBASE_EMULATOR,
  VITE_POSTHOG_API_KEY,
} = import.meta.env;

export const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: VITE_FIREBASE_DATABASE_URL,
  projectId: VITE_FIREBASE_PROJECT_ID,
  storageBucket: VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_FIREBASE_APP_ID,
  measurementId: VITE_FIREBASE_MEASUREMENT_ID,
};

export let db: firebase.firestore.Firestore | null = null;
export let functions: firebase.functions.Functions | null = null;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  db = firebase.firestore();
  const storage = firebase.storage();
  functions = firebase.app().functions('europe-west3');

  if (VITE_FIREBASE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.warn('Running on Firebase emulator!');
    db.useEmulator('localhost', 8080);
    firebase.auth().useEmulator('http://127.0.0.1:9099/');
    functions.useEmulator('localhost', 5001);
    storage.useEmulator('localhost', 9199);
    firebase.database().useEmulator('localhost', 9000);
  }
}

export const perf = firebase.performance();

const analyticsService = new WebAnalyticsService({
  postHogApiKey: VITE_POSTHOG_API_KEY || '',
});

export const { store } = createStore({
  firestore: firebase.firestore,
  auth: firebase.auth,
  analytics: analyticsService,
  db: db || firebase.firestore(),
  functions: functions || firebase.app().functions('europe-west3'),
  queryClient,
  database: firebase.database,
  config: {
    CHECKOUT_URL: import.meta.env.VITE_CHECKOUT_URL,
    CREATOR_MAILING_LIST_ID_GLOBAL: import.meta.env.VITE_CREATOR_MAILING_LIST_ID,
    CREATOR_MAILING_LIST_ID_PL: import.meta.env.VITE_CREATOR_MAILING_LIST_ID_PL,
    DISCORD_MAILINGR_CLIENT_ID: import.meta.env.VITE_DISCORD_MAILINGR_CLIENT_ID,
    APP_FUNCTION_DOMAIN: import.meta.env.VITE_FUNCTION_DOMAIN,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<AppStore, StoreDependencies & FirestoreUtils, AnyAction>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
