import { CommunityRole } from '../documents/Community';
import { BusinessEvent } from '../domains/BusinessEventEnvelope';
import { EmailList } from '../dtos/ProductDTO';
import {
  BusinessEventType,
  ProductEventType,
  ProductReferralsEventType,
} from '../enums/BusinessEventType';
import { EmailClient } from '../enums/EmailClient';
import { ProductIntegrationClient } from '../enums/ProductIntegrationClient';
import { RefundType } from '../enums/RefundType';
import { ArchivedEmailStatus, ArchivedEmailVisibility } from './ArchivedEmailDocument';
import { CommunityId, CommunityMember } from './Community';

export type SpecialParamValue =
  | 'productId'
  | 'price'
  | 'priceId'
  | 'clientEmail'
  | 'clientFirstName'
  | 'clientLastName'
  | 'clientName'
  | 'paymentType'
  | 'productName'
  | 'orderId';

export interface NumberFieldValue {
  type: 'number';
  value: string | SpecialParamValue;
}

export interface StringFieldValue {
  type: 'string';
  value: string | SpecialParamValue;
}

export enum AuthType {
  BasicAuth = 'basicAuth',
  BearerToken = 'bearerToken',
  ApiKey = 'apiKey',
  None = 'none',
}

export interface BasicAuth {
  username: string;
  password: string;
  type: AuthType.BasicAuth;
}

export interface BearerToken {
  token: string;
  type: AuthType.BearerToken;
}

export interface APIKey {
  key: string;
  value: string;
  addTo: 'header' | 'query';
  type: AuthType.ApiKey;
}

export type AuthTypeOption = BasicAuth | BearerToken | APIKey;

export interface BaseWebhook {
  triggers: BusinessEventType[];
  method: 'get' | 'post';
  url: string;
  description?: string;
  auth: null | AuthTypeOption;
  active: boolean;
  id: string;
  payload?: Record<string, any>;
}

export interface PostWebhook extends BaseWebhook {
  method: 'post';
  payload: Record<string, StringFieldValue | NumberFieldValue>;
  payloadAsParams: boolean;
}

export interface GetWebhook extends BaseWebhook {
  method: 'get';
  payload?: Record<string, SpecialParamValue | string>;
}

export type Webhook = PostWebhook | GetWebhook;

export enum TaskType {
  EmailClient = 'emailClient',
  DiscordIntegration = 'discordIntegration',
  ProductTask = 'productTask',
  CommunityTask = 'communityTask',
  CircleIntegration = 'circleIntegration',
}

export enum CommunityTask {
  AddMember = 'community.add_member',
  ChangeMemberRole = 'community.change_member_role',
  DeactivateMember = 'community.deactivate_member',
}

export enum EmailClientTask {
  AddTags = 'add_tags',
  RemoveTags = 'remove_tags',
  AddToLists = 'add_to_lists',
  RemoveFromLists = 'remove_from_lists',
  AddFieldValues = 'add_field_values',
}

export enum DiscordIntegrationTask {
  AddToServer = 'add_to_server',
  RemoveFromServer = 'remove_from_server',
  AddRole = 'add_role',
  RemoveRole = 'remove_role',
}

export enum CircleIntegrationTask {
  AddToCommunity = 'circle_add_to_community',
  RemoveFromCommunity = 'circle_remove_from_community',
  AddToGroup = 'circle_add_to_group',
  RemoveFromGroup = 'circle_remove_from_group',
  AddToSpace = 'circle_add_to_space',
  RemoveFromSpace = 'circle_remove_from_space',
  AddToMemberTag = 'circle_add_to_member_tag',
  RemoveFromMemberTag = 'circle_remove_from_member_tag',
}

export enum ProductTask {
  ImportIntoProduct = 'import_into_product',
  RemoveFromProduct = 'remove_from_product',
}

export enum TaskCreator {
  System = 'system',
  Owner = 'owner',
}

export enum PriceFilterType {
  Included = 'included',
  Excluded = 'excluded',
}

export interface PriceFilter {
  type: PriceFilterType;
  prices: string[];
}

export type AutomationFilter = PriceFilter;

export interface BaseTask {
  active: boolean;
  description?: string;
  id: string;
  protected?: boolean;
  creator?: TaskCreator;
  filters?: AutomationFilter[] | null;
}

export interface ImportIntoProductTask {
  type: ProductTask.ImportIntoProduct;
  products: { productId: string; priceId: string | null }[];
  sendEmailAboutAddToProduct?: boolean;
}

export interface RemoveFromProductTask {
  type: ProductTask.RemoveFromProduct;
  products: { productId: string }[];
  cancelActiveSubscription?: boolean;
  refundType?: RefundType;
}

export interface AddTagsTask {
  type: EmailClientTask.AddTags;
  tags: string[];
  emailClients: (TagsEmailClient | TagsEmailList)[];
}

export interface FieldValuesEmailList {
  id: string;
  name: string;
  client: EmailClient.MailChimp | EmailClient.GetResponse | EmailClient.FreshMail;
  account: string;
}

export interface TagsEmailList {
  id: string;
  name: string;
  client: EmailClient.MailChimp | EmailClient.GetResponse | EmailClient.FreshMail;
  account: string;
}

export interface TagsEmailClient {
  client:
    | EmailClient.ActiveCampaign
    | EmailClient.ConvertKit
    | EmailClient.MailerLite
    | EmailClient.Pipedrive;
  account: string;
}

export interface FieldValuesEmailClient {
  client:
    | EmailClient.ActiveCampaign
    | EmailClient.ConvertKit
    | EmailClient.MailerLite
    | EmailClient.Pipedrive;
  account: string;
}

export type TaskVariable = `{{${string}}}`;

export interface AddFieldValues {
  type: EmailClientTask.AddFieldValues;
  values: { [key: string]: string | number | TaskVariable };
  emailLists: (FieldValuesEmailList | FieldValuesEmailClient)[];
}

export interface RemoveTagsTask {
  type: EmailClientTask.RemoveTags;
  tags: string[];
  emailClients: (TagsEmailClient | TagsEmailList)[];
}

export interface AddToListsTask {
  type: EmailClientTask.AddToLists;
  emailLists: EmailList[];
}

export interface RemoveFromListsTask {
  type: EmailClientTask.RemoveFromLists;
  emailLists: EmailList[];
}

export type BusinessEventTrigger =
  | ProductEventType.ProductPurchased
  | ProductEventType.ProductSubscriptionEnd
  | ProductEventType.ProductSubscriptionRenewed
  | ProductEventType.ProductAccessPeriodEnd
  | ProductEventType.ProductClientImported
  | ProductEventType.ProductClientArchived
  | ProductEventType.ProductClientRemoved
  | ProductEventType.ProductAccessPeriodEndingIn14Days
  | ProductEventType.ProductAccessPeriodEndingIn7Days
  | ProductEventType.ProductAccessPeriodEndingIn3Days
  | ProductEventType.ProductAccessPeriodEndingIn1Day
  | ProductReferralsEventType.SubscriberGetReward
  | ProductReferralsEventType.ValidSubscriberFromReferral
  | ProductReferralsEventType.SubscriberHasRewardToConfirm;

export type BusinessEventTriggerData = BusinessEventTrigger extends BusinessEvent['eventName']
  ? BusinessEvent
  : never;

export interface ProductAutomationTask extends BaseTask {
  triggers: BusinessEventTrigger[];
  type: TaskType.ProductTask;
  action: ImportIntoProductTask | RemoveFromProductTask;
}

export interface EmailClientAutomationTask extends BaseTask {
  triggers: BusinessEventTrigger[];
  type: TaskType.EmailClient;
  action: AddTagsTask | RemoveTagsTask | AddToListsTask | RemoveFromListsTask | AddFieldValues;
}

export interface AddToDiscordServer {
  type: DiscordIntegrationTask.AddToServer;
  serverId: string;
}

export interface RemoveFromDiscordServer {
  type: DiscordIntegrationTask.RemoveFromServer;
  serverId: string;
}

export interface AddDiscordRole {
  type: DiscordIntegrationTask.AddRole;
  serverId: string;
  roleId: string;
  roleName: string;
}

export interface RemoveDiscordRole {
  type: DiscordIntegrationTask.RemoveRole;
  serverId: string;
  roleId: string;
  roleName: string;
}

export interface DiscordAutomationTask extends BaseTask {
  triggers: BusinessEventTrigger[];
  type: TaskType.DiscordIntegration;
  action: AddToDiscordServer | RemoveFromDiscordServer | AddDiscordRole | RemoveDiscordRole;
}

export interface AddToCircleCommunity {
  type: CircleIntegrationTask.AddToCommunity;
  communityId: number;
  spaces: number[];
  groups: number[];
  memberTags: number[];
}

export interface RemoveFromCircleCommunity {
  type: CircleIntegrationTask.RemoveFromCommunity;
  communityId: number;
}

export interface AddToCircleSpace {
  type: CircleIntegrationTask.AddToSpace;
  communityId: number;
  spaces: number[];
}

export interface RemoveFromCircleSpace {
  type: CircleIntegrationTask.RemoveFromSpace;
  communityId: number;
  spaces: number[];
}

export interface AddToCircleGroup {
  type: CircleIntegrationTask.AddToGroup;
  communityId: number;
  groups: number[];
}

export interface RemoveFromCircleGroup {
  type: CircleIntegrationTask.RemoveFromGroup;
  communityId: number;
  groups: number[];
}

export interface AddToCircleMemberTag {
  type: CircleIntegrationTask.AddToMemberTag;
  communityId: number;
  memberTags: number[];
}

export interface RemoveFromCircleMemberTag {
  type: CircleIntegrationTask.RemoveFromMemberTag;
  communityId: number;
  memberTags: number[];
}

export interface CircleAutomationTask extends BaseTask {
  triggers: BusinessEventTrigger[];
  type: TaskType.CircleIntegration;
  action:
    | AddToCircleCommunity
    | RemoveFromCircleCommunity
    | AddToCircleSpace
    | RemoveFromCircleSpace
    | AddToCircleGroup
    | RemoveFromCircleGroup
    | AddToCircleMemberTag
    | RemoveFromCircleMemberTag;
}

export type AutomationTask =
  | EmailClientAutomationTask
  | DiscordAutomationTask
  | ProductAutomationTask
  | CommunityAutomationTask
  | CircleAutomationTask;

export type AutomationTaskWithoutTriggers = Omit<AutomationTask, 'triggers'>;

export type EmbeddedFormVariant = 'basic' | 'card' | 'richCard';

export enum EmbeddedFormColor {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  GRAY = 'gray',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  PINK = 'pink',
}

export enum EmbeddedFormBackground {
  TRANSPARENT = 'transparent',
  LIGHTGRAY = 'lightgray',
  WHITE = 'white',
}

export interface EmbeddedForm {
  variant?: EmbeddedFormVariant;
  buttonText?: string;
  emailPlaceholder?: string;
  headerText?: string;
  successMessage?: string;
  descriptionText?: string;
  color?: string;
  background?: string;
  claim?: string;
  privacyPolicyUrl?: string;
  showClaim?: boolean;
  showHeader?: boolean;
  showDescription?: boolean;
  showPrivacyPolicyInfo?: boolean;
  showFirstNameInput?: boolean;
  firstNameRequired?: boolean;
  showPhoneNumberInput?: boolean;
  phoneNumberRequired?: boolean;
  customDomain?: string;
  termsAndPrivacyPolicyConsentRequired?: boolean;
  showTermsUrl?: boolean;
  termsUrl?: string;
}

export type ProductTaxSettings =
  | {
      vatRate: 0 | 4 | 5 | 7 | 8 | 23;
      legalVatExemption?: never;
    }
  | {
      vatRate: 'np' | 'zw';
      legalVatExemption: string;
    };

export type GTUCode = `GTU_${number}`;

export interface ProductInvoiceConfig {
  flatTaxRate: number | null;
  itemName: string | null;
  taxSettings?: ProductTaxSettings | null;
  pkwiu?: string | null;
  gtu?: GTUCode | null;
}

export interface AutomationSettings {
  successUrl: string | null;
  showPromotionCodesField?: boolean;
  showPromotionCodesFieldOnlyForSubscription?: boolean;
  showAdditionalInfoOptionalField?: boolean;
  showAdditionalInfoRequiredField?: boolean;
  invoiceAsOption?: boolean;
  hideCountryField?: boolean;
  showPhoneField?: boolean | null;
  showPhoneOptionalField?: boolean;
  showPhoneRequiredField?: boolean;
  disableEmailNotificationAboutPurchase?: boolean;
  disableEmailNotificationAboutPurchaseToOwner?: boolean;
  allowUpgradeSubscriptionPlan?: boolean;
  allowDowngradeSubscriptionPlan?: boolean;
  embeddedForm?: EmbeddedForm & { pl?: EmbeddedForm };
  invoiceConfig?: ProductInvoiceConfig;
  facebookPixelId?: string | null;
  googleAnalyticsId?: string | null;
  disableEmailsAboutFailedPayment: boolean;
  promoteNetPrices?: boolean;
  promoteNetPriceRate?: number | null;
  forceB2B?: boolean;
  termsUrl?: string | null;
  privacyPolicyUrl?: string | null;
  claim?: string | null;
  disableEmailsAboutCancelSubscription: boolean;
  disableEmailsAboutEndSubscription: boolean;
  metaConversionIntegrationId?: string | null;
  googleAnalyticsGtmId?: string | null;
  issueReceiptWhenInvoiceIsOptIn?: boolean;
  disableEmailNotificationsRemindersAboutEndingAccessToClient?: boolean;
}

export interface DiscordUser {
  id: string;
  username: string;
  avatar?: string;
  bot?: boolean;
  system?: boolean;
  locale?: string;
}

export interface Tags {
  bot_id: string;
}

export interface Role {
  id: string;
  name: string;
  permissions: string;
  position: number;
  color: number;
  hoist: boolean;
  managed: boolean;
  mentionable: boolean;
  icon: string | null;
  unicode_emoji: string | null;
  tags?: Tags;
}

export interface DiscordGuild {
  id: string;
  name: string;
  icon: string;
  description: null | string;
  splash?: null | string;
  discovery_splash?: null | string;
  features: string[];
  emojis: string[];
  stickers: string[];
  banner?: null | string;
  owner_id: string;
  application_id: null | string;
  region: string;
  afk_channel_id?: string | null;
  afk_timeout: number;
  system_channel_id: string;
  widget_enabled: boolean;
  widget_channel_id?: string | null;
  verification_level: number;
  roles: Role[];
  default_message_notifications: number;
  mfa_level: number;
  explicit_content_filter: number;
  max_members: number;
  max_video_channel_users: number;
  premium_tier: number;
  premium_subscription_count: number;
  system_channel_flags: number;
  preferred_locale: string;
  rules_channel_id: string | null;
  public_updates_channel_id: string | null;
  hub_type?: string | null;
  premium_progress_bar_enabled: boolean;
  nsfw: boolean;
  nsfw_level: number;
}

export type DiscordIntegration = {
  guild: DiscordGuild;
  guildId: string;
  ownerId: string;
  createdAt: Date;
  updatedAt: Date;
  roles?: Role[];
};

export enum ArchiveStrategyAccess {
  Disabled = 'disabled',
  Unlimited = 'unlimited',
  FromSubscribeDate = 'fromSubscribeDate',
}

export interface ArchiveSettings {
  accessStrategy: ArchiveStrategyAccess;
  automation: {
    onNewEmail: {
      privateArchiveStatus: ArchivedEmailStatus;
      publicArchiveStatus: ArchivedEmailVisibility;
    };
  };
}

export interface ProductAutomationDocument {
  createdAt: Date;
  updatedAt: Date;
  webhooks: Webhook[];
  productId: string;
  tasks?: AutomationTask[];
  settings?: AutomationSettings;
  archiveSettings?: ArchiveSettings;
  productIntegrations?: {
    [ProductIntegrationClient.Discord]?: DiscordIntegration[];
  };
}

export interface AddCommunityMemberTask {
  type: CommunityTask.AddMember;
  communityId: CommunityMember['communityId'];
  communitySlug: CommunityMember['communitySlug'];
  communityName: CommunityMember['communityName'];
  communityDescription: CommunityMember['communityDescription'];
  communityThumbUrl: CommunityMember['communityThumbUrl'];
  role: CommunityRole;
}

export interface DeactivateCommunityMemberTask {
  type: CommunityTask.DeactivateMember;
  communityId: CommunityMember['communityId'];
  communityName: CommunityMember['communityName'];
}

export interface ChangeCommunityMemberRoleTask {
  type: CommunityTask.ChangeMemberRole;
  communityId: CommunityId;
  role: CommunityRole;
}

export interface CommunityAutomationTask extends BaseTask {
  triggers: BusinessEventTrigger[];
  type: TaskType.CommunityTask;
  action: AddCommunityMemberTask | DeactivateCommunityMemberTask | ChangeCommunityMemberRoleTask;
}
