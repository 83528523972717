import { createAsyncThunk } from '@reduxjs/toolkit';

import { CheckoutSettings, FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export const updateCheckoutSettings = createAsyncThunk<
  void,
  ActionParams<Partial<CheckoutSettings>>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/updateCheckoutSettings`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.SETTINGS)
        .doc(FIRESTORE_DOCUMENT.INTEGRATION_CONFIG);

      await ref.set(
        {
          checkoutSettings: payload,
        },
        { merge: true }
      );

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
