import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, NFTPrice, ProductPrice } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = { priceId: string; productId: string; customInvoiceName: string };

export const updateProductPriceCustomInvoiceName = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/updateProductPriceCustomInvoiceName`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      const { product } = getState();

      if (product.details?.id !== payload.productId) {
        throw new Error('different-product-id');
      }

      if (!product.details.prices || !product.details.prices.length) {
        throw new Error('product-without-prices');
      }

      const updatedPrices = product.details.prices.map((price: ProductPrice | NFTPrice) =>
        price.id === payload.priceId
          ? { ...price, customInvoiceName: payload.customInvoiceName }
          : price
      );

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId);

      await ref.update({
        prices: updatedPrices,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
