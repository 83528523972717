import { createAsyncThunk } from '@reduxjs/toolkit';

import { ClientProductCourseProgressDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchNextPageProductCourseProgress = createAsyncThunk<
  {
    list: ClientProductCourseProgressDocument[];
    isLastPage: boolean;
    page: number;
  },
  { productId: string; page: number },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_REDUCER_NAME}/fetchNextPageProductCourseProgress`,
  async ({ productId, page }, { extra: { db, firestoreSubscriptions }, getState }) => {
    const {
      productCourseProgress,
      productCourseProgressSize,
      productCourseProgressPage,
      isLastPageForProgress,
    } = getState().product;

    if (isLastPageForProgress || (productCourseProgressPage && page <= productCourseProgressPage)) {
      return {
        page,
        list: [],
        isLastPage: isLastPageForProgress,
      };
    }

    const countPages = page - Math.ceil(productCourseProgress.length / productCourseProgressSize);

    if (countPages <= 0) {
      return {
        page,
        list: [],
        isLastPage: isLastPageForProgress,
      };
    }

    const ref = db
      .collection(FIRESTORE_COLLECTION.PRODUCT_COURSE_PROGRESS)
      .where('productId', '==', productId)
      .orderBy('numberOfSessions', 'desc')
      .limit(productCourseProgressSize * (page - productCourseProgressPage))
      .startAfter(firestoreSubscriptions.lastProductCourseProgressCursor)
      .withConverter(getDocumentConverter<ClientProductCourseProgressDocument>());

    const snapshot = await ref.get();

    const isLastPage = snapshot.docs.length < productCourseProgressSize;

    firestoreSubscriptions.lastProductCourseProgressCursor = isLastPage
      ? null
      : snapshot.docs[snapshot.docs.length - 1];

    const list = snapshot.docs.map((doc) => doc.data());
    const currentPage = productCourseProgressPage || 1;

    return {
      list,
      isLastPage,
      page: page - currentPage,
    };
  }
);
