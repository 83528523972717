import { Action, configureStore, EnhancedStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { QueryClient } from '@tanstack/react-query';
import firebase from 'firebase/compat';
import { createLogger } from 'redux-logger';

import { AnalyticsService } from '@mailingr/data-models';

import { createRootReducer } from './reducers';
import { AdminReducer } from './reducers/admin/types';
import { ArchiveReducer } from './reducers/archive/types';
import { AutomationLogsReducer } from './reducers/automationLogs/types';
import { CommunitiesReducer } from './reducers/communities/types';
import { CommunityReducer } from './reducers/community/types';
import { CommunityChannelsReducer } from './reducers/communityChannels/types';
import { CommunityGroupsReducer } from './reducers/communityGroups/types';
import { CommunityMembersReducer } from './reducers/communityMembers/types';
import { CommunitySettingsReducer } from './reducers/communitySettings/types';
import { DiscountReducer } from './reducers/discounts/types';
import { IntegrationReducer } from './reducers/integration/types';
import { IntegrationApiTokensReducer } from './reducers/integrationApiTokens/types';
import { NewsletterReducer } from './reducers/newsletter/types';
import { NewslettersReducer } from './reducers/newsletters/types';
import { NotificationsReducer } from './reducers/notifications/types';
import { OrdersReducer } from './reducers/orders/types';
import { ProductReducer } from './reducers/product/types';
import { ProductAutomationReducer } from './reducers/productAutomation/types';
import { ProductContentReducer } from './reducers/productContent/types';
import { ProductFilesReducer } from './reducers/productFiles/types';
import { ProductOrdersReducer } from './reducers/productOrders/types';
import { ProductReferralsReducer } from './reducers/productReferrals/types';
import { ProductResourcesReducer } from './reducers/productResources/types';
import { ProductsReducer } from './reducers/products/types';
import { ProductUserSettingsConfigReducer } from './reducers/productUserSettingsConfig/types';
import { StatisticsReducer } from './reducers/statistics/types';
import { SubscriptionReducer } from './reducers/subscription/types';
import { UserReducer } from './reducers/user/types';
import { ReferralsReducer } from './reducers/userReferrals/types';

export interface AppStore {
  user: UserReducer;
  orders: OrdersReducer;
  products: ProductsReducer;
  product: ProductReducer;
  notifications: NotificationsReducer;
  integration: IntegrationReducer;
  integrationApiTokens: IntegrationApiTokensReducer;
  newsletters: NewslettersReducer;
  newsletter: NewsletterReducer;
  archive: ArchiveReducer;
  productAutomation: ProductAutomationReducer;
  statistics: StatisticsReducer;
  productResources: ProductResourcesReducer;
  productFiles: ProductFilesReducer;
  productReferrals: ProductReferralsReducer;
  productOrders: ProductOrdersReducer;
  subscription: SubscriptionReducer;
  productContent: ProductContentReducer;
  admin: AdminReducer;
  automationLogs: AutomationLogsReducer;
  userReferrals: ReferralsReducer;
  discount: DiscountReducer;
  productUserSettings: ProductUserSettingsConfigReducer;
  communities: CommunitiesReducer;
  community: CommunityReducer;
  communityMembers: CommunityMembersReducer;
  communityChannels: CommunityChannelsReducer;
  communityGroups: CommunityGroupsReducer;
  communitySettings: CommunitySettingsReducer;
}

export interface StoreDependencies {
  firestore: typeof firebase.firestore;
  db: firebase.firestore.Firestore;
  auth: typeof firebase.auth;
  analytics: AnalyticsService;
  functions: firebase.functions.Functions;
  queryClient: QueryClient;
  database?: typeof firebase.database;
  config?: {
    CHECKOUT_URL: string;
    CREATOR_MAILING_LIST_ID_GLOBAL: string;
    CREATOR_MAILING_LIST_ID_PL: string;
    DISCORD_MAILINGR_CLIENT_ID: string;
    APP_FUNCTION_DOMAIN: string;
  };
}

type ProductsListener = {
  productsListener?: Function;
  productListener?: Function;
  productOrdersListener?: Function;
  productContentListener?: Function;
  productContentLessonListener?: Function;
  lastProductClientCursor?: firebase.firestore.DocumentData | null;
  lastArchivedProductClientCursor?: firebase.firestore.DocumentData | null;
  lastProductCourseProgressCursor?: firebase.firestore.DocumentData | null;
  lastOrderCursor?: firebase.firestore.DocumentData | null;
  lastFreeAccessInvitationCursor?: firebase.firestore.DocumentData | null;
  productResourcesListener?: Function;
  productFilesListener?: Function;
  productClientLogsListener?: Function;
  lastProductClientImportsCursor?: firebase.firestore.DocumentData | null;
};

type OrdersListener = {
  ordersListener?: Function;
};

type UserDetailsListener = {
  userDetailsListener?: Function;
  subscriptionListener?: Function;
};

type NotificationsListener = {
  notificationsListener?: Function;
};

type IntegrationListener = {
  integrationListener?: Function;
  apiTokenListener?: Function;
};

type NewslettersListener = {
  newslettersListener?: Function;
};

type NewsletterListener = {
  newsletterListener?: Function;
};

type ArchiveListener = {
  archiveListener?: Function;
  lastArchiveDocumentCursor?: firebase.firestore.DocumentData | null;
};

type ProductAutomationListener = {
  productAutomationListener?: Function;
};

type ReportsListener = {
  reportsListener?: Function;
};

type ProductReferralsListener = {
  referralsListener?: Function;
};

type UserReferralsListener = {
  userReferralsListener?: Function;
};

type DiscountsListener = {
  discountsListener?: Function;
};

type ProductUserSettingsListener = {
  productUserSettingsListener?: Function;
};

type CommunityListener = {
  communityListener?: Function;
};

type CommunitiesListener = {
  communitiesListener?: Function;
};

type CommunityMembersListener = {
  communityMembersListener?: Function;
};

type CommunityChannelsListener = {
  communityChannelsListener?: Function;
};

type CommunityGroupsListener = {
  communityGroupsListener?: Function;
};

type CommunitySettingsListener = {
  communitySettingsListener?: Function;
};

type FirestoreSubscriptions = ProductsListener &
  OrdersListener &
  UserDetailsListener &
  NotificationsListener &
  IntegrationListener &
  NewsletterListener &
  NewslettersListener &
  ArchiveListener &
  ProductAutomationListener &
  ReportsListener &
  ProductReferralsListener &
  UserReferralsListener &
  DiscountsListener &
  ProductUserSettingsListener &
  CommunityListener &
  CommunitiesListener &
  CommunityMembersListener &
  CommunityChannelsListener &
  CommunityGroupsListener &
  CommunitySettingsListener;

export interface FirestoreUtils {
  firestoreSubscriptions: FirestoreSubscriptions;
}

export type ActionParams<T = undefined, R = undefined> = {
  payload: T;
  onSuccess?: (_data?: R) => void;
  onFailure?: (errorCode?: number | firebase.functions.HttpsError['code']) => void;
};

export type AsyncThunkCreator<T = null> = {
  extra: StoreDependencies & FirestoreUtils;
  state: AppStore;
  rejectValue: T;
};

export type AppThunk<T = void> = ThunkAction<
  T,
  AppStore,
  StoreDependencies & FirestoreUtils,
  Action<string>
>;

const createStore = (storeDependencies: StoreDependencies): { store: EnhancedStore } => {
  const middleware: Middleware[] = [];
  const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

  if (IS_DEVELOPMENT) {
    middleware.push(createLogger({ collapsed: true }) as Middleware);
  }
  const store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            ...storeDependencies,
            firestoreSubscriptions: {},
          },
        },
      }).concat(middleware),
  });

  return { store };
};

export default createStore;
