import React, { FunctionComponent, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Select, Skeleton } from 'antd';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import * as styles from './Checkout.module.scss';

import 'firebase/compat/analytics';

import { useSelector } from 'react-redux';

import { AppStore } from '@mailingr/app-store';
import { RequestStatus } from '@mailingr/data-models';

import FreeFlowStepper from '~/components/FreeFlowStepper/Stepper';
import CheckoutWrapper from '~/pages/Checkout/components/CheckoutWrapper/CheckoutWrapper';
import ProductDetailsCard from '~/pages/Checkout/components/ProductDetailsCard';

interface OwnProps {}

type Props = OwnProps;

const tailLayout = {
  wrapperCol: { offset: 0, span: 24, md: { offset: 4, span: 16 } },
};

type FormModel = {
  email: string;
  firstName: string;
  lastName: string;
  acceptsTermsOfService: boolean;
};

const CouponValidationError = {
  WRONG_COUPON_CODE: 'wrong_coupon_code',
  CANNOT_FOUND_CLIENT_DATA: 'cannot_found_client_data',
  INACTIVE_COUPON: 'inactive_coupon',
};

const ProductValidationError = {
  NO_ACTIVE_PRODUCT: 'no_active_product',
  ALREADY_BOUGHT: 'already_bought',
  ACCOUNT_EXISTS: 'account_exists',
};

const CHECKOUT_URL = import.meta.env.VITE_CHECKOUT_URL as string;
const STRIPE_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY as string;

const CheckoutFreeProduct: FunctionComponent<Props> = () => {
  const { t, i18n } = useTranslation(['common', 'checkout']);
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [redirectToStripeCheckout, toggleFlag] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const productId = queryParams.get('productId');
  const priceId = queryParams.get('priceId');
  const refCode = queryParams.get('ref');
  const { checkoutProductInfo, checkoutProductInfoStatus } = useSelector(
    (store: AppStore) => store.product
  );
  const loggedUser = useSelector((store: AppStore) => store.user.details);
  const [formController] = Form.useForm<FormModel>();

  useEffect(() => {
    if (loggedUser) {
      formController.setFields([
        { value: loggedUser.email ?? '', name: 'email' },
        { value: loggedUser.firstName ?? '', name: 'firstName' },
        { value: loggedUser.lastName ?? '', name: 'lastName' },
      ]);
    }
  }, [formController, loggedUser]);

  const onFinish = async (values: FormModel) => {
    const stripe = await loadStripe(STRIPE_KEY, { stripeAccount: accountId });
    if (!stripe) {
      return null;
    }
    setErrorMessage(null);
    toggleFlag(true);
    const email = values.email.toLowerCase().trim();
    fetch(CHECKOUT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        email,
        accountId,
        productId,
        priceId,
        refCode,
        lang: i18n.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    })
      .then((res) => {
        if (!res.ok && res.status === 409) {
          const error = new Error();
          error.message = ProductValidationError.ALREADY_BOUGHT;
          throw error;
        }

        return res.json();
      })
      .then(async (json) => {
        if (json.error || json.message) {
          throw new Error(json.error);
        }
        if (json && json.session) {
          await firebase.analytics().logEvent('redirect_to_checkout', {
            email,
            accountId,
            productId,
            priceId,
          });
          stripe.redirectToCheckout({
            sessionId: json.session.id,
          });
        } else if (json.url) {
          toggleFlag(false);
          await firebase.analytics().logEvent('free_product_purchased', {
            email,
            accountId,
            productName: checkoutProductInfo?.name ?? '',
            productId,
            priceId,
          });
          window.location.replace(json.url);
        }
      })
      .catch((e) => {
        toggleFlag(false);
        if (Object.values(CouponValidationError).find((error) => error === e.message)) {
          setErrorMessage(e.message);

          return;
        }
        if (Object.values(ProductValidationError).find((error) => error === e.message)) {
          setErrorMessage(e.message);

          return;
        }
        history.push(
          `/checkout/failure?accountId=${accountId}&priceId=${queryParams.get(
            'priceId'
          )}&productId=${queryParams.get('productId')}&productName=${
            checkoutProductInfo?.name ?? ''
          }`
        );
      });
  };

  return (
    <CheckoutWrapper
      productCard={
        <ProductDetailsCard accountId={accountId} productId={productId} priceId={priceId} />
      }
    >
      <div className="absolute bottom-0 right-4 lg:bottom-8 lg:right-8">
        <Select
          value={i18n.language}
          size="large"
          onChange={(lang) => i18n.changeLanguage(lang)}
          dropdownMatchSelectWidth={false}
        >
          <Select.Option value="en" key="en">
            <span className="flag-icon flag-icon-gb" />
            🇺🇸 EN
          </Select.Option>
          <Select.Option value="pl" key="pl">
            <span className="flag-icon flag-icon-pl" />
            🇵🇱 PL
          </Select.Option>
        </Select>
      </div>
      <FreeFlowStepper current={0} />
      <Form<FormModel>
        name="login-form"
        layout="vertical"
        form={formController}
        labelCol={{ xxl: { span: 8, offset: 4 }, span: 24, offset: 0 }}
        wrapperCol={{ xxl: { span: 16, offset: 4 }, span: 24, offset: 0 }}
        onFinish={onFinish}
        validateTrigger="onSubmit"
        className={styles.form}
        initialValues={{
          email: email || '',
        }}
      >
        <Row>
          <Col xs={24}>
            <Form.Item
              name="email"
              label={t<string>('checkout:email')}
              rules={[
                { required: true, message: t<string>('validationErrors.fieldIsRequired') },
                { type: 'email', message: t<string>('validationErrors.wrongEmail') },
              ]}
            >
              <Input placeholder={t<string>('checkout:emailPlaceholder')} />
            </Form.Item>

            <Form.Item
              name="firstName"
              label={t<string>('checkout:firstName')}
              rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
            >
              <Input placeholder={t<string>('checkout:firstNamePlaceholder')} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label={t<string>('checkout:lastName')}
              rules={[{ required: false, message: t<string>('validationErrors.fieldIsRequired') }]}
            >
              <Input placeholder={t<string>('checkout:lastNamePlaceholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Skeleton
          loading={checkoutProductInfoStatus === RequestStatus.FETCHING}
          paragraph={{ rows: 3 }}
        >
          <Form.Item
            name="acceptsTermsOfService"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(t<string>('validationErrors.fieldIsRequired')),
              },
            ]}
          >
            <Checkbox>
              {t<string>('checkout:acceptTerms')}
              <a
                href={checkoutProductInfo?.cart?.pl.termsUrl || t<string>('common:links.terms')}
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>('checkout:termsOfService')}
              </a>
              {t<string>('checkout:acceptOr')}
              <a
                href={
                  checkoutProductInfo?.cart?.pl.privacyPolicyUrl ||
                  t<string>('common:links.privacyPolicy')
                }
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>('checkout:privacyPolicy')}
              </a>
              {t<string>('checkout:acceptEnd')}
              <span className={styles.required}>*</span>
            </Checkbox>
          </Form.Item>

          {checkoutProductInfo?.cart?.pl.customConsents?.map((consent) => (
            <Form.Item
              name={consent.name}
              key={consent.name}
              valuePropName="checked"
              rules={[
                consent.required
                  ? {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t<string>('validationErrors.fieldIsRequired')),
                    }
                  : {},
              ]}
            >
              <Checkbox>
                {consent.label}
                {consent.required && <span className={styles.required}>*</span>}
                {consent.url && (
                  <a
                    rel="noreferrer noopener"
                    href={consent.url}
                    target="_blank"
                    className={styles.seeMoreDetails}
                  >
                    {t<string>('checkout:seeMoreDetails')}
                  </a>
                )}
              </Checkbox>
            </Form.Item>
          ))}

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={checkoutProductInfo?.active === false}
              block
              className={styles.submitButton}
              loading={redirectToStripeCheckout}
            >
              {t<string>('checkout:button.join')}
            </Button>
          </Form.Item>

          <p className={styles.privacyPolicy}>
            {checkoutProductInfo?.cart?.pl.claim || t<string>('checkout:privacyPolicyInformation')}
          </p>
        </Skeleton>
      </Form>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24} sm={16} offset={4}>
          {errorMessage && (
            <Alert showIcon type="error" message={t<string>(`checkoutError.${errorMessage}`)} />
          )}
        </Col>
      </Row>
    </CheckoutWrapper>
  );
};

export default CheckoutFreeProduct;
