import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderDocument, RequestStatus } from '@mailingr/data-models';

import { PRODUCT_ORDERS_REDUCER_NAME, ProductOrdersReducer } from './types';

const initialState: ProductOrdersReducer = {
  orders: [],
  ordersStatus: null,
  productId: null,
  searchTerm: null,
  selectedMonth: null,
  error: null,
};

const productOrdersSlice = createSlice({
  name: PRODUCT_ORDERS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductOrdersStarted(
      state,
      { payload }: PayloadAction<{ productId: string; selectedMonth: string }>
    ) {
      state.ordersStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.productId = payload.productId;
      state.selectedMonth = payload.selectedMonth;
    },
    subscribeToProductOrdersSuccess(state, { payload }: PayloadAction<OrderDocument[]>) {
      state.ordersStatus = RequestStatus.SUBSCRIBED;
      state.orders = payload;
    },
    subscribeToProductOrdersFailed(state, { payload }: PayloadAction<Error>) {
      state.ordersStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromProductOrders() {
      return initialState;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
});

export const {
  subscribeToProductOrdersFailed,
  subscribeToProductOrdersStarted,
  subscribeToProductOrdersSuccess,
  unsubscribeFromProductOrders,
  clearSearchMode,
  setSearchTerm,
} = productOrdersSlice.actions;

export default productOrdersSlice.reducer;
