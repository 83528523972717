import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { CreatorMonthStatsDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { STATISTICS_REDUCER_NAME } from '../types';

export const fetchCurrentMonthStats = createAsyncThunk<
  CreatorMonthStatsDocument | null,
  void,
  AsyncThunkCreator<number>
>(
  `${STATISTICS_REDUCER_NAME}/fetchCurrentMonthStats`,
  async (_, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const today = dayjs();
      const ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.STATS_PER_MONTH)
        .doc(today.format('YYYY-M'));

      const doc = await ref.get();

      return doc.exists ? firestoreDateMapper<CreatorMonthStatsDocument>(doc) : null;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
