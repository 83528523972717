import { createAsyncThunk } from '@reduxjs/toolkit';

import { InvoicesClient, InvoiceSettings } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload =
  | {
      url: string;
      apiKey: string;
      client: Exclude<InvoicesClient, InvoicesClient.wFirma | InvoicesClient.wFirmaV2>;
      accountName: string;
      departmentId?: string;
      accountApiKey?: string;
      settings?: InvoiceSettings | null;
    }
  | {
      url: string;
      clientSecret: string;
      client: InvoicesClient.wFirma;
      accountName: string;
      settings?: InvoiceSettings | null;
    }
  | {
      url: string;
      accountName: string;
      accessKey: string;
      secretKey: string;
      companyId: string;
      client: InvoicesClient.wFirmaV2;
      settings?: InvoiceSettings | null;
    };

export const integrateWithInvoicesClient = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/integrateWithInvoicesClient`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-integrateWithInvoicesClient')(payload);

      analytics.track('integrate_with_invoices_client', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
