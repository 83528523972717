import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreatorStatsDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { STATISTICS_REDUCER_NAME } from '../types';

export const fetchCreatorStats = createAsyncThunk<
  CreatorStatsDocument | null,
  void,
  AsyncThunkCreator<number>
>(
  `${STATISTICS_REDUCER_NAME}/fetchCreatorStats`,
  async (_, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db.collection(FIRESTORE_COLLECTION.REPORTS).doc(user.uid);

      const doc = await ref.get();

      return doc.exists ? firestoreDateMapper<CreatorStatsDocument>(doc) : null;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
