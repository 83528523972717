import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/compat';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export const removeIntegrationWithPayU = createAsyncThunk<
  void,
  ActionParams<void>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/removeIntegrationWithPayU`,
  async ({ onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged');
      }

      await functions.httpsCallable('integrations-removeIntegrationWithPayU')();

      analytics.track('remove_integration_with_payu', {
        userId: user.uid,
      });
      onSuccess?.();
    } catch (e) {
      const error = e as firebase.functions.HttpsError;
      onFailure?.(error.code === 'permission-denied' ? 403 : 500);

      return rejectWithValue(e);
    }
  }
);
