import { OrderDocument, RequestStatus } from '@mailingr/data-models';

export const PRODUCT_ORDERS_REDUCER_NAME = 'ProductOrders';

export interface ProductOrdersReducer {
  orders: OrderDocument[];
  selectedMonth: string | null;
  productId: string | null;
  ordersStatus: RequestStatus | null;
  searchTerm: string | null;
  error: Error | null;
}
