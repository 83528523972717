import './index.css';
import './i18nextConfig';

import React from 'react';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import pl from 'antd/lib/locale/pl_PL';
import dayjs from 'dayjs';
import { createRoot } from 'react-dom/client';

import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary';
import { appTheme } from '~/theme/appTheme';
import App from './App';

import 'dayjs/locale/pl';

dayjs.locale('pl');

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_KEY,
  environment: import.meta.env.VITE_ENV_NAME,
  release: '@mailingr/web-app@' + import.meta.env.APP_VERSION,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ConfigProvider
        locale={pl}
        theme={{
          token: appTheme,
        }}
      >
        <App />
      </ConfigProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
