import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const removeArchivedClient = createAsyncThunk<
  ProductClientDocument,
  { productId: string; clientOfProduct: ProductClientDocument },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/removeArchivedClient`,
  async ({ clientOfProduct, productId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .doc(clientOfProduct.email);

      await ref.delete();

      await ref.collection(FIRESTORE_COLLECTION.PRODUCT_CLIENT_REMOVED_ACCESS).add({
        ...clientOfProduct,
        deletedAt: new Date(),
      });

      return clientOfProduct;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
