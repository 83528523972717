import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, UpdateCommunityMemberDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_MEMBERS_REDUCER_NAME } from '../types';

export const updateCommunityMember = createAsyncThunk<
  void,
  ActionParams<UpdateCommunityMemberDTO>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_MEMBERS_REDUCER_NAME}/updateCommunityMember`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { db, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      const { communityId, id, ...updateData } = payload;

      const memberRef = db
        .collection(FIRESTORE_COLLECTION.COMMUNITIES)
        .doc(communityId)
        .collection(FIRESTORE_COLLECTION.COMMUNITY_MEMBERS)
        .doc(id);

      await memberRef.update({
        ...updateData,
        updatedAt: new Date(),
      });

      analytics.track('update_community_member', {
        userId: user.uid,
        email: user.email,
        communityId,
        memberId: id,
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
