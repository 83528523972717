import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import {
  ContentModule,
  ContentType,
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductContentDocument,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  module: Omit<ContentModule, 'id' | 'lessons' | 'createdAt' | 'updatedAt'>;
  productId: string;
};
export const addContentModule = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/addContentModule`,
  async ({ productId, module }, { extra: { firestore, analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;
    const moduleId = uuid();

    if (!user) {
      throw new Error('invalid-user');
    }

    const contentModule: ContentModule = {
      ...module,
      id: moduleId,
      lessons: [],
      type: ContentType.Learning,
      createdAt: dayjs().unix(),
      updatedAt: dayjs().unix(),
    };

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    if (!data) {
      const productContent: ProductContentDocument = {
        updatedAt: new Date(),
        createdAt: new Date(),
        modules: [contentModule],
        productId,
        ownerId: user.uid,
        videoCollectionId: null,
      };

      await ref.set(productContent, { merge: true });
    } else {
      await ref.update({ modules: firestore.FieldValue.arrayUnion(contentModule) });
    }

    analytics.track('add_content_module', { ownerId: user.uid, productId });

    return moduleId;
  }
);
