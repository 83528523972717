import { DiscountAction, DiscountDocument, RequestStatus } from '@mailingr/data-models';

export const DISCOUNT_REDUCER_NAME = 'Discount';

export interface DiscountReducer {
  discountsListStatus: RequestStatus | null;
  discounts: DiscountDocument[];
  discountAction: null | { id: string; action: DiscountAction };
  discountsError: Error | null;
}
