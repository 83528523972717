import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductFilesDocument,
  ProductFilesPayload,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_FILES_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  model: Partial<ProductFilesPayload>;
};

export const updateProductFiles = createAsyncThunk<
  void,
  ActionParams<Payload, string>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_FILES_REDUCER_NAME}/updateProductFiles`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const model: Partial<ProductFilesDocument> = {
        ...payload.model,
        updatedAt: new Date(),
      };

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_FILES)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_FILES_CONTENT);

      await ref.update(model);

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
