import { Brand } from '../domains/BrandType';
import { BunnyVideoObject } from './BunnyObjects';
import { VideoLessonStatus } from './ProductContentDocument';

export enum CommunityRole {
  Owner = 'owner',
  // Admin = 'admin',
  // Moderator = 'moderator',
  Member = 'member',
  // Viewer = 'viewer',
  // Commenter = 'commenter',
  // Banned = 'banned',
}

export type CustomRole = string;

export enum ChannelType {
  Feed = 'feed',
  Chat = 'chat',
  Events = 'events',
  Link = 'link',
}

export enum PermissionLevel {
  Unavailable = 'unavailable',
  Read = 'read',
  Comment = 'comment',
  Write = 'write',
  Admin = 'admin',
}

export enum SortingType {
  Latest = 'latest',
  Oldest = 'oldest',
  TopActivity = 'topActivity',
}

export type ReactionId = string;

export type CommunityMemberId = Brand<string, 'CommunityMemberId'>;
export const parseCommunityMemberId = (id: string): CommunityMemberId => id as CommunityMemberId;

export type CommunityOwnerId = Brand<string, 'CommunityOwnerId'>;
export const parseCommunityOwnerId = (id: string): CommunityOwnerId => id as CommunityOwnerId;

export type CommunityId = Brand<string, 'CommunityId'>;
export const parseCommunityId = (id: string): CommunityId => id as CommunityId;

export type GroupId = Brand<string, 'GroupId'>;
export const parseGroupId = (id: string): GroupId => id as GroupId;

export type ChannelId = Brand<string, 'ChannelId'>;
export const parseChannelId = (id: string): ChannelId => id as ChannelId;

export type ChannelPostId = Brand<string, 'ChannelPostId'>;
export const parseChannelPostId = (id: string): ChannelPostId => id as ChannelPostId;

export type ChannelPostCommentId = Brand<string, 'ChannelPostCommentId'>;
export const parseChannelPostCommentId = (id: string): ChannelPostCommentId =>
  id as ChannelPostCommentId;

export type Reaction = {
  id: ReactionId;
  authorId: CommunityMemberId;
  icon: string;
  iconCode: string | null;
  addedAt: number;
  iconName: string | null;
};

export interface Attachment {
  id: string;
  originalName: string;
  shortName: string;
  size: number;
  contentType: string;
  duration?: number | null;
  extension: string;
  url: string;
  thumbUrl?: string | null;
  createdBy: string;
}

export interface Community {
  id: CommunityId;
  name: string;
  slug: string;
  description: string | null;
  thumbUrl: string | null;
  createdAt: Date;
  updatedAt: Date;
  createdBy: CommunityOwnerId;
  membersCount: number;
  contentTree: ContentIdTreeNode[];
  videoLibraryId?: string | null;
}

export type ContentIdTreeNode = GroupIdTreeNode | ChannelIdTreeNode;

export interface GroupIdTreeNode {
  id: GroupId;
  type: 'group';
  children: ContentIdTreeNode[];
}

export interface ChannelIdTreeNode {
  id: ChannelId;
  type: 'channel';
}

export interface PermissionForResource {
  level: PermissionLevel;
}

// from more specific to more general, more specific permissions override more general ones
export interface PermissionGroup {
  channels: Record<string, PermissionForResource>;
  groups: Record<string, PermissionForResource>;
  general: PermissionForResource;
}

export interface CommunityPermissions {
  id: CommunityId;
  permissions: Record<CommunityRole | CustomRole, PermissionGroup>;
}

export enum MembersListVariant {
  Grid = 'grid',
  Horizontal = 'horizontal',
}

export interface CommunityMember {
  id: CommunityMemberId;
  communityId: CommunityId;
  communitySlug: Community['slug'] | null;
  communityName: Community['name'] | null;
  communityDescription: Community['description'] | null;
  communityThumbUrl: Community['thumbUrl'] | null;
  communityOwnerId: Community['createdBy'];
  bio: string | null;
  links: {
    website: string | null;
    twitter: string | null;
    github: string | null;
    instagram: string | null;
    youtube: string | null;
    linkedin: string | null;
    twitch: string | null;
  };
  lastSeen: Date | null;
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
  addedAt: Date;
  addedBy: string | null;
  userId: string;
  email: string;
  firstName: string;
  lastName: string | null;
  headline: string | null;
  nickname: string | null;
  avatarUrl: string | null;
  role: CommunityRole | CustomRole;
  disabled: boolean;
  ownerId: string;
  membersListVariant?: MembersListVariant | null;
}

export interface CommunityMemberSettings {
  feed?: CommunityMemberFeedSettings;
  createdAt: Date;
  updatedAt: Date;
}

export interface CommunityMemberFeedSettings {
  postsSorting?: SortingType | null;
}

export interface Group {
  id: GroupId;
  communityId: CommunityId;
  channels: string[];
  name: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
}

export interface ChannelSettings {
  readonly: boolean;
}

export enum ChannelStatus {
  Active = 'active',
  Hidden = 'hidden',
  Archived = 'archived',
  Deleted = 'deleted',
}

export interface BaseChannel {
  id: ChannelId;
  communityId: CommunityId;
  groupId: GroupId | null;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
}

export interface FeedChannel extends BaseChannel {
  slug: string;
  description: string | null;
  settings: ChannelSettings;
  status: ChannelStatus;
  type: ChannelType.Feed;
}

export interface LinkChannel extends BaseChannel {
  url: string;
  type: ChannelType.Link;
  description?: never;
  settings?: never;
  status?: never;
}

export type CommunityChannel = FeedChannel | LinkChannel;
export type CommunityGroup = Group;

export interface ChannelPostSettings {
  hideComments: boolean;
  hideReactions: boolean;
  closeComments: boolean;
  alwaysExpanded: boolean;
}

export enum ChannelPostStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
  Deleted = 'deleted',
  Hidden = 'hidden',
}

export enum ChannelCommentStatus {
  Published = 'published',
  Deleted = 'deleted',
  Hidden = 'hidden',
}

export interface ChannelPost {
  id: ChannelPostId;
  title: string;
  channelId: ChannelId;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: CommunityMemberId;
  author: CommunityMemberViewModel;
  pinned: Date | false;
  reactions: Array<Reaction>;
  commentsCount: number;
  attachments: Attachment[];
  settings: ChannelPostSettings;
  views: number | null;
  status: ChannelPostStatus;
  editedAt?: null | number[];
  videos: string[];
}

export interface CommunityMemberViewModel {
  id: CommunityMemberId;
  firstName: CommunityMember['firstName'];
  lastName: CommunityMember['lastName'];
  nickname: CommunityMember['nickname'];
  avatarUrl: CommunityMember['avatarUrl'];
}

export interface ChannelPostComment {
  id: ChannelPostCommentId;
  communityId: CommunityId;
  channelId: ChannelId;
  postId: ChannelPostId;
  author: CommunityMemberViewModel;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: CommunityMemberId;
  reactions: Array<Reaction>;
  repliesCount: null | number;
  parentCommentId?: ChannelPostCommentId | null;
  attachments: Attachment[];
  status: ChannelCommentStatus;
  editedAt?: null | number[];
  video?: CommunityVideo | null;
}

export interface CommunityVideo extends BunnyVideoObject {
  parentsId: string[];
  fileName: string;
  extension: string;
  size: number;
  url: string | null;
  type: 'bunny';
  authorizationSignature: string;
  authorizationExpire: number;
  uploadStatus: 'created' | 'pending' | 'success' | 'error';
  progress?: number;
  thumbnail: string | null;
  toDelete?: boolean;
  createdAt: Date;
  updatedAt: Date;
  ownerId: string;
  hlsUrl: string | null;
  previewUrl: string | null;
  mp4Url: string | null;
  bunnyStatus: VideoLessonStatus;
}

export enum CommunityMembersDisplayOption {
  Hidden = 'hidden',
  CountOnly = 'count_only',
  FullList = 'full_list',
}

export interface CommunitySettings {
  uploadVideo: boolean;
  membersDisplay?: CommunityMembersDisplayOption;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}
