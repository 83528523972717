import { FunctionsError } from "firebase/functions";

export const cloudFunctionErrorHandler = (e: Error | FunctionsError) => {
  if ("code" in e && typeof e.code === "string") {
    if (e.code.includes("permission-denied")) {
      return {
        code: 403,
        error: e,
      };
    }

    return {
      code: e.code.includes("not-found") ? 404 : 500,
      error: e,
    };
  }

  return {
    code: 500,
    error: e,
  };
};
