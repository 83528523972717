import { createAsyncThunk } from '@reduxjs/toolkit';

import { EmailClient } from '@mailingr/data-models';
import { cloudFunctionErrorHandler } from '@mailingr/utils';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type PayloadAC = {
  url: string;
  apiKey: string;
  client: EmailClient.ActiveCampaign;
};

export type PayloadCK = {
  url: string;
  apiSecret: string;
  client: EmailClient.ConvertKit;
};

export type PayloadPipedrive = {
  url: string;
  apiSecret: string;
  client: EmailClient.Pipedrive;
  tagsFieldName: string;
  listsFieldName: string;
};

export type PayloadMailerLite = {
  url: string;
  apiKey: string;
  client: EmailClient.MailerLite;
};

export type PayloadGetResponse = {
  url: string;
  apiKey: string;
  client: EmailClient.GetResponse;
};

export type PayloadMailChimp = {
  url: string;
  apiKey: string;
  client: EmailClient.MailChimp;
  serverPrefix: string;
};

export type PayloadFreshMail = {
  url: string;
  apiKey: string;
  client: EmailClient.FreshMail;
  firstNameKey: string;
  lastNameKey: string;
};

export const integrateWithEmailClient = createAsyncThunk<
  void,
  ActionParams<
    | PayloadAC
    | PayloadCK
    | PayloadMailerLite
    | PayloadMailChimp
    | PayloadGetResponse
    | PayloadFreshMail
    | PayloadPipedrive
  >,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/integrateWithEmailClient`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-integrateWithEmailClient')(payload);

      analytics.track('integrate_with_email_client', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.(cloudFunctionErrorHandler(e).code);

      return rejectWithValue(e);
    }
  }
);
