import { IntegrationDocument, RequestStatus } from '@mailingr/data-models';

export interface IntegrationReducer {
  status: null | RequestStatus;
  data: null | IntegrationDocument;
  error: null | Error;
  showProductList: boolean;
}

export const INTEGRATION_REDUCER_NAME = 'Integration';
