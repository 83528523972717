export enum ActionType {
  ClientTerminated = 'client_terminated',
  ClientPurchaseProduct = 'client_purchase_product',
  ClientRenewedSubscription = 'client_renewed_product',
  ClientSubscriptionPlanUpdated = 'client_subscription_plan_updated',
  ClientRenewedAccessPeriod = 'client_renewed_access_to_product',
  NewsletterPaymentRequiredAction = 'newsletter_payment_required_action',
  NewsletterSubscriptionEnd = 'newsletter_subscription_end',
  NewsletterAccessPeriodEnd = 'newsletter_access_period_end',
  ClientsImportFinished = 'clients_import_finished',
  ClientCancelSubscriptionAtPeriodEnd = 'client_cancel_subscription_at_period_end',
  ClientResumeSubscriptionAtPeriodEnd = 'client_resume_subscription_at_period_end',
  NewsletterAccessPeriodEndingIn14Days = 'newsletter_access_period_ending_in_14_days',
  NewsletterAccessPeriodEndingIn7Days = 'newsletter_access_period_ending_in_7_days',
  NewsletterAccessPeriodEndingIn3Days = 'newsletter_access_period_ending_in_3_days',
  NewsletterAccessPeriodEndingIn1Day = 'newsletter_access_period_ending_in_1_day',
  NewsletterValidReferralPurchase = 'newsletter_valid_product_referral',
  NewsletterSubscriberGetReward = 'newsletter_subscriber_get_reward',
  ClientHasReferralRewardToConfirm = 'client_has_referral_reward_to_confirm',
  ClientGotReferralReward = 'client_got_referral_reward',
  VideoBeenUploaded = 'video_been_uploaded',
  ClientListProblem = 'client_list_problem',
  ClientTagProblem = 'client_tag_problem',
}

export enum CommunityNotificationActionType {
  NewPostAdded = 'NewPostAdded',
  NewCommentAdded = 'NewCommentAdded',
  NewReactionAdded = 'NewReactionAdded',
  NewCommentReplyAdded = 'NewCommentReplyAdded',
  NewCommentReactionAdded = 'NewCommentReactionAdded',
  NewMentionAdded = 'NewMentionAdded',
}

export enum NotificationStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export type ConnectedClient = {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string | null;
};

export enum NotificationType {
  ClientAction = 'client_action',
  NewsletterInfo = 'newsletter_info',
  CommunityAction = 'community_action',
}

export interface NotificationDocument<T = object> {
  connectedClient?: ConnectedClient | null;
  type: NotificationType;
  eventDate: string;
  data?: T;
  status: NotificationStatus;
  timestamp: Date;
  eventTimestamp: Date;
  connectedClientEmail?: string | null;
  // resource connected with notification e.g. community post id
  resourceId?: string | null;
}

export interface GenericNotification<
  Type extends NotificationType = NotificationType,
  IType = ActionType | CommunityNotificationActionType,
  IData = { [key: string]: any },
> extends NotificationDocument {
  type: Type;
  data: {
    action: IType;
    payload: IData;
  };
}
