export const prefixes = [
  { country: 'AF', value: '93' },
  { country: 'AL', value: '355' },
  { country: 'DZ', value: '213' },
  { country: 'AS', value: '1684' },
  { country: 'AD', value: '376' },
  { country: 'AO', value: '244' },
  { country: 'AI', value: '1264' },
  { country: 'AG', value: '1268' },
  { country: 'AR', value: '54' },
  { country: 'AM', value: '374' },
  { country: 'AW', value: '297' },
  { country: 'AU', value: '61' },
  { country: 'AT', value: '43' },
  { country: 'AZ', value: '994' },
  { country: 'BS', value: '1242' },
  { country: 'BH', value: '973' },
  { country: 'BD', value: '880' },
  { country: 'BB', value: '1246' },
  { country: 'BY', value: '375' },
  { country: 'BE', value: '32' },
  { country: 'BZ', value: '501' },
  { country: 'BJ', value: '229' },
  { country: 'BM', value: '1441' },
  { country: 'BT', value: '975' },
  { country: 'BO', value: '591' },
  { country: 'BA', value: '387' },
  { country: 'BW', value: '267' },
  { country: 'BR', value: '55' },
  { country: 'IO', value: '246' },
  { country: 'VG', value: '1284' },
  { country: 'BN', value: '673' },
  { country: 'BG', value: '359' },
  { country: 'BF', value: '226' },
  { country: 'BI', value: '257' },
  { country: 'KH', value: '855' },
  { country: 'CM', value: '237' },
  { country: 'CA', value: '1' },
  { country: 'CV', value: '238' },
  { country: 'BQ', value: '599' },
  { country: 'KY', value: '1345' },
  { country: 'CF', value: '236' },
  { country: 'TD', value: '235' },
  { country: 'CL', value: '56' },
  { country: 'CN', value: '86' },
  { country: 'CO', value: '57' },
  { country: 'KM', value: '269' },
  { country: 'CD', value: '243' },
  { country: 'CG', value: '242' },
  { country: 'CK', value: '682' },
  { country: 'CR', value: '506' },
  { country: 'CI', value: '225' },
  { country: 'HR', value: '385' },
  { country: 'CU', value: '53' },
  { country: 'CW', value: '599' },
  { country: 'CY', value: '357' },
  { country: 'CZ', value: '420' },
  { country: 'DK', value: '45' },
  { country: 'DJ', value: '253' },
  { country: 'DM', value: '1767' },
  { country: 'DO', value: '1' },
  { country: 'EC', value: '593' },
  { country: 'EG', value: '20' },
  { country: 'SV', value: '503' },
  { country: 'GQ', value: '240' },
  { country: 'ER', value: '291' },
  { country: 'EE', value: '372' },
  { country: 'ET', value: '251' },
  { country: 'FK', value: '500' },
  { country: 'FO', value: '298' },
  { country: 'FJ', value: '679' },
  { country: 'FI', value: '358' },
  { country: 'FR', value: '33' },
  { country: 'GF', value: '594' },
  { country: 'PF', value: '689' },
  { country: 'GA', value: '241' },
  { country: 'GM', value: '220' },
  { country: 'GE', value: '995' },
  { country: 'DE', value: '49' },
  { country: 'GH', value: '233' },
  { country: 'GI', value: '350' },
  { country: 'GR', value: '30' },
  { country: 'GL', value: '299' },
  { country: 'GD', value: '1473' },
  { country: 'GU', value: '1671' },
  { country: 'GT', value: '502' },
  { country: 'GN', value: '224' },
  { country: 'GW', value: '245' },
  { country: 'GY', value: '592' },
  { country: 'HT', value: '509' },
  { country: 'HN', value: '504' },
  { country: 'HK', value: '852' },
  { country: 'HU', value: '36' },
  { country: 'IS', value: '354' },
  { country: 'IN', value: '91' },
  { country: 'ID', value: '62' },
  { country: 'IR', value: '98' },
  { country: 'IQ', value: '964' },
  { country: 'IE', value: '353' },
  { country: 'IL', value: '972' },
  { country: 'IT', value: '39' },
  { country: 'JM', value: '1876' },
  { country: 'JP', value: '81' },
  { country: 'JO', value: '962' },
  { country: 'KZ', value: '7' },
  { country: 'KE', value: '254' },
  { country: 'KI', value: '686' },
  { country: 'KW', value: '965' },
  { country: 'KG', value: '996' },
  { country: 'LA', value: '856' },
  { country: 'LV', value: '371' },
  { country: 'LB', value: '961' },
  { country: 'LS', value: '266' },
  { country: 'LR', value: '231' },
  { country: 'LY', value: '218' },
  { country: 'LI', value: '423' },
  { country: 'LT', value: '370' },
  { country: 'LU', value: '352' },
  { country: 'MO', value: '853' },
  { country: 'MK', value: '389' },
  { country: 'MG', value: '261' },
  { country: 'MW', value: '265' },
  { country: 'MY', value: '60' },
  { country: 'MV', value: '960' },
  { country: 'ML', value: '223' },
  { country: 'MT', value: '356' },
  { country: 'MH', value: '692' },
  { country: 'MQ', value: '596' },
  { country: 'MR', value: '222' },
  { country: 'MU', value: '230' },
  { country: 'MX', value: '52' },
  { country: 'FM', value: '691' },
  { country: 'MD', value: '373' },
  { country: 'MC', value: '377' },
  { country: 'MN', value: '976' },
  { country: 'ME', value: '382' },
  { country: 'MS', value: '1664' },
  { country: 'MA', value: '212' },
  { country: 'MZ', value: '258' },
  { country: 'MM', value: '95' },
  { country: 'NA', value: '264' },
  { country: 'NR', value: '674' },
  { country: 'NP', value: '977' },
  { country: 'NL', value: '31' },
  { country: 'NC', value: '687' },
  { country: 'NZ', value: '64' },
  { country: 'NI', value: '505' },
  { country: 'NE', value: '227' },
  { country: 'NG', value: '234' },
  { country: 'NU', value: '683' },
  { country: 'NF', value: '672' },
  { country: 'KP', value: '850' },
  { country: 'MP', value: '1670' },
  { country: 'NO', value: '47' },
  { country: 'OM', value: '968' },
  { country: 'PK', value: '92' },
  { country: 'PW', value: '680' },
  { country: 'PS', value: '970' },
  { country: 'PA', value: '507' },
  { country: 'PG', value: '675' },
  { country: 'PY', value: '595' },
  { country: 'PE', value: '51' },
  { country: 'PH', value: '63' },
  { country: 'PL', value: '48' },
  { country: 'PT', value: '351' },
  { country: 'PR', value: '1' },
  { country: 'QA', value: '974' },
  { country: 'RE', value: '262' },
  { country: 'RO', value: '40' },
  { country: 'RU', value: '7' },
  { country: 'RW', value: '250' },
  { country: 'SH', value: '290' },
  { country: 'KN', value: '1869' },
  { country: 'LC', value: '1758' },
  { country: 'PM', value: '508' },
  { country: 'VC', value: '1784' },
  { country: 'WS', value: '685' },
  { country: 'SM', value: '378' },
  { country: 'ST', value: '239' },
  { country: 'SA', value: '966' },
  { country: 'SN', value: '221' },
  { country: 'RS', value: '381' },
  { country: 'SC', value: '248' },
  { country: 'SL', value: '232' },
  { country: 'SG', value: '65' },
  { country: 'SX', value: '1721' },
  { country: 'SK', value: '421' },
  { country: 'SI', value: '386' },
  { country: 'SB', value: '677' },
  { country: 'SO', value: '252' },
  { country: 'ZA', value: '27' },
  { country: 'KR', value: '82' },
  { country: 'SS', value: '211' },
  { country: 'ES', value: '34' },
  { country: 'LK', value: '94' },
  { country: 'SD', value: '249' },
  { country: 'SR', value: '597' },
  { country: 'SZ', value: '268' },
  { country: 'SE', value: '46' },
  { country: 'CH', value: '41' },
  { country: 'SY', value: '963' },
  { country: 'TW', value: '886' },
  { country: 'TJ', value: '992' },
  { country: 'TZ', value: '255' },
  { country: 'TH', value: '66' },
  { country: 'TL', value: '670' },
  { country: 'TG', value: '228' },
  { country: 'TK', value: '690' },
  { country: 'TO', value: '676' },
  { country: 'TT', value: '1868' },
  { country: 'TN', value: '216' },
  { country: 'TR', value: '90' },
  { country: 'TM', value: '993' },
  { country: 'TC', value: '1649' },
  { country: 'TV', value: '688' },
  { country: 'VI', value: '1340' },
  { country: 'UG', value: '256' },
  { country: 'UA', value: '380' },
  { country: 'AE', value: '971' },
  { country: 'GB', value: '44' },
  { country: 'US', value: '1' },
  { country: 'UY', value: '598' },
  { country: 'UZ', value: '998' },
  { country: 'VU', value: '678' },
  { country: 'VA', value: '39' },
  { country: 'VE', value: '58' },
  { country: 'VN', value: '84' },
  { country: 'WF', value: '681' },
  { country: 'YE', value: '967' },
  { country: 'ZM', value: '260' },
  { country: 'ZW', value: '263' },
];
