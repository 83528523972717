import {
  ClientProductCourseProgressDocument,
  LessonType,
  ProductContentDocument,
  ProgressStatistics,
} from '@mailingr/data-models';

export const getClientsProgress = (
  productContent: ProductContentDocument,
  productCourseProgress: ClientProductCourseProgressDocument[]
): ProgressStatistics[] => {
  if (!productContent || !productCourseProgress) {
    return [];
  }

  const lessons = productContent.modules.map((module) => module.lessons).flat();
  const progress = productCourseProgress.map((progress) => {
    let firstSessionAt: null | number = progress.firstSessionAt || null;
    let lastSessionAt: null | number = progress.lastSessionAt || null;
    const status = lessons.reduce<{
      completed: number;
      totality: number;
      totalLessons: number;
      completedLessons: number;
    }>(
      (prev, current) => {
        prev.completed =
          prev.completed +
          (current.type === LessonType.Video &&
          progress &&
          progress.lessonsProgress[current.id]?.completed
            ? (current.length ?? 0)
            : 0);
        prev.totality =
          prev.totality + (current.type === LessonType.Video ? (current.length ?? 0) : 0);
        prev.totalLessons = prev.totalLessons + 1;
        prev.completedLessons =
          prev.completedLessons + (progress.lessonsProgress[current.id]?.completed ? 1 : 0);

        const lessonProgress = progress.lessonsProgress[current.id];
        if (lessonProgress) {
          if (!firstSessionAt || lessonProgress.updatedAt < firstSessionAt) {
            firstSessionAt = lessonProgress.updatedAt;
          }

          if (!lastSessionAt || lessonProgress.updatedAt > lastSessionAt) {
            lastSessionAt = lessonProgress.updatedAt;
          }
        }

        return prev;
      },
      { totality: 0, completed: 0, totalLessons: 0, completedLessons: 0 }
    );

    return {
      ...progress,
      totalLessons: status.totalLessons,
      completedLessons: status.completedLessons,
      firstSessionAt,
      lastSessionAt,
      courseProgress: Math.ceil((status.completed / status.totality) * 100),
    };
  });

  return progress;
};
