import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommunityChannel, RequestStatus } from '@mailingr/data-models';

import { createCommunityChannel } from './actions/createCommunityChannel';
import { COMMUNITY_CHANNELS_REDUCER_NAME, CommunityChannelsReducer } from './types';

const initialState: CommunityChannelsReducer = {
  adding: false,
  error: null,
  data: [],
  requestStatus: null,
  searchTerm: null,
};

const communityChannelsSlice = createSlice({
  name: COMMUNITY_CHANNELS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToCommunityChannelsStarted(state) {
      state.requestStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToCommunityChannelsSucceeded(state, { payload }: PayloadAction<CommunityChannel[]>) {
      state.requestStatus = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToCommunityChannelsFailed(state, { payload }: PayloadAction<Error>) {
      state.requestStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromCommunityChannels(state) {
      state.data = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCommunityChannel.pending, (state) => {
      state.adding = true;
    });
    builder.addCase(createCommunityChannel.fulfilled, (state) => {
      state.adding = false;
    });
    builder.addCase(createCommunityChannel.rejected, (state) => {
      state.adding = false;
    });
  },
});

export const {
  subscribeToCommunityChannelsStarted,
  subscribeToCommunityChannelsFailed,
  subscribeToCommunityChannelsSucceeded,
  unsubscribeFromCommunityChannels,
} = communityChannelsSlice.actions;
export default communityChannelsSlice.reducer;
