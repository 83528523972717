import { createAsyncThunk } from '@reduxjs/toolkit';

import { AutomationTaskWithoutTriggers } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  oldEmail: string;
  newEmail: string;
  productId: string;
  tasksForOldEmail: AutomationTaskWithoutTriggers[];
  tasksForNewEmail: AutomationTaskWithoutTriggers[];
  sendEmailNotification: boolean;
};

export const changeProductClientEmail = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${PRODUCT_REDUCER_NAME}/changeProductClientEmail`,
  async (
    payload,
    { rejectWithValue, extra: { functions, analytics, auth, firestoreSubscriptions } }
  ) => {
    try {
      const user = auth().currentUser;
      firestoreSubscriptions.newsletterListener?.();
      await functions.httpsCallable('products-changeProductClientEmail')(payload);
      analytics.track('change_product_client_email', {
        productId: payload.productId,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
