import { ArchivedEmailDocument, RequestStatus } from '@mailingr/data-models';

export const ARCHIVE_REDUCER_NAME = 'Archive';

export interface ArchiveReducer {
  status: RequestStatus | null;
  error: null | string;
  fetchingNextPage: boolean;
  isLastPage: boolean;
  fetchingFirstPage: boolean;
  data: ArchivedEmailDocument[];
  pageSize: number;
}
