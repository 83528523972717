import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, OrderDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchProductOrderByEmail = createAsyncThunk<
  OrderDocument[],
  { productId: string; email: string; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchProductOrderByEmail`,
  async (
    { productId, email, ownerId },
    { rejectWithValue, getState, extra: { auth, db, firestoreSubscriptions } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const { productOrdersListSize } = getState().product;

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_ORDERS)
        .where('clientEmail', '==', email)
        .orderBy('createdAt', 'desc')
        .limit(productOrdersListSize);

      const querySnapshot = await ref.get();

      if (!querySnapshot.empty) {
        firestoreSubscriptions.lastOrderCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
      }

      return querySnapshot.docs.map((doc) => firestoreDateMapper<OrderDocument>(doc));
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
