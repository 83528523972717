import firebase from 'firebase/compat';

import { UserActivityStatus } from '@mailingr/data-models';

export const setUserStatus = async (database: typeof firebase.database, userId: string) => {
  const userStatusDatabaseRef = database().ref(`/status/${userId}`);

  const isOfflineForDatabase = {
    state: UserActivityStatus.Offline,
    lastChanged: database.ServerValue.TIMESTAMP,
  };

  const isOnlineForDatabase = {
    state: UserActivityStatus.Online,
    lastChanged: database.ServerValue.TIMESTAMP,
  };

  return database()
    .ref('.info/connected')
    .on('value', function (snapshot: firebase.database.DataSnapshot) {
      if (snapshot.val() == false) {
        return;
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Error with user status', e);
        });
    });
};
