import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductInfoResponse } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  checkoutUrl: string;
  priceId?: string | null;
  accountId: string | null;
  renewal?: boolean;
  email?: string;
};

export const getCheckoutProductInfo = createAsyncThunk<
  ProductInfoResponse,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/getCheckoutProductInfo`,
  async (
    {
      payload: { checkoutUrl, accountId, priceId, productId, renewal, email },
      onSuccess,
      onFailure,
    },
    { rejectWithValue, extra: { analytics } }
  ) => {
    try {
      const res = await fetch(checkoutUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountId,
          productId,
          priceId,
          renewal,
          email,
        }),
      });
      analytics.track('get_checkout_product_info', {
        productId,
        priceId,
        accountId,
      });
      onSuccess?.();
      if (!res.ok) {
        throw new Error(await res.json());
      }
      const body: ProductInfoResponse = await res.json();

      return body;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
