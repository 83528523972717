import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscountDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { DISCOUNT_REDUCER_NAME } from '../types';

type Payload = DiscountDTO;

export const updateDiscount = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${DISCOUNT_REDUCER_NAME}/updateDiscount`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('discounts-updateDiscount')(payload);
      analytics.track('update-discount', {
        discountId: payload.id,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
