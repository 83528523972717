import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export const generatePaywallKeys = createAsyncThunk<
  void,
  ActionParams<void>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/generatePaywallKeys`,
  async ({ onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged');
      }

      await functions.httpsCallable('products-generatePaywallKeys')();

      analytics.track('generate_paywall_keys', {
        userId: user.uid,
        email: user.email,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
