import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  subscriptionId: string;
  authorId: string;
  productId: string;
  authorStripeId: string;
  newPriceId: string;
};

export const updateCurrentClientPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REDUCER_NAME}/updateCurrentClientPlan`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('products-updateCurrentClientPlan')({
        ...payload,
        uid: user?.uid,
        email: user?.email,
      });
      analytics.track('update_subscription_plan', {
        productId: payload.productId,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
