import { RequestStatus } from '@mailingr/data-models';

export const REFERRALS_REDUCER_NAME = 'referrals';
export interface ReferralsReducer {
  referralCode: string | null;
  statistics: null | {
    registrations: number;
  };
  status: RequestStatus | null;
}
