import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';

type Payload = {
  sendInvoiceToClient: boolean;
  updateClientData?: boolean;
  orderId: string;
  productId: string;
  ownerId: string;
};

export const issueInvoice = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${NEWSLETTERS_REDUCER_NAME}/issueInvoice`,
  async (payload, { rejectWithValue, extra: { functions, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      await functions.httpsCallable('invoices-retryInvoiceIssueForOrder')(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
