import { createAsyncThunk } from '@reduxjs/toolkit';
import capitalize from 'lodash.capitalize';
import orderBy from 'lodash.orderby';
import uniqBy from 'lodash.uniqby';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const searchProductClient = createAsyncThunk<
  ProductClientDocument[],
  { emailOrLastName: string; productId: string; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/searchProductClient`,
  async (
    { emailOrLastName, productId, ownerId },
    { rejectWithValue, extra: { auth, db }, getState }
  ) => {
    try {
      const { productClientsListSize } = getState().product;
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const refEmail = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('email', '>=', emailOrLastName)
        .where('email', '<=', emailOrLastName + '~')
        .limit(productClientsListSize);

      const refName = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('lastName', '>=', emailOrLastName)
        .where('lastName', '<=', emailOrLastName + '~')
        .limit(productClientsListSize);

      const refCaptalizeName = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('lastName', '>=', capitalize(emailOrLastName))
        .where('lastName', '<=', capitalize(emailOrLastName) + '~')
        .limit(productClientsListSize);

      const res = await Promise.all([refEmail.get(), refName.get(), refCaptalizeName.get()]);
      const list = res
        .map((snapshot) => snapshot.docs)
        .flat()
        .map((doc) => firestoreDateMapper<ProductClientDocument>(doc));

      return orderBy(uniqBy(list, 'email'), 'createdAt', 'desc');
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
