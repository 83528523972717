import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '@mailingr/data-models';

import { REFERRALS_REDUCER_NAME, ReferralsReducer } from './types';

const initialState: ReferralsReducer = {
  referralCode: null,
  status: null,
  statistics: null,
};

const reducerSlice = createSlice({
  initialState,
  name: REFERRALS_REDUCER_NAME,
  reducers: {
    subscribeToUserReferralsStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
    },
    subscribeToUserReferralsSuccess(
      state,
      { payload }: PayloadAction<Omit<ReferralsReducer, 'status'>>
    ) {
      state.status = RequestStatus.SUBSCRIBED;
      state.referralCode = payload.referralCode;
      state.statistics = payload.statistics;
    },
    subscribeToUserReferralsFailed(state, _action: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
    },
    unsubscribeFromUserReferrals(state) {
      state.status = null;
      state.referralCode = null;
      state.statistics = null;
    },
  },
});

export const {
  subscribeToUserReferralsStarted,
  subscribeToUserReferralsSuccess,
  subscribeToUserReferralsFailed,
  unsubscribeFromUserReferrals,
} = reducerSlice.actions;
export default reducerSlice.reducer;
