import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ChannelId,
  FIRESTORE_COLLECTION as COLLECTION,
  CommunityId,
  ContentIdTreeNode,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';

const removeChannelFromContentTree = (
  contentTree: ContentIdTreeNode[],
  channelId: ChannelId
): ContentIdTreeNode[] => {
  return contentTree
    .map((node) => {
      if (node.type === 'group' && node.children) {
        return {
          ...node,
          children: removeChannelFromContentTree(node.children, channelId),
        };
      }

      return node;
    })
    .filter((node) => node.type !== 'channel' || node.id !== channelId);
};

export const deleteCommunityChannel = createAsyncThunk<
  void,
  ActionParams<{ communityId: CommunityId; channelId: ChannelId }>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_CHANNELS_REDUCER_NAME}/deleteCommunityChannel`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged in');
      }

      const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
      const community = await communityRef.get();

      const channelRef = communityRef
        .collection(COLLECTION.COMMUNITY_CHANNELS)
        .doc(payload.channelId);

      await db.runTransaction(async (transaction) => {
        transaction.delete(channelRef);

        const contentTree: ContentIdTreeNode[] = community.data()?.contentTree || [];
        const updatedTree = removeChannelFromContentTree(contentTree, payload.channelId);

        transaction.update(communityRef, {
          contentTree: updatedTree,
          updatedAt: new Date(),
        });
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();
      // eslint-disable-next-line no-console
      console.error({ payload, error: e });

      return rejectWithValue(e);
    }
  }
);
