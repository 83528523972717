import { UserActivityStatus } from '@mailingr/data-models';

import { AppThunk } from '../../../index';
import { logInFailed } from '../reducer';
import { setUserStatus } from '../userPresence/setUserStatusInRealtimeDatabase';
import { setUserStatusManually } from '../userPresence/setUserStatusManually';

export const setUserPresence =
  (state?: UserActivityStatus): AppThunk =>
  async (dispatch, getState, { database, firestore }) => {
    try {
      const { user } = getState();

      if (user?.data) {
        const { uid: userId } = user.data;

        if (!userId) {
          return;
        }

        if (state) {
          await setUserStatusManually({ database, userId, state, firestore });

          return;
        }

        if (database) {
          await setUserStatus(database, userId);
        }
      }
    } catch (e) {
      dispatch(logInFailed(e.code));
    }
  };
