import { BaseDocument } from './BaseDocument';
import { GainedRewardStatus } from './ProductClientReferrals';

export enum ProductReferralsStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended',
}

export enum RewardType {
  Digital = 'digital',
  Physical = 'physical',
  CouponCode = 'couponCode',
}

export enum ApprovalType {
  Automatically = 'automatically',
  Manual = 'manual',
}

export enum RewardStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface RefCodeOwner {
  firstName: string;
  lastName: string;
  uid: string;
  email: string;
}

export interface RewardWinner extends RefCodeOwner {
  timestamp: number;
  status: GainedRewardStatus;
}

export interface RewardStats {
  clients: RewardWinner[];
}

export interface EmailTemplate {
  json: string;
  html: string;
  subject: string;
  id: string;
  version: number;
}

export interface BaseReward {
  id: string;
  name: string;
  type: RewardType;
  image: string | null;
  description: string | null;
  numberOfReferrals: number;
  recurring: boolean;
  approval: ApprovalType;
  settings: {
    emailTemplate: EmailTemplate | null;
  };
  stats: RewardStats;
  status: RewardStatus;
  rewardType: null | ReferralRewardType;
  product?: null | string;
  priceId?: null | string;
  sendEmailAboutAccountCreation?: boolean;
}

export type Reward = BaseReward;

export type RewardClientInfo = Omit<Reward, 'stats' | 'settings'>;

export interface ProductReferralsDocument extends BaseDocument {
  productId: string;
  status: ProductReferralsStatus;
  totalReferrals: number;
  clients: {
    [email: string]: number;
  };
  rewards: Reward[];
  version: number;
  synced?: boolean | 'processing' | 'syncing';
}

export enum EmailValidationStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  Disposable = 'disposable',
  Catchall = 'catchall',
  Unknown = 'unknown',
  Duplicated = 'duplicated',
  DuplicatedWithAlias = 'duplicatedWithAlias',
}

export interface EmailValidation {
  result: EmailValidationStatus;
  flags: string[];
  suggested_correction: string;
  address_info: {
    domain: string;
    host: string;
    original_email: string;
    normalized_email: string;
    addr: string;
    alias: string;
    fqdn: string;
    tld: string;
  };
}

export enum ReferralRewardType {
  ImportToProduct = 'import_to_product',
}
