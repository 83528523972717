import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductResourceCategory } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  id: string;
  categories: ProductResourceCategory[];
};

export const updateResourceCategories = createAsyncThunk<
  string,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/updateResourceCategories`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { db, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.id);

      await ref.update({
        resourceCategories: payload.categories,
      });

      analytics.track('update_resource_categories', {
        productId: payload.id,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();

      return 'test';
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
