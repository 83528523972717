import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchAllProductClients = createAsyncThunk<
  { list: ProductClientDocument[] },
  { productId: string; type: 'all' | 'archived' | 'active' },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchAllProductClients`,
  async ({ productId, type }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('status', 'in', type === 'all' ? ['active', 'archived'] : [type])
        .orderBy('createdAt', 'desc');

      const res = await ref.get();
      const list = res.docs.map((doc) => firestoreDateMapper<ProductClientDocument>(doc));

      return {
        list,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
