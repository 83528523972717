import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, UpdateCommunityChannelDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';

export const updateCommunityChannel = createAsyncThunk<
  void,
  ActionParams<UpdateCommunityChannelDTO>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_CHANNELS_REDUCER_NAME}/updateCommunityChannel`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged');
      }
      const channelRef = db
        .collection(FIRESTORE_COLLECTION.COMMUNITIES)
        .doc(payload.communityId)
        .collection(FIRESTORE_COLLECTION.COMMUNITY_CHANNELS)
        .doc(payload.id);

      const { id, communityId, ...updateData } = payload;

      await channelRef.update({
        ...updateData,
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
