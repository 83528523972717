import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/compat';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = { id: string };

export const archiveProduct = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/archiveProduct`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('products-archiveProductV2')(payload);
      analytics.track('archive_mailing', {
        productId: payload.id,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      const error = e as firebase.functions.HttpsError;
      onFailure?.(error.code);

      return rejectWithValue(e);
    }
  }
);
