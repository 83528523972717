import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ArchivedEmailStatus,
  ArchivedEmailVisibility,
  ArchiveStrategyAccess,
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductAutomationDocument,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  settings: ProductAutomationDocument['archiveSettings'] | null;
};

export const updateProductAutomationArchiveSettings = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<unknown>
>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationArchiveSettings`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error('product automation data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);

      await ref.update({
        archiveSettings: payload.settings
          ? payload.settings
          : {
              accessStrategy: ArchiveStrategyAccess.Unlimited,
              automation: {
                onNewEmail: {
                  privateArchiveStatus: ArchivedEmailStatus.Public,
                  publicArchiveStatus: ArchivedEmailVisibility.Hidden,
                },
              },
            },
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
