import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import { Alert, Badge, Card, Carousel, Divider, Modal, Skeleton, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStore, productActions } from '@mailingr/app-store';
import {
  EndAccessStrategy,
  PaymentTypeOption,
  PriceConfig,
  PriceInfoV2,
  RequestStatus,
} from '@mailingr/data-models';

import { useAppDispatch } from '~/initializeStore';
import * as styles from '../Checkout.module.scss';
import CardLogo from './assets/card_logo.svg?react';
import P24Logo from './assets/p24_logo.svg?react';

interface OwnProps {
  productId: string | null;
  priceId: string | null;
  accountId: string;
  setPrice?: (id: string) => void;
  renewal?: boolean;
  email?: string;
}

const PRODUCT_INFO_URL = import.meta.env.VITE_CHECKOUT_PRODUCT_INFO_URL as string;

type Props = OwnProps;

const ProductDetailsCard: FunctionComponent<Props> = ({
  priceId,
  productId,
  accountId,
  setPrice,
  renewal,
  email,
}) => {
  const { t } = useTranslation(['common', 'checkout', 'products']);
  const dispatch = useAppDispatch();
  const { checkoutProductInfo, checkoutProductInfoStatus } = useSelector(
    (store: AppStore) => store.product
  );
  const previousValue = useRef(true);
  const previousValuePrice = useRef(true);

  const isActive = checkoutProductInfo?.active;
  const isActivePrice = checkoutProductInfo?.price?.active;

  useEffect(() => {
    if (!productId) {
      return;
    }
    dispatch(
      productActions.getCheckoutProductInfo({
        payload: { productId, priceId, accountId, renewal, email, checkoutUrl: PRODUCT_INFO_URL },
      })
    );
  }, [productId, priceId, accountId, dispatch, renewal, email]);

  const isRenewalFlow = checkoutProductInfo && renewal && 'strategy' in checkoutProductInfo;

  useEffect(() => {
    if (isActive === false && isActive !== previousValue.current) {
      previousValue.current = isActive ?? true;
      Modal.error({
        title: t<string>('checkout:detailsCard.inactiveTitle'),
        content: t<string>('checkout:detailsCard.inactiveDesc'),
      });
    }
  }, [isActive, t]);

  useEffect(() => {
    if (isRenewalFlow && checkoutProductInfo?.price) {
      setPrice?.(checkoutProductInfo?.price.id);
    }
  }, [checkoutProductInfo?.price, isRenewalFlow, setPrice]);

  useEffect(() => {
    if (isActivePrice === false && isActivePrice !== previousValuePrice.current) {
      previousValuePrice.current = isActivePrice ?? true;
      Modal.error({
        title: t<string>('checkout:detailsCard.inactiveTitle'),
        content: t<string>('checkout:detailsCard.inactiveDesc'),
      });
    }
  }, [isActivePrice, t]);

  const currentAccessPeriod = useMemo(() => {
    if (!priceId) {
      return null;
    }
    if (!checkoutProductInfo || checkoutProductInfo?.isFree) {
      return null;
    }
    if (checkoutProductInfo && 'prices' in checkoutProductInfo) {
      const selectedPrice = checkoutProductInfo.prices?.find(
        (price) => price.id === priceId && price.type !== PaymentTypeOption.NFT
      );
      if (
        !selectedPrice &&
        checkoutProductInfo.price &&
        'accessPeriod' in checkoutProductInfo.price
      ) {
        // @ts-ignore
        return checkoutProductInfo.price.accessPeriod;
      }

      return selectedPrice?.accessPeriod;
    }

    return null;
  }, [priceId, checkoutProductInfo]);

  const currentPrice = useMemo(() => {
    if (!priceId) {
      return renewal ? t<string>('checkout:renewalAccess') : t<string>('checkout:selectPrice');
    }
    if (!checkoutProductInfo || checkoutProductInfo?.isFree) {
      return '';
    }
    if (checkoutProductInfo && 'prices' in checkoutProductInfo) {
      const selectedPrice = checkoutProductInfo.prices.find((price) => price.id === priceId);
      if (!selectedPrice && checkoutProductInfo?.price) {
        return `${
          checkoutProductInfo?.price.amount
        } ${checkoutProductInfo?.price.currency?.toUpperCase()}`;
      }
      if (!selectedPrice || !('amount' in selectedPrice)) {
        return t<string>('checkout:selectPrice');
      }

      return `${selectedPrice.amount} ${selectedPrice.currency?.toUpperCase()}`;
    }

    return '';
  }, [priceId, checkoutProductInfo, renewal, t]);

  const getStatus = () => {
    if (!checkoutProductInfo) {
      return 'none';
    }
    if (!checkoutProductInfo.active) {
      return 'inactive';
    }
    if (checkoutProductInfo.isFree) {
      return 'free';
    }
    if (checkoutProductInfo.price?.active === false) {
      return 'inactive';
    }

    return 'paid';
  };

  const status = getStatus();

  const labelMapper = {
    none: '',
    free: currentPrice,
    paid: currentPrice,
    inactive: t<string>('checkout:detailsCard.inactive'),
  };

  const countDiscount = (amount: number, priceConfig?: PriceConfig | null) => {
    if (!priceConfig || !priceConfig.showAsPromo) {
      return null;
    }

    const percent = 100 - (100 * amount) / (priceConfig.showAsPromo.priceBeforeDiscount / 100);

    return percent.toFixed(0);
  };

  const setPriceHandler = useCallback(() => {
    if (!setPrice || !checkoutProductInfo?.price || checkoutProductInfo?.isFree) {
      return;
    }

    setPrice(checkoutProductInfo.price.priceId);
  }, [checkoutProductInfo, setPrice]);

  return (
    <div>
      {checkoutProductInfoStatus === RequestStatus.FAILED && (
        <Alert
          className={styles.banner}
          banner
          showIcon
          type="error"
          message={t<string>('checkout:failure.productError')}
        />
      )}
      {status === 'inactive' && (
        <Alert
          className={styles.banner}
          banner
          showIcon
          type="error"
          message={labelMapper[status]}
        />
      )}
      <Skeleton active loading={checkoutProductInfoStatus === RequestStatus.FETCHING}>
        {checkoutProductInfo ? (
          <Space direction="vertical" style={{ width: '100%' }} size={16}>
            <div className={styles.productTitle}>
              <div>
                <Typography.Title level={2} className={styles.text}>
                  {checkoutProductInfo.name}
                </Typography.Title>
              </div>
              <div>
                <Typography.Title level={2} className={styles.text}>
                  {currentPrice}
                </Typography.Title>
              </div>
            </div>

            <div style={{ maxWidth: 200, margin: '10px auto', overflow: 'hidden' }}>
              <Carousel>
                {checkoutProductInfo.images.map((image, i) => (
                  <div key={i}>
                    <img
                      src={image}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>

            {checkoutProductInfo.cart?.pl.creator === '' ? null : (
              <Typography.Title level={4} className={styles.text}>
                {t<string>('checkout:detailsCard.creator')}{' '}
                {checkoutProductInfo.author
                  ? checkoutProductInfo.author.firstName + ' ' + checkoutProductInfo.author.lastName
                  : checkoutProductInfo.cart?.pl.creator || checkoutProductInfo.owner}
              </Typography.Title>
            )}

            <Typography.Paragraph className={styles.text}>
              {checkoutProductInfo.description}
            </Typography.Paragraph>

            {renewal && 'strategy' in checkoutProductInfo ? (
              <>
                <Space direction="vertical" size={4}>
                  <Typography.Paragraph className={styles.renewalInfo}>
                    {t<string>('checkout:detailsCard.endAccess')}{' '}
                    <b>
                      {dayjs.unix(checkoutProductInfo.currentPeriodEnd).format('DD.MM.YY HH:mm')}
                    </b>
                  </Typography.Paragraph>
                  {priceId && currentAccessPeriod && (
                    <Typography.Paragraph className={styles.renewalInfo}>
                      {t<string>('checkout:detailsCard.renewalAccess')}{' '}
                      <b>
                        {dayjs
                          .unix(checkoutProductInfo.currentPeriodEnd)
                          .add(currentAccessPeriod.interval_count, currentAccessPeriod.interval)
                          .format('DD.MM.YY HH:mm')}
                      </b>
                    </Typography.Paragraph>
                  )}
                </Space>
                {checkoutProductInfo.price && checkoutProductInfo.price.amount > 0 && (
                  <Badge.Ribbon
                    text={t<string>('checkout:endAccessStrategy.offerWithTheSamePrice')}
                    color="pink"
                    style={{
                      top: 'unset',
                      bottom: 10,
                      visibility:
                        checkoutProductInfo.strategy === EndAccessStrategy.OfferWithTheSamePrice
                          ? 'visible'
                          : 'hidden',
                    }}
                  >
                    <Card
                      key={checkoutProductInfo.price.priceId}
                      className={styles.priceCard}
                      style={
                        priceId === checkoutProductInfo.price.priceId
                          ? {
                              backgroundColor: '#fff',
                            }
                          : undefined
                      }
                      onClick={setPriceHandler}
                    >
                      <div className={styles.priceInfo}>
                        <Typography.Paragraph strong style={{ marginBottom: 0 }}>
                          <input
                            type="radio"
                            checked={priceId === checkoutProductInfo.price.priceId}
                            onChange={setPriceHandler}
                            className={styles.priceRadio}
                          />
                          {checkoutProductInfo.price.customLabel ||
                            t(
                              `products:paymentTypeOptions.${checkoutProductInfo.price.accessPeriod?.type}`
                            )}
                          {checkoutProductInfo.price.customLabel
                            ? ''
                            : checkoutProductInfo.price.accessPeriod?.interval
                              ? ` (${t(
                                  `products:one_time.${checkoutProductInfo.price.accessPeriod?.interval}`,
                                  {
                                    count: checkoutProductInfo.price.accessPeriod?.interval_count,
                                  }
                                )})`
                              : null}
                        </Typography.Paragraph>
                        <Typography.Paragraph strong style={{ marginBottom: 0 }}>
                          {checkoutProductInfo.price.amount}{' '}
                          {checkoutProductInfo.price.currency?.toUpperCase()}
                        </Typography.Paragraph>
                      </div>
                      <div className={styles.pricePaymentMethods}>
                        <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
                          {t<string>('checkout:paymentMethods')}
                        </Typography.Paragraph>
                        <div className={styles.stripeMethods}>
                          <CardLogo />
                          {checkoutProductInfo.price.accessPeriod?.type === 'one_time' && (
                            <P24Logo width={50} />
                          )}
                        </div>
                      </div>
                    </Card>
                  </Badge.Ribbon>
                )}

                {'prices' in checkoutProductInfo && checkoutProductInfo.prices.length ? (
                  <Divider className={styles.pricesDivider}>
                    {t<string>('checkout:otherPrices')}
                  </Divider>
                ) : null}
              </>
            ) : null}

            {'prices' in checkoutProductInfo
              ? checkoutProductInfo.prices
                  .filter((item): item is PriceInfoV2 => item.type !== PaymentTypeOption.NFT)
                  // @ts-ignore
                  .map(({ id, type, accessPeriod, amount, currency, customLabel, config }) => (
                    <Badge.Ribbon
                      text={t<string>('checkout:promo', {
                        discount: countDiscount(amount, config),
                      })}
                      color="red"
                      key={id}
                      style={{
                        top: 'unset',
                        bottom: 10,
                        visibility: config?.showAsPromo ? 'visible' : 'hidden',
                      }}
                    >
                      <Card
                        className={styles.priceCard}
                        style={
                          priceId === id
                            ? {
                                backgroundColor: '#fff',
                              }
                            : undefined
                        }
                        onClick={() => setPrice?.(id)}
                      >
                        <div className={styles.priceInfo}>
                          <Typography.Paragraph strong style={{ marginBottom: 0 }}>
                            <input
                              type="radio"
                              checked={priceId === id}
                              onChange={() => setPrice?.(id)}
                              className={styles.priceRadio}
                            />
                            {customLabel || t<string>(`products:paymentTypeOptions.${type}`)}
                            {customLabel
                              ? ''
                              : type === 'recurring' || (type === 'one_time' && accessPeriod)
                                ? ` (${t<string>(`products:${type}.${accessPeriod?.interval}`, {
                                    count: accessPeriod?.interval_count,
                                  })})`
                                : ''}
                          </Typography.Paragraph>
                          {config?.showAsPromo ? (
                            <Space direction="horizontal">
                              <Typography.Paragraph
                                strong
                                type="danger"
                                style={{ marginBottom: 0 }}
                              >
                                {amount} {currency?.toUpperCase()}
                              </Typography.Paragraph>
                              <Typography.Paragraph delete style={{ marginBottom: 0 }}>
                                {config.showAsPromo.priceBeforeDiscount / 100}{' '}
                                {currency?.toUpperCase()}
                              </Typography.Paragraph>
                            </Space>
                          ) : (
                            <Typography.Paragraph strong style={{ marginBottom: 0 }}>
                              {amount} {currency?.toUpperCase()}
                            </Typography.Paragraph>
                          )}
                        </div>
                        <div className={styles.pricePaymentMethods}>
                          <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
                            {t<string>('checkout:paymentMethods')}
                          </Typography.Paragraph>
                          <div className={styles.stripeMethods}>
                            <CardLogo />
                            {type === 'one_time' && ['eur', 'pln'].includes(currency) ? (
                              <P24Logo width={50} />
                            ) : null}
                          </div>
                        </div>
                        {config?.alertInfo ? (
                          <div>
                            <Alert
                              style={{ marginTop: 10 }}
                              type="warning"
                              showIcon
                              message={config?.alertInfo}
                            />
                          </div>
                        ) : null}
                      </Card>
                    </Badge.Ribbon>
                  ))
              : null}
          </Space>
        ) : null}
      </Skeleton>
    </div>
  );
};

export default ProductDetailsCard;
