import auth from './auth.json';
import chat from './chat.json';
import checkout from './checkout.json';
import common from './common.json';
import communities from './communities.json';
import content from './content.json';
import dashboard from './dashboard.json';
import newsletters from './newsletters.json';
import products from './products.json';
import settings from './settings.json';
import subscription from './subscription.json';
import profile from './userProfile.json';

export default {
  common,
  auth,
  dashboard,
  profile,
  products,
  checkout,
  chat,
  settings,
  newsletters,
  subscription,
  content,
  communities,
};
