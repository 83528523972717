import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { NEWSLETTER_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
};

export const revalidateEmbeddedForm = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${NEWSLETTER_REDUCER_NAME}/revalidateEmbeddedForm`,
  async (payload, { extra: { functions, analytics } }) => {
    await functions.httpsCallable('newsletters-revalidateEmbeddedForm')(payload);

    analytics.track('revalidate_embedded_form', { product_id: payload.productId });
  }
);
