import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientReferrals } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
};

export const fetchReferralsRanking = createAsyncThunk<
  ProductClientReferrals[],
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REFERRALS_REDUCER_NAME}/fetchReferralsRanking`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productReferrals;

      if (!data) {
        throw new Error('product referrals data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_REFERRALS)
        .orderBy('totalValidReferrals', 'desc');

      const res = await ref.get();

      onSuccess?.();

      return res.docs.map((doc) =>
        firestoreDateMapper<ProductClientReferrals>(doc, (data) => ({ ...data, email: doc.id }))
      );
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
