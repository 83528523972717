import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  FreeAccessDocument,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchFreeAccessInvitation = createAsyncThunk<
  { isLastPage: boolean; list: FreeAccessDocument[] },
  { productId: string; email?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchFreeAccessInvitation`,
  async (
    { productId, email },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const { freeAccessListSize } = getState().product;

      let ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS)
        .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_FREE_ACCESS)
        .orderBy('createdAt', 'desc')
        .limit(freeAccessListSize);

      if (email) {
        ref = ref.where('email', '==', email);
      }

      const res = await ref.get();
      const list = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as FreeAccessDocument);

      if (!res.empty) {
        firestoreSubscriptions.lastFreeAccessInvitationCursor = res.docs[res.docs.length - 1];
      }

      return {
        list,
        isLastPage: list.length < freeAccessListSize,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
