import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { ARCHIVE_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  archivedEmailId: string;
};

export const removeArchivedEmail = createAsyncThunk<
  { id: string },
  Payload,
  AsyncThunkCreator<void>
>(
  `${ARCHIVE_REDUCER_NAME}/removeArchivedEmail`,
  async (payload, { extra: { db, auth, analytics } }) => {
    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    const ref = db
      .collection(FIRESTORE_COLLECTION.PRODUCTS_ARCHIVE)
      .doc(payload.productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_ARCHIVE_EMAILS)
      .doc(payload.archivedEmailId);

    await ref.delete();

    await analytics.track('removed_archived_email', {
      productId: payload.productId,
      userId: user.uid,
    });

    return {
      id: payload.archivedEmailId,
    };
  }
);
