import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientImports } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchAllProductClientImports = createAsyncThunk<
  { list: ProductClientImports[] },
  { productId: string; importId: string; type: 'all' | 'imported' | 'failed' },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchAllProductClientImports`,
  async ({ productId, importId, type }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_IMPORTS)
        .where('importId', '==', importId)
        .where('status', 'in', type === 'all' ? ['imported', 'failed'] : [type])
        .orderBy('createdAt', 'desc');

      const res = await ref.get();
      const list = res.docs.map((doc) => firestoreDateMapper<ProductClientImports>(doc));

      return {
        list,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
