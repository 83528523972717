import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientImports } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchNextPageProductClientImports = createAsyncThunk<
  { isLastPage: boolean; list: ProductClientImports[]; page: number },
  { productId: string; page: number; ownerId?: string; importId: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchNextPageProductClientImports`,
  async (
    { productId, page, ownerId, importId },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const {
        productClientImportsListSize,
        productClientImportsPage,
        productClientImports,
        isLastProductClientImportsPage,
      } = getState().product;

      if (
        isLastProductClientImportsPage ||
        (productClientImportsPage && page <= productClientImportsPage)
      ) {
        return {
          page,
          list: [],
          isLastPage: true,
        };
      }

      const countPages =
        page - Math.ceil(productClientImports.length / productClientImportsListSize);

      if (countPages <= 0) {
        return {
          page,
          list: [],
          isLastPage: true,
        };
      }

      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_IMPORTS)
        .where('importId', '==', importId)
        .orderBy('createdAt', 'desc')
        .limit(productClientImportsListSize * (page - productClientImportsPage))
        .startAfter(firestoreSubscriptions.lastProductClientImportsCursor);

      const snapshot = await ref.get();

      const isLastPage = snapshot.docs.length < productClientImportsPage;

      firestoreSubscriptions.lastProductClientImportsCursor = isLastPage
        ? null
        : snapshot.docs[snapshot.docs.length - 1];

      const list = snapshot.docs.map((doc) => firestoreDateMapper<ProductClientImports>(doc));
      const currentPage = productClientImportsPage || 1;

      return {
        list,
        isLastPage,
        page: page - currentPage,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
