import { createAsyncThunk } from '@reduxjs/toolkit';
import Stripe from 'stripe';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { NEWSLETTER_REDUCER_NAME } from '../types';

export const resumeNewsletterSubscriptionAtCurrentPeriodEnd = createAsyncThunk<
  Stripe.Response<Stripe.BillingPortal.Session>,
  ActionParams<
    { productId: string; clientEmail: string },
    Stripe.Response<Stripe.BillingPortal.Session>
  >,
  AsyncThunkCreator<number>
>(
  `${NEWSLETTER_REDUCER_NAME}/resumeNewsletterSubscriptionAtCurrentPeriodEnd`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions } }) => {
    try {
      const res = await functions.httpsCallable(
        'newsletters-resumeNewsletterAtEndCurrentSubscription'
      )(payload);
      onSuccess?.(res.data?.body as Stripe.Response<Stripe.BillingPortal.Session>);

      return res.data as Stripe.Response<Stripe.BillingPortal.Session>;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
