import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { ContentModule, FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  module: Partial<ContentModule> & { id: string };
  productId: string;
};

export const updateContentModule = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/updateContentModule`,
  async ({ productId, module }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    if (!data?.modules) {
      throw new Error('invalid-modules');
    }

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    await ref.update({
      updatedAt: new Date(),
      modules: data.modules.map((m) =>
        m.id === module.id ? { ...m, ...module, updatedAt: dayjs().unix() } : m
      ),
    });

    analytics.track('update_content_module', { ownerId: user.uid, productId });
  }
);
