import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION as COLLECTION,
  NFTPrice,
  PriceConfig,
  ProductPrice,
  ProductPriceV2,
  ProductVersionMigrator,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = {
  config: PriceConfig;
  productId: string;
  priceId: string;
  paymentGateways?: ProductPriceV2['paymentGateways'];
};

const defaultPriceConfig: PriceConfig = {
  showAsPromo: false,
  activeUntil: null,
  enableQuantityBuy: false,
};

export const updateProductPriceConfig = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REDUCER_NAME}/updateProductPriceConfig`,
  async (payload, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }

      const { product } = getState();

      if (product.details?.id !== payload.productId) {
        throw new Error('different-product-id');
      }

      if (!product.details.prices || !product.details.prices.length) {
        throw new Error('product-without-prices');
      }

      const updatedPrices = product.details.prices.map((price: ProductPrice | NFTPrice) =>
        price.id === payload.priceId
          ? {
              ...price,
              config: { ...defaultPriceConfig, ...payload.config },
              ...(ProductVersionMigrator.allowQuantityBuy(price) && payload.config.enableQuantityBuy
                ? { quantity: price.quantity ? price.quantity : price.clients }
                : null),
              ...(payload.paymentGateways ? { paymentGateways: payload.paymentGateways } : {}),
            }
          : price
      );

      const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId);

      await ref.update({
        prices: updatedPrices,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
