import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductAutomationDocument, RequestStatus } from '@mailingr/data-models';

import { PRODUCT_AUTOMATION_REDUCER_NAME, ProductAutomationReducer } from './types';

const initialState: ProductAutomationReducer = {
  status: null,
  data: null,
  error: null,
};

const productAutomationSlice = createSlice({
  name: PRODUCT_AUTOMATION_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductAutomationStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = null;
    },
    subscribeToProductAutomationSuccess(
      state,
      { payload }: PayloadAction<ProductAutomationDocument | null>
    ) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToProductAutomationFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
    },
    unsubscribeFromProductAutomation(state) {
      state.status = null;
      state.data = null;
      state.error = null;
    },
  },
});

export const {
  subscribeToProductAutomationFailed,
  subscribeToProductAutomationStarted,
  subscribeToProductAutomationSuccess,
  unsubscribeFromProductAutomation,
} = productAutomationSlice.actions;

export default productAutomationSlice.reducer;
