export enum ArchivedEmailStatus {
  Public = 'public',
  Hidden = 'hidden',
}

export enum ArchivedEmailVisibility {
  Public = 'public',
  Hidden = 'hidden',
  Locked = 'locked',
}

export interface ArchivedEmailDocument {
  emailId: string;
  id: string;
  threadId: string;
  createdAt: Date;
  productId: string;
  productName: string;
  creatorId: string;
  creatorFirstName: string;
  creatorLastName: string;
  creatorAvatarUrl: string[] | null;
  fromEmail: string;
  fromName?: string | null;
  subject: string;
  body: string;
  htmlBody: string;
  legacyHtmlBody?: string | null;
  status: ArchivedEmailStatus;
  weekYear: string;
  monthYear: string;
  thumbnails?: string[] | null;
  landingPageVisibility?: ArchivedEmailVisibility;
  slug: string;
  productSlug: string | null;
}
