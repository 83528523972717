import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductResourceDocument, RequestStatus } from '@mailingr/data-models';

import { PRODUCT_RESOURCES_REDUCER_NAME, ProductResourcesReducer } from './types';

const initialState: ProductResourcesReducer = {
  status: null,
  data: [],
  error: null,
  searchTerm: null,
};

const productResourcesSlice = createSlice({
  name: PRODUCT_RESOURCES_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductResourcesStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = [];
    },
    subscribeToProductResourcesSuccess(
      state,
      { payload }: PayloadAction<ProductResourceDocument[]>
    ) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToProductResourcesFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
    },
    unsubscribeFromProductResources(state) {
      state.status = null;
      state.data = [];
      state.error = null;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
});

export const {
  subscribeToProductResourcesStarted,
  subscribeToProductResourcesSuccess,
  subscribeToProductResourcesFailed,
  unsubscribeFromProductResources,
  clearSearchMode,
  setSearchTerm,
} = productResourcesSlice.actions;

export default productResourcesSlice.reducer;
