import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductDocument, ProductVersionMigrator, RequestStatus } from '@mailingr/data-models';

import { addProduct } from './actions';
import { PRODUCTS_REDUCER_NAME, ProductsReducer } from './types';

const initialState: ProductsReducer = {
  productsInfo: null,
  productsInfoStatus: null,
  productsListStatus: null,
  productsListData: [],
  addingNewProduct: false,
  productsError: null,
  searchTerm: null,
};

const productsSlice = createSlice({
  name: PRODUCTS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductsStarted(state) {
      state.productsListStatus = RequestStatus.SUBSCRIBING;
      state.productsError = null;
    },
    subscribeToProductsSuccess(state, { payload }: PayloadAction<ProductDocument[]>) {
      state.productsListStatus = RequestStatus.SUBSCRIBED;
      state.productsListData = payload.map((product) =>
        ProductVersionMigrator.transformToV2(product)
      );
    },
    subscribeToProductsFailed(state, { payload }: PayloadAction<Error>) {
      state.productsListStatus = RequestStatus.FAILED;
      state.productsError = payload;
    },
    unsubscribeFromProducts(state) {
      state.productsInfoStatus = null;
      state.productsListData = [];
      state.productsError = null;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProduct.pending, (state) => {
      state.addingNewProduct = true;
    });
    builder.addCase(addProduct.fulfilled, (state) => {
      state.addingNewProduct = false;
    });
    builder.addCase(addProduct.rejected, (state) => {
      state.addingNewProduct = false;
    });
  },
});

export const {
  subscribeToProductsStarted,
  subscribeToProductsFailed,
  subscribeToProductsSuccess,
  unsubscribeFromProducts,
  clearSearchMode,
  setSearchTerm,
} = productsSlice.actions;
export default productsSlice.reducer;
