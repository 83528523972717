import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductAccessResponse } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = { productId: string };

export const checkAccessToProduct = createAsyncThunk<
  ProductAccessResponse,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/checkAccessToProduct`,
  async (
    { payload: { productId }, onSuccess, onFailure },
    { rejectWithValue, extra: { functions } }
  ) => {
    try {
      const result = await functions.httpsCallable('products-checkAccess')({ productId });

      onSuccess?.();

      return result.data.body;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
