import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/compat';

import { EmailClient } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload = {
  url: string;
  client: EmailClient;
};

export const removeIntegrationWithEmailClient = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/removeIntegrationWithEmailClient`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-removeIntegrationWithEmailClient')(payload);

      analytics.track('remove_integration_with_email_client', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      const error = e as firebase.functions.HttpsError;
      onFailure?.(error.code);

      return rejectWithValue(e);
    }
  }
);
