import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchNextPageProductArchivedClients = createAsyncThunk<
  { isLastPage: boolean; list: ProductClientDocument[]; page: number },
  { productId: string; page: number; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchNextPageProductArchivedClients`,
  async (
    { productId, page, ownerId },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const {
        productArchivedClientsListSize,
        isLastPageForArchived: isFetchedAllItems,
        productArchivedPage,
        productArchivedClients,
      } = getState().product;

      if (isFetchedAllItems || (productArchivedPage && page <= productArchivedPage)) {
        return {
          page,
          list: [],
          isLastPage: isFetchedAllItems,
        };
      }

      const countPages =
        page - Math.ceil(productArchivedClients.length / productArchivedClientsListSize);

      if (countPages <= 0) {
        return {
          page,
          list: [],
          isLastPage: isFetchedAllItems,
        };
      }

      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('status', '==', 'archived')
        .orderBy('archivedInfo.archivedAt', 'desc')
        .limit(productArchivedClientsListSize * (page - productArchivedPage))
        .startAfter(firestoreSubscriptions.lastArchivedProductClientCursor);

      const snapshot = await ref.get();

      const isLastPage = snapshot.docs.length < productArchivedClientsListSize;

      firestoreSubscriptions.lastArchivedProductClientCursor = isLastPage
        ? null
        : snapshot.docs[snapshot.docs.length - 1];

      const list = snapshot.docs.map((doc) => firestoreDateMapper<ProductClientDocument>(doc));
      const currentPage = productArchivedPage || 1;

      return {
        list,
        isLastPage,
        page: page - currentPage,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
