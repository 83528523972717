import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { AutomationTask, FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  task: AutomationTask;
  action: 'add' | 'update' | 'remove';
  ownerId?: string;
};

export const updateProductAutomationTask = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationTask`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error('product automation data not exists');
      }

      let tasksToUpdate = cloneDeep(data.tasks) ?? [];

      if (payload.action === 'remove') {
        tasksToUpdate = tasksToUpdate.filter((task) => task.id !== payload.task.id);
      }

      if (payload.action === 'update') {
        tasksToUpdate = data.tasks?.map((savedTask) =>
          savedTask.id === payload.task.id ? payload.task : savedTask
        ) ?? [payload.task];
      }

      if (payload.action === 'add') {
        tasksToUpdate = [...(data?.tasks || []), payload.task];
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(payload.ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);

      await ref.update({
        tasks: tasksToUpdate,
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
