import { createSelector, Selector } from '@reduxjs/toolkit';

import { ProductDocumentV2 } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getProducts = (store: AppStore) => store.products.productsListData;
const getSearchTerm = (store: AppStore) => store.products.searchTerm;

export const getFilteredProducts: Selector<AppStore, ProductDocumentV2[]> = createSelector(
  [getProducts, getSearchTerm],
  (products, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return products;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return products.filter((product) => {
      return product.name.toLowerCase().includes(searchInLowerCase);
    });
  }
);

export const getFilteredPaidProducts: Selector<AppStore, ProductDocumentV2[]> = createSelector(
  [getProducts],
  (products) => {
    return products.filter((product) => {
      return !product.isFree;
    });
  }
);
