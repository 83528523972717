import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProductClientDocument,
  ProductReferralsStatus,
  RequestStatus,
} from '@mailingr/data-models';

import { getProgramReferralInfo } from './actions/getProgramReferralInfo';
import { NEWSLETTER_REDUCER_NAME, NewsletterReducer } from './types';

const initialState: NewsletterReducer = {
  data: null,
  status: null,
  error: null,
  referralProgramStatus: null,
  referralProgram: {
    status: ProductReferralsStatus.Inactive,
  },
};

const newsletterSlice = createSlice({
  name: NEWSLETTER_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToNewsletterStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToNewsletterSuccess(state, { payload }: PayloadAction<ProductClientDocument>) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToNewsletterFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromNewsletter(state) {
      state.data = null;
      state.status = null;
      state.error = null;
      state.referralProgramStatus = null;
      state.referralProgram = {
        status: ProductReferralsStatus.Inactive,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProgramReferralInfo.pending, (state) => {
      state.referralProgramStatus = RequestStatus.FETCHING;
    });
    builder.addCase(getProgramReferralInfo.fulfilled, (state, { payload }) => {
      state.referralProgramStatus = RequestStatus.SUCCESS;
      state.referralProgram = payload;
    });
    builder.addCase(getProgramReferralInfo.rejected, (state) => {
      state.referralProgramStatus = RequestStatus.FAILED;
    });
  },
});

export const {
  subscribeToNewsletterFailed,
  subscribeToNewsletterStarted,
  subscribeToNewsletterSuccess,
  unsubscribeFromNewsletter,
} = newsletterSlice.actions;
export default newsletterSlice.reducer;
