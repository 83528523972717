import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductClientReferralsInfo } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { NEWSLETTER_REDUCER_NAME } from '../types';

export const getProgramReferralInfo = createAsyncThunk<
  ProductClientReferralsInfo,
  { ownerId: string; productId: string },
  AsyncThunkCreator<number>
>(
  `${NEWSLETTER_REDUCER_NAME}/getProgramReferralInfo`,
  async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
      const res = await functions.httpsCallable('referrals-getReferralsInfoForClient')(payload);

      return res.data.body as ProductClientReferralsInfo;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
