import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

import {
  FIRESTORE_COLLECTION,
  FreeProductMonthStatsDocument,
  PaidProductMonthStatsDocument,
} from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { STATISTICS_REDUCER_NAME } from '../types';

export const fetchProductMonthStats = createAsyncThunk<
  PaidProductMonthStatsDocument | FreeProductMonthStatsDocument | null,
  { productId: string; date?: Dayjs; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${STATISTICS_REDUCER_NAME}/fetchProductMonthStats`,
  async ({ productId, date, ownerId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const monthStr = (date || dayjs()).format('YYYY-M');
      const ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.STATS_PER_PRODUCT)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.STATS_PER_PRODUCT_MONTH)
        .doc(monthStr);

      const doc = await ref.get();

      return doc.exists
        ? firestoreDateMapper<FreeProductMonthStatsDocument | PaidProductMonthStatsDocument>(doc)
        : null;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
