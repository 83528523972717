import { createAsyncThunk } from '@reduxjs/toolkit';

import { SendTestEmailTemplatePayload } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';

type Payload = SendTestEmailTemplatePayload & { productId: string };

export const sendTestEmail = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REFERRALS_REDUCER_NAME}/sendTestEmail`,
  async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('referrals-sendTestEmailTemplate')(payload);
      analytics.track('send_test_email', {
        productId: payload.productId,
        userId: user?.uid ?? '',
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
