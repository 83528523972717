import { ProductResourceDocument, RequestStatus } from '@mailingr/data-models';

export const PRODUCT_RESOURCES_REDUCER_NAME = 'ProductResources';

export interface ProductResourcesReducer {
  data: ProductResourceDocument[];
  status: null | RequestStatus;
  error: null | string;
  searchTerm: string | null;
}
