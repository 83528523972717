import { createAsyncThunk } from '@reduxjs/toolkit';

import { SubscriptionInterval, SubscriptionTier } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';

type Payload = {
  interval: SubscriptionInterval;
  tier: SubscriptionTier;
};

export const changeSubscriptionPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${SUBSCRIPTION_REDUCER_NAME}/changeSubscriptionPlan`,
  async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
      await functions.httpsCallable('subscriptions-changeSubscriptionPlan')(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
