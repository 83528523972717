import React, { FunctionComponent, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Select, Skeleton } from 'antd';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import * as styles from './Checkout.module.scss';

import 'firebase/compat/analytics';

import { CreditCardOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { AppStore } from '@mailingr/app-store';
import { RequestStatus } from '@mailingr/data-models';

import RenewalStepper from '~/components/RenewalStepper/Stepper';
import { useQuery } from '~/hooks/useQuery';
import CheckoutWrapper from '~/pages/Checkout/components/CheckoutWrapper/CheckoutWrapper';
import ProductDetailsCard from '~/pages/Checkout/components/ProductDetailsCard';
import { useFetchNip } from './hooks/useFetchNip';

interface OwnProps {}

type Props = OwnProps;

const tailLayout = {
  wrapperCol: { offset: 0, span: 24, md: { offset: 4, span: 16 } },
};

type FormModel = {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  nip: string;
  street: string;
  postalCode: string;
  city: string;
  acceptsTermsOfService: boolean;
  invoiceRequested?: boolean;
};

const CouponValidationError = {
  WRONG_COUPON_CODE: 'wrong_coupon_code',
  CANNOT_FOUND_CLIENT_DATA: 'cannot_found_client_data',
  INACTIVE_COUPON: 'inactive_coupon',
};

const ProductValidationError = {
  NO_ACTIVE_PRODUCT: 'no_active_product',
  ALREADY_BOUGHT: 'already_bought',
  ACCOUNT_EXISTS: 'account_exists',
};

const CHECKOUT_URL = import.meta.env.VITE_CHECKOUT_URL as string;
const STRIPE_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY as string;

const CheckoutRenewal: FunctionComponent<Props> = () => {
  const { t, i18n } = useTranslation(['common', 'checkout']);
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectToStripeCheckout, toggleFlag] = useState(false);
  const queryParams = useQuery();
  const isFree = queryParams.get('priceId') === 'free';
  const email = queryParams.get('email');
  const productId = queryParams.get('productId');
  const defaultPrice = queryParams.get('priceId');
  const [priceId, setPriceId] = useState<string | null>(defaultPrice ?? null);
  const [formController] = Form.useForm<FormModel>();
  const [showInvoiceData, toggleInvoiceData] = useState(false);
  const { checkoutProductInfo, checkoutProductInfoStatus } = useSelector(
    (store: AppStore) => store.product
  );
  const loggedUser = useSelector((store: AppStore) => store.user.details);
  const { setNip, nip, nipHelpKeyText, nipValidStatus } = useFetchNip(formController);

  useEffect(() => {
    if (isFree) {
      history.push(`/free/${accountId}?${queryParams}`);
    }
  }, [history, accountId, queryParams, isFree]);

  useEffect(() => {
    if (
      checkoutProductInfo &&
      !checkoutProductInfo.isFree &&
      'invoiceAsOption' in checkoutProductInfo
    ) {
      toggleInvoiceData(!checkoutProductInfo?.invoiceAsOption);
    }
  }, [checkoutProductInfo]);

  useEffect(() => {
    if (checkoutProductInfo && 'client' in checkoutProductInfo && checkoutProductInfo.client) {
      formController.setFields([
        { value: email?.trim().replace(' ', '+') ?? '', name: 'email' },
        { value: checkoutProductInfo.client.firstName ?? '', name: 'firstName' },
        { value: checkoutProductInfo.client.lastName ?? '', name: 'lastName' },
        { value: checkoutProductInfo.client.companyName ?? '', name: 'companyName' },
        { value: checkoutProductInfo.client.nip ?? '', name: 'nip' },
        { value: checkoutProductInfo.client.street ?? '', name: 'street' },
        { value: checkoutProductInfo.client.postalCode ?? '', name: 'postalCode' },
        { value: checkoutProductInfo.client.city ?? '', name: 'city' },
      ]);
    } else if (loggedUser) {
      formController.setFields([
        { value: loggedUser.email ?? '', name: 'email' },
        { value: loggedUser.firstName ?? '', name: 'firstName' },
        { value: loggedUser.lastName ?? '', name: 'lastName' },
      ]);
    }
  }, [email, formController, loggedUser, checkoutProductInfo]);

  const onFinish = async (values: FormModel) => {
    const stripe = await loadStripe(STRIPE_KEY, { stripeAccount: accountId });
    if (!stripe) {
      return null;
    }
    setErrorMessage(null);
    toggleFlag(true);
    const email = values.email.toLowerCase().trim();
    fetch(CHECKOUT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        email,
        accountId,
        productId,
        priceId,
        renewal: true,
        lang: i18n.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    })
      .then((res) => {
        if (res.status === 409) {
          const error = new Error();
          error.message = ProductValidationError.ALREADY_BOUGHT;
          throw error;
        }

        return res.json();
      })
      .then(async (json) => {
        if (json.error || json.message) {
          throw new Error(json.error);
        }
        if (json && json.session) {
          await firebase.analytics().logEvent('redirect_to_checkout', {
            email,
            accountId,
            productName: checkoutProductInfo?.name ?? '',
            productId,
            priceId,
            renewal: true,
          });
          stripe.redirectToCheckout({
            sessionId: json.session.id,
          });
        } else if (json.url) {
          toggleFlag(false);
          window.localStorage.setItem('emailForSignIn', email);
          await firebase.analytics().logEvent('free_product_purchased', {
            email,
            accountId,
            productName: checkoutProductInfo?.name ?? '',
            productId,
            priceId,
          });
          window.location.replace(json.url);
        }
      })
      .catch((e) => {
        toggleFlag(false);
        if (Object.values(CouponValidationError).find((error) => error === e.message)) {
          setErrorMessage(e.message);

          return;
        }
        if (Object.values(ProductValidationError).find((error) => error === e.message)) {
          setErrorMessage(e.message);

          return;
        }
        history.push(
          `/checkout/failure?accountId=${accountId}&priceId=${queryParams.get(
            'priceId'
          )}&productId=${queryParams.get('productId')}&name=${checkoutProductInfo?.name ?? ''}`
        );
      });
  };

  const showExtendCheckbox =
    checkoutProductInfo && 'invoiceAsOption' in checkoutProductInfo
      ? checkoutProductInfo.invoiceAsOption
      : false;

  return (
    <CheckoutWrapper
      productCard={
        <ProductDetailsCard
          accountId={accountId}
          productId={productId}
          renewal
          email={email ?? undefined}
          priceId={priceId}
          setPrice={(id) => setPriceId(id)}
        />
      }
    >
      <div className="absolute bottom-0 right-4 lg:bottom-8 lg:right-8">
        <Select
          value={i18n.language}
          size="large"
          onChange={(lang) => i18n.changeLanguage(lang)}
          dropdownMatchSelectWidth={false}
        >
          <Select.Option value="en" key="en">
            <span className="flag-icon flag-icon-gb" />
            🇺🇸 EN
          </Select.Option>
          <Select.Option value="pl" key="pl">
            <span className="flag-icon flag-icon-pl" />
            🇵🇱 PL
          </Select.Option>
        </Select>
      </div>
      <RenewalStepper current={0} />
      <Form<FormModel>
        name="login-form"
        layout="vertical"
        labelCol={{ xxl: { span: 8, offset: 4 }, span: 24, offset: 0 }}
        wrapperCol={{ xxl: { span: 16, offset: 4 }, span: 24, offset: 0 }}
        onFinish={onFinish}
        validateTrigger="onSubmit"
        className={styles.form}
        form={formController}
        initialValues={{
          email: email || '',
        }}
      >
        <Row>
          <Col xs={24}>
            <Form.Item
              name="email"
              label={t<string>('checkout:email')}
              rules={[
                { required: true, message: t<string>('validationErrors.fieldIsRequired') },
                { type: 'email', message: t<string>('validationErrors.wrongEmail') },
              ]}
            >
              <Input placeholder={t<string>('checkout:emailPlaceholder')} />
            </Form.Item>

            <Form.Item
              name="firstName"
              label={t<string>('checkout:firstName')}
              rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
            >
              <Input placeholder={t<string>('checkout:firstNamePlaceholder')} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label={t<string>('checkout:lastName')}
              rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
            >
              <Input placeholder={t<string>('checkout:lastNamePlaceholder')} />
            </Form.Item>

            {showExtendCheckbox && (
              <Form.Item name="invoiceRequested" valuePropName="checked">
                <Checkbox onChange={(e) => toggleInvoiceData(e.target.checked)}>
                  {t<string>('checkout:wantInvoice')}
                </Checkbox>
              </Form.Item>
            )}
          </Col>

          {showInvoiceData && (
            <Col xs={24}>
              <Form.Item name="companyName" label={t<string>('checkout:companyName')}>
                <Input />
              </Form.Item>

              <Form.Item
                name="nip"
                label={t<string>('checkout:nip.name')}
                help={t(nipHelpKeyText)}
                validateStatus={nipValidStatus}
                hasFeedback
              >
                <Input
                  placeholder={t<string>('checkout:nipPlaceholder')}
                  value={nip}
                  onChange={(e) => setNip(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="street"
                label={t<string>('checkout:street')}
                rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
              >
                <Input placeholder={t<string>('checkout:streetPlaceholder')} />
              </Form.Item>

              <Form.Item
                name="postalCode"
                label={t<string>('checkout:postalCode')}
                rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="city"
                label={t<string>('checkout:city')}
                rules={[{ required: true, message: t<string>('validationErrors.fieldIsRequired') }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Skeleton
          loading={checkoutProductInfoStatus === RequestStatus.FETCHING}
          paragraph={{ rows: 3 }}
        >
          <Form.Item
            name="acceptsTermsOfService"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(t<string>('validationErrors.fieldIsRequired')),
              },
            ]}
          >
            <Checkbox>
              {t<string>('checkout:acceptTerms')}
              <a
                href={checkoutProductInfo?.cart?.pl.termsUrl || t<string>('common:links.terms')}
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>('checkout:termsOfService')}
              </a>
              {t<string>('checkout:acceptOr')}
              <a
                href={
                  checkoutProductInfo?.cart?.pl.privacyPolicyUrl ||
                  t<string>('common:links.privacyPolicy')
                }
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>('checkout:privacyPolicy')}
              </a>
              {t<string>('checkout:acceptEnd')}
              <span className={styles.required}>*</span>
            </Checkbox>
          </Form.Item>

          {checkoutProductInfo?.cart?.pl.customConsents?.map((consent) => (
            <Form.Item
              name={consent.name}
              key={consent.name}
              valuePropName="checked"
              rules={[
                consent.required
                  ? {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t<string>('validationErrors.fieldIsRequired')),
                    }
                  : {},
              ]}
            >
              <Checkbox>
                {consent.label}
                {consent.required && <span className={styles.required}>*</span>}
                {consent.url && (
                  <a
                    rel="noreferrer noopener"
                    href={consent.url}
                    target="_blank"
                    className={styles.seeMoreDetails}
                  >
                    {t<string>('checkout:seeMoreDetails')}
                  </a>
                )}
              </Checkbox>
            </Form.Item>
          ))}

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={16} offset={4}>
              {errorMessage && (
                <Alert showIcon type="error" message={t<string>(`checkoutError.${errorMessage}`)} />
              )}
            </Col>
          </Row>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={checkoutProductInfo?.active === false || !priceId}
              size="large"
              block
              icon={<CreditCardOutlined />}
              className={styles.submitButton}
              loading={redirectToStripeCheckout}
            >
              {t<string>('checkout:button.goToPayment')}
            </Button>
          </Form.Item>

          <p className={styles.privacyPolicy}>
            {checkoutProductInfo?.cart?.pl.claim || t<string>('checkout:privacyPolicyInformation')}
          </p>
        </Skeleton>
      </Form>
    </CheckoutWrapper>
  );
};

export default CheckoutRenewal;
