import { CommunityMember, RequestStatus } from '@mailingr/data-models';

export interface CommunityMembersReducer {
  requestStatus: null | RequestStatus;
  data: CommunityMember[];
  updating: boolean;
  error: null | Error;
  searchTerm: string | null;
}

export const COMMUNITY_MEMBERS_REDUCER_NAME = 'CommunityMembers';
