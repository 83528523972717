import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  FreeAccessDocument,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = Omit<FreeAccessDocument, 'id'>;

export const addFreeAccessInvitation = createAsyncThunk<
  string,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/addFreeAccessInvitation`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS)
        .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_FREE_ACCESS);

      const res = await ref.add({
        ...payload,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      onSuccess?.();

      return res.id;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
