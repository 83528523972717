import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductFilesDocument,
  ProductFilesPayload,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_FILES_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  model: ProductFilesPayload;
};

export const addProductFiles = createAsyncThunk<
  void,
  ActionParams<Payload, string>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_FILES_REDUCER_NAME}/addProductFiles`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { db, auth, analytics } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const model: ProductFilesDocument = {
        ...payload.model,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_FILES)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_FILES_CONTENT);

      await ref.set(model);

      await analytics.track('add_files_to_product', {
        productId: payload.productId,
        userId: user.uid,
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
