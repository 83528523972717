import { createSelector, Selector } from '@reduxjs/toolkit';

import { Community } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getData = (store: AppStore) => store.communities.data;
const getSearchTerm = (store: AppStore) => store.communities.searchTerm;

export const getFilteredCommunities: Selector<AppStore, Community[]> = createSelector(
  [getData, getSearchTerm],
  (products, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
      return products;
    }

    const searchInLowerCase = searchTerm.toLowerCase();

    return products.filter((product) => {
      return product.name.toLowerCase().includes(searchInLowerCase);
    });
  }
);
