import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/compat';

import { ConversionTrackingClient, IntegrationClient } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload = {
  id: string;
  client: IntegrationClient | ConversionTrackingClient;
};

export const removeIntegrationWithExternalApi = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<firebase.functions.HttpsError>
>(
  `${INTEGRATION_REDUCER_NAME}/removeIntegrationWithExternalApi`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-removeIntegrationWithExternalApi')(payload);

      analytics.track('remove_integration_with_external_api', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      const error = e as firebase.functions.HttpsError;
      onFailure?.(error.code);

      return rejectWithValue(error);
    }
  }
);
