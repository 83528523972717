import { createAsyncThunk } from '@reduxjs/toolkit';

import { Chapter, MetaTag, Moment } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  lessonId: string;
  productId: string;
  videoId: string;
  chapters: Chapter[];
  moments: Moment[];
  metaTags: MetaTag[];
};

export const updateLessonVideo = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/updateLessonVideo`,
  async (dto, { extra: { analytics, auth, functions } }) => {
    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }
    await functions.httpsCallable('videos-updateVideoForLesson')(dto);
    analytics.track('update_video_in_lesson', {
      ownerId: user.uid,
      productId: dto.productId,
    });
  }
);
