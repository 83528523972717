import { createSelector, Selector } from '@reduxjs/toolkit';
import sortBy from 'lodash.sortby';

import { BaseReward } from '@mailingr/data-models';

import { AppStore } from '../../../index';

const getRewards = (store: AppStore) => store.productReferrals.data?.rewards ?? [];

export const getSortedRewards: Selector<AppStore, BaseReward[]> = createSelector(
  [getRewards],
  (rewards) => {
    if (!rewards || !rewards.length) {
      return [];
    }

    return sortBy(rewards, 'numberOfReferrals');
  }
);
