import { createSelector, Selector } from '@reduxjs/toolkit';

import { AppStore } from '../../../index';

const getProductContent = (store: AppStore) => store.productContent.data;

export const getCourseTags: Selector<AppStore, string[]> = createSelector(
  [getProductContent],
  (content) => {
    if (!content) {
      return [];
    }

    const lessons = content.modules.map((module) => module.lessons).flat();
    const tags = lessons.map((lesson) => lesson.tags ?? []).flat();

    return [...new Set(tags)];
  }
);
