import Stripe from 'stripe';

import { Brand } from '../domains/BrandType';
import { CyclesConfiguration, EmailList } from '../dtos/ProductDTO';
import { PaymentClient } from '../enums';
import { InvoicesClient } from '../enums/InvoicesClient';
import { ProductChargeType, ProductType } from '../enums/productEnums';
import { CheckoutSettings } from './IntegrationDocument';
import { ClientInvoiceData } from './OrderDocument';
import { ArchiveStrategyAccess } from './ProductAutomationDocument';
import { SubscriptionInterval } from './ProductClientDocument';

export interface ProductResourceCategory {
  name: string;
  icon?: string;
  default?: boolean;
}

export type Currency = 'pln' | 'usd' | 'eur' | 'gbp';

export interface Author {
  firstName?: string;
  lastName?: string;
  email?: string;
  avatar?: string[];
}

export interface BaseProduct {
  id: string;
  normalizedId: string;
  slug?: string;
  name: string;
  author: Author | null;
  isFree: boolean;
  description: string;
  checkoutDescription: string;
  images: string[];
  metadata?: Record<string, string>;
  clientTags: string[];
  archived: boolean;
  active: boolean;
  created: number;
  updated: number;
  updatedAt?: Date;
  type?: 'service' | 'good';
  productChargeType: ProductChargeType;
  productType: ProductType;
  resourceCategories?: ProductResourceCategory[];
  language?: string | null;
  showOnMainProductsPage?: boolean;
}

export enum PriceStatus {
  Available = 'available',
  Archived = 'archived',
}

export enum EndAccessStrategy {
  Cancel = 'cancel',
  OfferWithTheSamePrice = 'offerWithTheSamePrice',
  OfferWithTheCurrentPrices = 'offerWithTheCurrentPrices',
}

export enum AccessStrategyAfterSell {
  CancelImmediately = 'cancelImmediately',
  KeepAccessTillCurrentAccessPeriod = 'keepTillCurrentAccessPeriod',
}

export type RecurringAccessPeriod = {
  type: 'recurring';
  interval_count: number;
  interval: SubscriptionInterval;
  trialPeriodDays?: number | null;
};

export type OneTimeAccessPeriod = {
  type: 'one_time';
  interval_count: number;
  interval: SubscriptionInterval;
  endAccessStrategy?: EndAccessStrategy;
};

export type NFTAccessPeriod = {
  type: 'nft';
  interval_count: number;
  interval: SubscriptionInterval;
};

export type PriceAccessPeriod = RecurringAccessPeriod | OneTimeAccessPeriod;

export type BillingScheme = 'per_unit' | 'tiered';

export type ProductPriceId = Brand<string, 'ProductPriceId'>;
export const parseProductPriceId = (id: string): ProductPriceId => id as ProductPriceId;

export interface PriceConfig {
  additionalInfo?: string | null;
  timeLimit?: number | null;
  quantityLimit?: number | null;
  showAvailable?: boolean;
  showAsPromo?:
    | false
    | {
        priceBeforeDiscount: number;
        hidePromoBadge?: boolean | null;
      };
  activeUntil?: null | number;
  alertInfo?: string;
  enableQuantityBuy?: boolean | null;
}

export type PendingOrder = {
  id: string;
  quantity: number;
};

export interface BaseGatewayPrice {
  priceId: string;
  disabled?: boolean;
}

export interface StripeGatewayPrice extends BaseGatewayPrice {
  billingScheme: BillingScheme;
  livemode: boolean;
  unit_amount_decimal: string | null;
  unit_amount: number | null;
  recurring: Stripe.Price.Recurring | null;
}

export interface PayUGatewayPrice extends BaseGatewayPrice {}

export interface TPayGatewayPrice extends BaseGatewayPrice {}

export interface PaymentGatewayConfig {
  [PaymentClient.Stripe]?: StripeGatewayPrice;
  [PaymentClient.PayU]?: PayUGatewayPrice;
  [PaymentClient.Tpay]?: TPayGatewayPrice;
  [PaymentClient.Metamask]?: never;
}

export interface StripeProductPrice extends Stripe.Price {
  active: boolean;
  currency: Currency;
  livemode: boolean;
  billing_scheme: BillingScheme;
  created: number;
  metadata: Record<string, string>;
  id: string;
  type: 'recurring' | 'one_time';
  unit_amount: number | null;
  unit_amount_decimal: string | null;
  clients: number;
  status: PriceStatus;
  paymentClient?: PaymentClient.Stripe;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  customInvoiceName?: string;
  config?: PriceConfig;
  pendingOrders?: PendingOrder[];
  checkoutId?: string;
  hidden?: boolean;
  archivedClients?: number;
  connectedUpsells?: string[] | null;
  quantity?: number;
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: null | CyclesConfiguration;
  version?: 1;
}

export interface PayUProductPrice {
  active: boolean;
  id: string;
  created: number;
  status: PriceStatus;
  paymentClient: PaymentClient.PayU;
  currency: Currency;
  unit_amount: number;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  customInvoiceName?: string;
  pendingOrders?: PendingOrder[];
  checkoutId?: string;
  hidden?: boolean;
  archivedClients?: number;
  config?: PriceConfig;
  type: 'one_time';
  clients: number;
  connectedUpsells?: string[] | null;
  quantity?: number;
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: null;
  version?: 1;
}

export interface BaseProductPriceV2 {
  // TODO: after migration, remove string id
  id: string | ProductPriceId;
  active: boolean;
  created: number;
  status: PriceStatus;
  currency: Currency;
  unitAmount: number;
  type: 'recurring' | 'one_time';
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  customInvoiceName?: string;
  config?: PriceConfig;
  pendingOrders?: PendingOrder[];
  checkoutId?: string;
  hidden?: boolean;
  archivedClients?: number;
  clients: number;
  connectedUpsells?: string[] | null;
  quantity?: number;
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: CyclesConfiguration | null;
  metadata?: Record<string, string>;
  paymentGateways: PaymentGatewayConfig;
  version: 2;
}

export interface OneTimeProductPriceV2 extends BaseProductPriceV2 {
  type: 'one_time';
  accessPeriod: OneTimeAccessPeriod | null;
}

export interface RecurringProductPriceV2 extends BaseProductPriceV2 {
  type: 'recurring';
  accessPeriod: RecurringAccessPeriod;
}

export type ProductPriceV2 = OneTimeProductPriceV2 | RecurringProductPriceV2;

export type DeprecatedProductPrice = StripeProductPrice | PayUProductPrice;
export type ProductPrice = StripeProductPrice | PayUProductPrice | ProductPriceV2;

export interface NFTCollectionMetadata {
  name: string;
  symbol: string;
  contractType: string;
  imageUrl: string | null;
  externalUrl: string | null;
  discordUrl: string | null;
}

export enum Chain {
  Ethereum = 'eth',
  Polygon = 'polygon',
  Goerli = 'goerli',
}

export type NFTPrice = {
  active: boolean;
  created: number;
  id: string;
  type: 'nft';
  clients: number;
  status: PriceStatus;
  paymentClient: PaymentClient.Metamask;
  accessPeriod: NFTAccessPeriod | null;
  customLabel?: string;
  nftTokenAddress: string;
  totalSupply: number | null;
  nftCollectionMetadata: NFTCollectionMetadata;
  config?: PriceConfig;
  accessStrategyAfterSell?: AccessStrategyAfterSell;
  requiredNumberOfTokens: number;
  chain: Chain;
  pendingOrders?: PendingOrder[];
  checkoutId?: string;
  hidden?: boolean;
  archivedClients?: number;
  connectedUpsells?: string[] | null;
  quantity?: never;
  promoteNetPrice?: boolean;
  vatRate?: number;
  installments?: number | null;
  paymentCycles?: never;
  paymentGateways?: null;
};

export type CustomInvoicesClient = {
  accountUrl: string;
  accountName: string;
  client: InvoicesClient;
};

export type ArchiveInbox = {
  email: string;
  firstName: string;
  lastName: string;
};

export interface ServiceProduct extends BaseProduct {
  prices: (ProductPrice | NFTPrice)[];
  isFree: false;
  productChargeType: ProductChargeType.Paid;
  totalClients: number;
  connectedEmailLists: EmailList[];
  activeClients: number;
  churnedClients: number;
  totalFreeAccounts: number;
  clientTags: string[];
  customInvoicesClient?: CustomInvoicesClient | null;
  archiveInbox?: ArchiveInbox;
  allowImportClients?: boolean;
  paymentsMethods?: {
    blik?: boolean;
    p24?: boolean;
    applePay?: boolean;
    googlePay?: boolean;
  };
  doubleOptIn?: 'none';
  version?: 1;
}

export interface ServiceProductV2 extends BaseProduct {
  prices: (ProductPriceV2 | NFTPrice)[];
  isFree: false;
  productChargeType: ProductChargeType.Paid;
  totalClients: number;
  connectedEmailLists: EmailList[];
  activeClients: number;
  churnedClients: number;
  totalFreeAccounts: number;
  clientTags: string[];
  customInvoicesClient?: CustomInvoicesClient | null;
  archiveInbox?: ArchiveInbox;
  allowImportClients?: boolean;
  doubleOptIn?: 'none';
  version: 2;
  enabledPaymentGateways: PaymentClient[];
  paymentsMethods?: {
    blik?: false | PaymentClient.Stripe | PaymentClient.PayU | PaymentClient.Tpay;
    p24?: false | PaymentClient.Stripe;
    applePay?: false | PaymentClient.Stripe;
    googlePay?: false | PaymentClient.Stripe;
  };
}

export interface FreeServiceProduct extends BaseProduct {
  prices?: never;
  isFree: true;
  productChargeType: ProductChargeType.Free;
  totalClients: number;
  connectedEmailLists: EmailList[];
  paymentClient: null;
  clientTags: string[];
  customInvoicesClient?: CustomInvoicesClient | null;
  archiveInbox?: ArchiveInbox;
  paymentsMethods?: never;
  doubleOptIn?: 'none' | 'external';
  enabledPaymentGateways?: never;
  version?: never;
}

export type DeprecatedProductDocument = ServiceProduct | FreeServiceProduct;

export type ProductDocument = ServiceProduct | FreeServiceProduct | ServiceProductV2;

export type ProductDocumentV2 = ServiceProductV2 | FreeServiceProduct;

export interface StripePriceInfo {
  amount: number;
  currency: Currency;
  active: true;
  id: string;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  config?: PriceConfig | null;
  available?: number | true;
  type: 'recurring' | 'one_time';
  priceId: string;
  allowedPaymentMethods?: ('card' | 'blik' | 'p24')[];
  paymentClient: PaymentClient.Stripe;
  connectedUpsells?: string[] | null;
  quantity?: number;
  clients: number;
  pendingOrders?: PendingOrder[];
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: CyclesConfiguration | null;
}

export interface PriceInfoV2 {
  amount: number;
  currency: Currency;
  active: true;
  id: string;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string | null;
  config?: PriceConfig | null;
  available?: number | true;
  type: 'recurring' | 'one_time';
  priceId: string;
  allowedPaymentMethods: ('card' | 'blik' | 'p24' | 'payu' | 'tpay' | 'applePay' | 'googlePay')[];
  connectedUpsells?: string[] | null;
  quantity?: number;
  clients: number;
  pendingOrders?: PendingOrder[];
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: CyclesConfiguration | null;
  activePaymentGateways: PaymentClient[];
}

export interface PayUPriceInfo {
  amount: number;
  currency: Currency;
  active: true;
  id: string;
  accessPeriod: PriceAccessPeriod | null;
  customLabel?: string;
  config?: PriceConfig | null;
  available?: number | true;
  type: 'one_time';
  priceId: string;
  allowedPaymentMethods?: 'payu'[];
  paymentClient: PaymentClient.PayU;
  connectedUpsells?: string[] | null;
  quantity?: number;
  clients: number;
  pendingOrders?: PendingOrder[];
  promoteNetPrice?: boolean;
  vatRate?: number;
  paymentCycles?: null;
}

export interface NFTPriceInfo {
  type: 'nft';
  id: string;
  accessPeriod: NFTAccessPeriod | null;
  active: true;
  tokenAddress: string;
  requiredNumberOfTokens: number;
  name: string;
  symbol: string;
  customLabel?: string;
  config?: PriceConfig | null;
  allowedPaymentMethods: 'metamask'[];
  available?: number | true;
  paymentClient: PaymentClient.Metamask;
  connectedUpsells?: string[] | null;
  quantity?: never;
  clients: number;
  pendingOrders?: PendingOrder[];
  promoteNetPrice?: boolean;
  installments?: number | null;
}

export interface PaidProductInfo {
  active: boolean;
  isFree: false;
  images: string[];
  name: string;
  description: string;
  checkoutDescription: string;
  owner: string;
  ownerId: string;
  author: Author | null;
  price: {
    amount: number;
    currency: Currency;
    active: boolean;
    priceId: string;
  } | null;
  prices: (PriceInfoV2 | NFTPriceInfo)[];
  upsellPrices?: (PriceInfoV2 | NFTPriceInfo)[];
  cart?: CheckoutSettings['cart'];
  invoiceAsOption: boolean;
  stripeAccountId: string;
  allowUpgradeSubscriptionPlan?: boolean;
  allowDowngradeSubscriptionPlan?: boolean;
  hideCountryField?: boolean;
  showPhoneField?: false | 'optional' | 'required';
  showAdditionalInfo?: false | 'optional' | 'required';
  allowPromotionCode?: false | 'onlyForSubscription' | 'forAll';
  archiveAccessStrategy: ArchiveStrategyAccess;
  salesPageSlug?: string;
  forceB2B?: boolean;
  isMetaConversionAvailable: boolean;
}

export interface FreePrice {
  id: string;
  amount: 0;
  type: 'free';
  currency: Currency;
  allowedPaymentMethods: string[];
  available: 1;
  accessPeriod: PriceAccessPeriod;
  config: PriceConfig;
  active: boolean;
  promoteNetPrice?: boolean;
  vatRate: number;
}

export interface FreeProductInfo {
  active: boolean;
  isFree: true;
  images: string[];
  name: string;
  description: string;
  checkoutDescription: string;
  owner: string;
  ownerId: string;
  author: Author | null;
  price: FreePrice | null;
  cart?: CheckoutSettings['cart'];
  customDomain?: string;
  archiveAccessStrategy: ArchiveStrategyAccess;
  isMetaConversionAvailable: false;
  salesPageSlug?: string;
}

export interface RenewalProductInfo {
  strategy: EndAccessStrategy;
  active: boolean;
  isFree: false;
  images: string[];
  name: string;
  description: string;
  checkoutDescription: string;
  owner: string;
  ownerId: string;
  author: Author | null;
  cart?: CheckoutSettings['cart'];
  currentPeriodEnd: number;
  client: ClientInvoiceData;
  price: PriceInfoV2 | null;
  prices: PriceInfoV2[];
  upsellPrices?: PriceInfoV2[];
  invoiceAsOption: boolean;
  stripeAccountId: string;
  hideCountryField?: boolean;
  showPhoneField?: false | 'optional' | 'required';
  showAdditionalInfo?: false | 'optional' | 'required';
  allowPromotionCode?: false | 'onlyForSubscription' | 'forAll';
  archiveAccessStrategy: ArchiveStrategyAccess;
  salesPageSlug?: string;
  forceB2B?: boolean;
  isMetaConversionAvailable: boolean;
}

export type ProductInfoResponse = FreeProductInfo | PaidProductInfo | RenewalProductInfo;

export type ProductOrderCreatedResponse =
  | StripeRecurringProductOrderCreatedResponse
  | OneTimeProductOrderCreatedResponse
  | PayUProductOrderCreatedResponse
  | TpayProductOrderCreatedResponse;

export type StripeRecurringProductOrderCreatedResponse = {
  paymentIntentId: string | null;
  successUrl: string;
  cancelUrl: string;
  subscription: Stripe.Subscription;
  paymentIntent: Stripe.PaymentIntent | null;
  orderId: string;
  isCustomSuccessUrl: boolean;
  paymentClient: PaymentClient.Stripe;
  isFullyDiscounted: boolean;
};

export type OneTimeProductOrderCreatedResponse = {
  paymentIntentId: string;
  successUrl: string;
  cancelUrl: string;
  subscription?: never;
  orderId: string;
  clientSecret: string;
  isCustomSuccessUrl: boolean;
  paymentClient:
    | PaymentClient.Stripe
    | PaymentClient.Metamask
    | PaymentClient.PayU
    | PaymentClient.Tpay;
  isFullyDiscounted: boolean;
};

export type PayUProductOrderCreatedResponse = {
  redirectUrl: string;
  orderId: string;
  paymentClient: PaymentClient.PayU;
  isFullyDiscounted: boolean;
};

export type TpayProductOrderCreatedResponse = {
  redirectUrl: string;
  orderId: string;
  paymentClient: PaymentClient.Tpay;
  isFullyDiscounted: boolean;
};
