import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  ProductClientImports,
  ProductClientsImportAggregationDocument,
} from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchProductClientImports = createAsyncThunk<
  { total: number; isLastPage: boolean; list: ProductClientImports[] },
  { productId: string; ownerId?: string; importId: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchProductClientImports`,
  async (
    { productId, ownerId, importId },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const { productClientImportsListSize } = getState().product;
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_IMPORTS)
        .where('importId', '==', importId)
        .orderBy('createdAt', 'desc')
        .limit(productClientImportsListSize);

      const res = await ref.get();
      const list = res.docs.map((doc) => firestoreDateMapper<ProductClientImports>(doc));

      const totalRef = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_IMPORT_AGGREGATIONS)
        .doc(importId);

      const totalRes = await totalRef.get();
      const totalImportsSize = totalRes.exists
        ? (totalRes.data() as ProductClientsImportAggregationDocument)?.total
        : list.length;
      if (!res.empty) {
        firestoreSubscriptions.lastProductClientImportsCursor = res.docs[res.docs.length - 1];
      }

      return {
        list,
        isLastPage: totalImportsSize < productClientImportsListSize,
        total: totalImportsSize,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
