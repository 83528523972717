import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommunityMember, RequestStatus } from '@mailingr/data-models';

import { COMMUNITY_MEMBERS_REDUCER_NAME, CommunityMembersReducer } from './types';

const initialState: CommunityMembersReducer = {
  updating: false,
  error: null,
  data: [],
  requestStatus: null,
  searchTerm: null,
};

// TODO: add pagination support
const communityMembersSlice = createSlice({
  name: COMMUNITY_MEMBERS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToCommunityMembersStarted(state) {
      state.requestStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToCommunityMembersSucceeded(state, { payload }: PayloadAction<CommunityMember[]>) {
      state.requestStatus = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToCommunityMembersFailed(state, { payload }: PayloadAction<Error>) {
      state.requestStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromCommunityMembers(state) {
      state.data = [];
      state.error = null;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    updateCommunityMemberStarted(state) {
      state.updating = true;
      state.error = null;
    },
    updateCommunityMemberSucceeded(state, { payload }: PayloadAction<CommunityMember>) {
      state.updating = false;
      state.data = state.data.map((member) =>
        member.id === payload.id ? { ...member, ...payload } : member
      );
    },
    updateCommunityMemberFailed(state, { payload }: PayloadAction<Error>) {
      state.updating = false;
      state.error = payload;
    },
  },
});

export const {
  subscribeToCommunityMembersStarted,
  subscribeToCommunityMembersFailed,
  subscribeToCommunityMembersSucceeded,
  unsubscribeFromCommunityMembers,
  clearSearchMode,
  setSearchTerm,
  updateCommunityMemberStarted,
  updateCommunityMemberSucceeded,
  updateCommunityMemberFailed,
} = communityMembersSlice.actions;
export default communityMembersSlice.reducer;
