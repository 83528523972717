import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreateProductDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCTS_REDUCER_NAME } from '../types';

export const addProduct = createAsyncThunk<
  void,
  ActionParams<CreateProductDTO>,
  AsyncThunkCreator<number>
>(
  `${PRODUCTS_REDUCER_NAME}/addProduct`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;

      await functions.httpsCallable('products-createProductV2')({ ...payload, version: 2 });
      analytics.track('create_new_mailing', {
        paymentType: payload.productChargeType,
        isFree: payload.isFree,
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
