import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, OrderDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchNextProductOrdersByEmail = createAsyncThunk<
  { list: OrderDocument[]; isLastPage: boolean; page: number },
  { productId: string; email: string; ownerId?: string; page: number },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchNextProductOrdersByEmail`,
  async (
    { productId, email, ownerId, page },
    { rejectWithValue, getState, extra: { auth, db, firestoreSubscriptions } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const {
        productOrdersListSize,
        isLastOrdersPage: isFetchedAllItems,
        productOrdersPage,
      } = getState().product;

      if (isFetchedAllItems || (productOrdersPage && page <= productOrdersPage)) {
        return {
          page,
          list: [],
          isLastPage: isFetchedAllItems,
        };
      }

      const countPages = page - productOrdersPage;

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_ORDERS)
        .where('clientEmail', '==', email)
        .orderBy('createdAt', 'desc')
        .limit(countPages * productOrdersListSize)
        .startAfter(firestoreSubscriptions.lastOrderCursor);

      const querySnapshot = await ref.get();

      const isLastPage = querySnapshot.docs.length < productOrdersListSize;

      firestoreSubscriptions.lastOrderCursor = isLastPage
        ? null
        : querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        list: querySnapshot.docs.map((doc) => firestoreDateMapper<OrderDocument>(doc)),
        page,
        isLastPage,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
