import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, ProductClientDocument } from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const fetchNextPageProductClients = createAsyncThunk<
  { isLastPage: boolean; list: ProductClientDocument[]; page: number },
  { productId: string; page: number; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/fetchNextPageProductClients`,
  async (
    { productId, page, ownerId },
    { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }
  ) => {
    try {
      const {
        productClientsListSize,
        isLastPage: isFetchedAllItems,
        productClientsPage,
        productClients,
      } = getState().product;

      if (isFetchedAllItems || (productClientsPage && page <= productClientsPage)) {
        return {
          page,
          list: [],
          isLastPage: isFetchedAllItems,
        };
      }

      const countPages = page - Math.ceil(productClients.length / productClientsListSize);

      if (countPages <= 0) {
        return {
          page,
          list: [],
          isLastPage: isFetchedAllItems,
        };
      }

      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
        .where('status', '==', 'active')
        .orderBy('createdAt', 'desc')
        .limit(productClientsListSize * (page - productClientsPage))
        .startAfter(firestoreSubscriptions.lastProductClientCursor);

      const snapshot = await ref.get();

      const isLastPage = snapshot.docs.length < productClientsListSize;

      firestoreSubscriptions.lastProductClientCursor = isLastPage
        ? null
        : snapshot.docs[snapshot.docs.length - 1];

      const list = snapshot.docs.map((doc) => firestoreDateMapper<ProductClientDocument>(doc));
      const currentPage = productClientsPage || 1;

      return {
        list,
        isLastPage,
        page: page - currentPage,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
