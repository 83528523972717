import firebase from 'firebase/compat';

import { AppThunk } from '../../../index';
import { logInSuccess } from '../reducer';
import { UserStatus } from '../types';
import { getUserDetails } from './getUserDetails';

export const setLoggedUserData =
  ({ uid, email }: firebase.User): AppThunk =>
  async (dispatch, getState, { analytics }) => {
    const { status } = getState().user;

    if (status === UserStatus.LOGGING_IN) {
      return;
    }

    dispatch(
      logInSuccess({
        uid,
        email,
      })
    );
    dispatch(getUserDetails(uid));
    analytics.identify(uid, { email });
  };
