import { createAsyncThunk } from '@reduxjs/toolkit';

import { EmailClient } from '@mailingr/data-models';
import { cloudFunctionErrorHandler } from '@mailingr/utils';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export type Payload = {
  url: string;
  client: EmailClient;
  serverPrefix?: string;
  lastNameKey?: string;
  firstNameKey?: string;
  tagsField?: {
    id: number;
    key: string;
    name: string;
  };
  listsField?: {
    id: number;
    key: string;
    name: string;
  };
};

export const refreshConnectedListsWithEmailClient = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/refreshListsConnectedWithEmailClient`,
  async (
    { onSuccess, onFailure, payload },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('User is not logged');
      }
      await functions.httpsCallable('integrations-refreshListsConnectedWithEmailClient')(payload);

      analytics.track('refresh_connected_lists_with_email_client', {
        userId: user.uid,
        email: user.email,
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.(cloudFunctionErrorHandler(e).code);

      return rejectWithValue(e);
    }
  }
);
