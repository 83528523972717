import { createAsyncThunk } from '@reduxjs/toolkit';

import { GainedRewardStatus } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';

type Payload = {
  refOwnerEmail: string;
  rewardId: string;
  selectedStatus: GainedRewardStatus.Confirmed | GainedRewardStatus.Rejected;
};

export const handleRewardConfirmation = createAsyncThunk<void, Payload, AsyncThunkCreator<void>>(
  `${PRODUCT_REFERRALS_REDUCER_NAME}/handleRewardConfirmation`,
  async (payload, { rejectWithValue, extra: { functions }, getState }) => {
    try {
      const { data } = getState().productReferrals;

      if (!data) {
        throw new Error('product referrals data not exists');
      }

      await functions.httpsCallable('referrals-handleRewardConfirmation')({
        productId: data.productId,
        ...payload,
      });
    } catch (e) {
      return rejectWithValue();
    }
  }
);
