import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT, Reward } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  rewards: Reward[];
};

export const updateProductReferralsRewards = createAsyncThunk<void, Payload, AsyncThunkCreator>(
  `${PRODUCT_REFERRALS_REDUCER_NAME}/updateProductReferralsRewards`,
  async (payload, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productReferrals;

      if (!data) {
        throw new Error('product referrals data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_REFERRALS);

      await ref.update({
        rewards: payload.rewards,
        updatedAt: new Date(),
      });
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
