import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  IntegrationDocument,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { INTEGRATION_REDUCER_NAME } from '../types';

export const skipStripeIntegration = createAsyncThunk<
  void,
  ActionParams<void>,
  AsyncThunkCreator<number>
>(
  `${INTEGRATION_REDUCER_NAME}/skipStripeIntegration`,
  async ({ onSuccess, onFailure }, { rejectWithValue, extra: { firestore, analytics, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('User is not logged');
      }

      const ref = firestore()
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.SETTINGS)
        .doc(FIRESTORE_DOCUMENT.INTEGRATION_CONFIG);

      const data: IntegrationDocument = {
        ownerId: user.uid,
        fees: {},
        isStripeConnect: false,
        ownerEmail: user.email,
        stripeConnectedAccountId: false,
        createdAt: new Date(),
        stripeVerificationToken: '',
        updatedAt: new Date(),
        isPayUConnected: null,
      };

      await ref.set(data, { merge: true });
      analytics.track('skip_stripe_integration', {
        userId: user.uid,
        email: user.email,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
