import { createAsyncThunk } from '@reduxjs/toolkit';

import { UpdateProductPriceDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

type Payload = { prices: UpdateProductPriceDTO[] };

export const updateProductPrices = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/updateProductPrices`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('products-updateProductPricesV2')({ ...payload, version: 2 });
      analytics.track('update_mailing_prices', {
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
