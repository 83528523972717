import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { USER_REDUCER } from '../reducer';
import { updateUserData } from './updateUserData';

export const createReferralCode = createAsyncThunk<void, void, AsyncThunkCreator<string>>(
  `${USER_REDUCER}/createReferralCode`,
  async (_, { dispatch, rejectWithValue, extra: { firestore, auth } }) => {
    try {
      const user = await auth().currentUser;

      if (!user || !user.email) {
        throw new Error('User is not logged');
      }

      const res = await firestore().collection(FIRESTORE_COLLECTION.REFERRAL_CODES).add({
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
        email: user.email.toLowerCase(),
      });

      dispatch(
        updateUserData({
          uid: user.uid,
          refCode: res.id,
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        throw rejectWithValue(e.message);
      }

      throw rejectWithValue('Unknown error');
    }
  }
);
