import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  LessonListItem,
  LessonStatus,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  newStatus: LessonStatus;
  moduleId: string;
  productId: string;
  lessonId: string;
};

export const updateContentLessonStatus = createAsyncThunk<
  string,
  Payload,
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/updateContentLessonStatus`,
  async (
    { productId, moduleId, newStatus, lessonId },
    { extra: { analytics, auth, db }, getState }
  ) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    if (!data || !data.modules.some((module) => module.id === moduleId)) {
      throw new Error('invalid-module');
    }

    const now = dayjs();

    const batch = db.batch();

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    const lessonOnList = data.modules
      .find((module) => module.id === moduleId)
      ?.lessons.find((l) => l.id === lessonId);

    if (lessonOnList) {
      const updated: LessonListItem = {
        ...lessonOnList,
        status: newStatus,
        updatedAt: now.unix(),
      };

      const updatedModules = data.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              lessons: module.lessons.map((l) => (l.id === lessonId ? updated : l)),
            }
          : module
      );

      batch.update(ref, {
        modules: updatedModules,
      });
    }

    batch.update(ref.collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId), {
      status: newStatus,
      updatedAt: now.toDate(),
    });

    await batch.commit();

    analytics.track('updated_lesson_status', {
      ownerId: user.uid,
      productId,
      moduleId,
    });

    return lessonId;
  }
);
