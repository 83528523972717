import { IntegrationDocument } from '../documents/IntegrationDocument';
import {
  FreeServiceProduct,
  NFTPrice,
  OneTimeAccessPeriod,
  parseProductPriceId,
  PaymentGatewayConfig,
  PayUProductPrice,
  ProductDocument,
  ProductPrice,
  ProductPriceV2,
  RecurringAccessPeriod,
  ServiceProduct,
  ServiceProductV2,
  StripeProductPrice,
} from '../documents/ProductDocument';
import { PaymentClient, PaymentTypeOption } from '../enums';

export class ProductVersionMigrator {
  private static determineEnabledPaymentClientsFromPrices(
    prices: (ProductPrice | NFTPrice)[],
    product: ProductDocument
  ): PaymentClient[] {
    if ('enabledPaymentGateways' in product && product.enabledPaymentGateways?.length) {
      return product.enabledPaymentGateways;
    }

    const enabledClients = new Set<PaymentClient>();

    (prices || []).forEach((price) => {
      if ('paymentClient' in price && price.paymentClient) {
        enabledClients.add(price.paymentClient);
      } else if ('unit_amount' in price && !('paymentClient' in price)) {
        enabledClients.add(PaymentClient.Stripe);
      }
    });

    return Array.from(enabledClients);
  }

  static compareByStripePriceId(
    price: ProductPrice | NFTPrice | ProductPriceV2,
    stripePriceId?: string
  ) {
    if (!stripePriceId) {
      return false;
    }

    if ('version' in price && price.version === 2) {
      return (
        price.paymentGateways?.[PaymentClient.Stripe]?.priceId === stripePriceId ||
        price.id === stripePriceId
      );
    }

    return price.id === stripePriceId;
  }

  static allowQuantityBuy(price: ProductPrice | NFTPrice) {
    const priceV2 = this.transformPriceToV2(price);

    if (priceV2.type === PaymentTypeOption.NFT) {
      return false;
    }

    if (
      priceV2?.paymentGateways?.[PaymentClient.Stripe]?.priceId &&
      !priceV2.paymentGateways[PaymentClient.Stripe]?.disabled
    ) {
      // quantity buy is not supported for stripe
      return false;
    }

    if (priceV2?.paymentGateways?.[PaymentClient.PayU]?.priceId) {
      // quantity buy is supported for payu
      return true;
    }

    if (priceV2?.paymentGateways?.[PaymentClient.Tpay]?.priceId) {
      // quantity buy is supported for tpay
      return true;
    }

    return false;
  }

  static transformPriceToV2(
    price: ProductPrice | NFTPrice | ProductPriceV2
  ): ProductPriceV2 | NFTPrice {
    if ('version' in price && price.version === 2) {
      return price;
    }

    if (
      'paymentClient' in price &&
      'unit_amount' in price &&
      price.paymentClient === PaymentClient.PayU
    ) {
      const payuPrice = price as PayUProductPrice;

      return {
        id: parseProductPriceId(payuPrice.id),
        type: 'one_time',
        active: payuPrice.active,
        created: payuPrice.created,
        status: payuPrice.status,
        currency: payuPrice.currency,
        unitAmount: payuPrice.unit_amount,
        accessPeriod: payuPrice.accessPeriod as OneTimeAccessPeriod | null,
        customLabel: payuPrice.customLabel || '',
        customInvoiceName: payuPrice.customInvoiceName || '',
        config: payuPrice.config,
        pendingOrders: payuPrice.pendingOrders,
        checkoutId: payuPrice.checkoutId,
        hidden: payuPrice.hidden ?? false,
        archivedClients: payuPrice.archivedClients ?? 0,
        clients: payuPrice.clients ?? 0,
        connectedUpsells: payuPrice.connectedUpsells || null,
        quantity: payuPrice.quantity,
        promoteNetPrice: payuPrice.promoteNetPrice,
        vatRate: payuPrice.vatRate,
        paymentCycles: payuPrice.paymentCycles,
        version: 2,
        paymentGateways: {
          [PaymentClient.PayU]: {
            priceId: payuPrice.id,
          },
        } satisfies PaymentGatewayConfig,
      };
    } else if ('unit_amount' in price) {
      const stripePrice = price as StripeProductPrice;

      return stripePrice.type === 'recurring'
        ? {
            id: parseProductPriceId(stripePrice.id),
            type: 'recurring',
            active: stripePrice.active,
            created: stripePrice.created,
            status: stripePrice.status,
            currency: stripePrice.currency,
            unitAmount: stripePrice.unit_amount || 0,
            accessPeriod: stripePrice.accessPeriod as RecurringAccessPeriod,
            customLabel: stripePrice.customLabel,
            customInvoiceName: stripePrice.customInvoiceName,
            config: stripePrice.config,
            pendingOrders: stripePrice.pendingOrders,
            checkoutId: stripePrice.checkoutId,
            hidden: stripePrice.hidden,
            archivedClients: stripePrice.archivedClients,
            clients: stripePrice.clients,
            connectedUpsells: stripePrice.connectedUpsells,
            quantity: stripePrice.quantity,
            promoteNetPrice: stripePrice.promoteNetPrice,
            vatRate: stripePrice.vatRate,
            paymentCycles: stripePrice.paymentCycles,
            metadata: stripePrice.metadata,
            version: 2,
            paymentGateways: {
              [PaymentClient.Stripe]: {
                priceId: stripePrice.id,
                billingScheme: stripePrice.billing_scheme,
                livemode: stripePrice.livemode,
                unit_amount_decimal: stripePrice.unit_amount_decimal,
                unit_amount: stripePrice.unit_amount,
                recurring: stripePrice.recurring,
              },
            } satisfies PaymentGatewayConfig,
          }
        : {
            id: parseProductPriceId(stripePrice.id),
            type: 'one_time',
            active: stripePrice.active,
            created: stripePrice.created,
            status: stripePrice.status,
            currency: stripePrice.currency,
            unitAmount: stripePrice.unit_amount || 0,
            accessPeriod: stripePrice.accessPeriod as OneTimeAccessPeriod | null,
            customLabel: stripePrice.customLabel,
            customInvoiceName: stripePrice.customInvoiceName,
            config: stripePrice.config,
            pendingOrders: stripePrice.pendingOrders,
            checkoutId: stripePrice.checkoutId,
            hidden: stripePrice.hidden,
            archivedClients: stripePrice.archivedClients,
            clients: stripePrice.clients,
            connectedUpsells: stripePrice.connectedUpsells,
            quantity: stripePrice.quantity,
            promoteNetPrice: stripePrice.promoteNetPrice,
            vatRate: stripePrice.vatRate,
            paymentCycles: stripePrice.paymentCycles,
            metadata: stripePrice.metadata,
            version: 2,
            paymentGateways: {
              [PaymentClient.Stripe]: {
                priceId: stripePrice.id,
                billingScheme: stripePrice.billing_scheme,
                livemode: stripePrice.livemode,
                unit_amount_decimal: stripePrice.unit_amount_decimal,
                unit_amount: stripePrice.unit_amount,
                recurring: stripePrice.recurring,
              },
            } satisfies PaymentGatewayConfig,
          };
    }

    return price as NFTPrice;
  }

  static transformToV2(
    product: FreeServiceProduct | ServiceProduct | ServiceProductV2,
    providedPaymentClients?: PaymentClient[]
  ): ServiceProductV2 | FreeServiceProduct {
    if (product.isFree) {
      return product as FreeServiceProduct;
    }
    if (product.version === 2) {
      return product;
    }

    // Transform prices to V2 format using the new method
    const pricesV2: ProductPriceV2[] = (product.prices || [])
      .map((price) => this.transformPriceToV2(price))
      .filter((price): price is ProductPriceV2 => price !== null);

    // Use provided payment clients or determine from prices
    const enabledPaymentGateways =
      providedPaymentClients ??
      this.determineEnabledPaymentClientsFromPrices(product.prices || [], product);

    // Transform payment methods
    const paymentsMethods: ServiceProductV2['paymentsMethods'] = product.paymentsMethods
      ? {
          blik: product.paymentsMethods.blik ? PaymentClient.Stripe : false,
          p24: product.paymentsMethods.p24 ? PaymentClient.Stripe : false,
          applePay: product.paymentsMethods.applePay ? PaymentClient.Stripe : false,
          googlePay: product.paymentsMethods.googlePay ? PaymentClient.Stripe : false,
        }
      : undefined;

    return {
      ...product,
      prices: pricesV2,
      version: 2 as const,
      enabledPaymentGateways,
      paymentsMethods,
    };
  }

  static getActivePaymentGatewaysForAccount(integration: IntegrationDocument) {
    const enabledPaymentGateways: PaymentClient[] = [];

    if (integration.stripeConnectedAccountId && integration.isStripeConnect) {
      enabledPaymentGateways.push(PaymentClient.Stripe);
    }

    if (integration.payU && integration.isPayUConnected) {
      enabledPaymentGateways.push(PaymentClient.PayU);
    }

    if (integration.tpay && integration.isTpayConnected) {
      enabledPaymentGateways.push(PaymentClient.Tpay);
    }

    return enabledPaymentGateways;
  }
}
