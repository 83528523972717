import { BaseDocument } from './BaseDocument';
import { BunnyVideoObject } from './BunnyObjects';

export enum ContentType {
  Learning = 'learning',
}

export enum ModuleStatus {
  Draft = 'draft',
  Public = 'public',
  Hidden = 'hidden',
  Locked = 'locked',
}

export enum LessonStatus {
  Draft = 'draft',
  Public = 'public',
  Hidden = 'hidden',
  Locked = 'locked',
}

export enum LessonType {
  Video = 'video',
  ExternalVideo = 'externalVideo',
  Article = 'article',
  Task = 'task',
}

export interface ContentAuthor {
  firstName?: string;
  lastName?: string;
  email?: string;
  avatar?: string[];
}

export interface LessonBaseDocument extends BaseDocument {
  id: string;
  type: LessonType;
  status: LessonStatus;
  title: string;
  description: string;
  tags?: string[];
  moduleId: string;
  productId: string;
  ownerId: string;
  thumbnail: string | null;
  attachments: { url: string; name: string; uid: string }[];
  content: string;
  editorContent: string;
  blockEditor?: boolean;
  author: ContentAuthor | null;
}

export enum VideoLessonStatus {
  Pending = 'Pending',
  Queued = 'queued',
  Processing = 'processing',
  Encoding = 'encoding',
  Ready = 'ready',
  Failed = 'failed',
}

export interface VideoLessonDocument extends LessonBaseDocument {
  type: LessonType.Video;
  video:
    | null
    | ({
        type: 'bunny';
        videoStatus: VideoLessonStatus;
        authorizationSignature: string;
        authorizationExpire: number;
      } & BunnyVideoObject);
}

export interface ExternalVideoLessonDocument extends LessonBaseDocument {
  type: LessonType.ExternalVideo;
  video: null | {
    type: 'external';
    url: string;
    length: number;
  };
}

export interface ArticleLessonDocument extends LessonBaseDocument {
  type: LessonType.Article;
}

export interface TaskLessonDocument extends LessonBaseDocument {
  type: LessonType.Task;
}

export interface LessonListItem {
  id: string;
  type: LessonType;
  createdAt: number;
  updatedAt: number;
  status: LessonStatus;
  title: string;
  description: string;
  tags?: string[];
  moduleId: string;
  length: null | number;
  size: null | number;
  thumbnail: string | null;
}

export interface ContentModule {
  id: string;
  type: ContentType;
  createdAt: number;
  updatedAt: number;
  status: ModuleStatus;
  name: string;
  description: string;
  thumbnail?: string | null;
  author: ContentAuthor | null;
  lessons: LessonListItem[];
  unlockDelay: number | null;
  unlock?: {
    afterDate: number | null;
    clientAccessDate: number | null;
  } | null;
}

export interface ProductContentDocument extends BaseDocument {
  modules: ContentModule[];
  productId: string;
  ownerId: string;
  videoCollectionId: string | null;
}

export type LessonDocument =
  | VideoLessonDocument
  | ExternalVideoLessonDocument
  | ArticleLessonDocument
  | TaskLessonDocument;
