import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  LessonDocument,
  ProductContentDocument,
} from '@mailingr/data-models';

import { firestoreDateMapper, getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  oldProductId: string;
  newProductId: string;
  oldOwnerId: string;
  newOwnerId: string;
};

export const duplicateCourseContent = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/duplicateCourseContent`,
  async (
    { oldProductId, newProductId, oldOwnerId, newOwnerId },
    { getState, extra: { auth, db } }
  ) => {
    const user = auth().currentUser;
    const { isAdmin } = getState().user;

    if (!user || ((oldOwnerId !== user.uid || newOwnerId !== user.uid) && !isAdmin)) {
      throw new Error('invalid-user');
    }

    const oldContentRef = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(oldOwnerId)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(oldProductId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    const oldContentSnapshot = await oldContentRef.get();
    const oldContent = oldContentSnapshot.exists
      ? firestoreDateMapper<ProductContentDocument>(oldContentSnapshot)
      : null;

    if (!oldContent) {
      throw new Error('invalid-content');
    }

    const oldLessonsRef = oldContentRef
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS)
      .withConverter(getDocumentConverter<LessonDocument>());

    const oldLessonsSnapshot = await oldLessonsRef.get();
    const oldLessons = oldLessonsSnapshot.docs.map((doc) => doc.data());

    if (!oldLessons) {
      throw new Error('invalid-lessons');
    }

    const newContentRef = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(newOwnerId)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(newProductId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    const batch = db.batch();

    const newContentModules = oldContent.modules.map((module) => {
      const moduleId = uuid();
      const lessons = module.lessons.map((lesson) => ({ ...lesson, id: uuid(), moduleId }));

      return { ...module, id: moduleId, lessons };
    });

    const newContentLessons = newContentModules.map((module) => module.lessons).flat();
    const oldContentLessons = oldContent.modules.map((module) => module.lessons).flat();

    batch.update(newContentRef, {
      ...oldContent,
      productId: newProductId,
      ownerId: newOwnerId,
      modules: newContentModules,
      updatedAt: new Date(),
    });

    oldContentLessons.forEach((lesson, index) => {
      const newLessonRef = newContentRef
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS)
        .doc(newContentLessons[index].id);
      batch.set(newLessonRef, {
        ...oldLessons.find((doc) => doc.id === lesson.id),
        id: newContentLessons[index].id,
        moduleId: newContentLessons[index].moduleId,
        productId: newProductId,
        ownerId: newOwnerId,
      });
    });

    await batch.commit();
  }
);
