import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreateDiscountDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { DISCOUNT_REDUCER_NAME } from '../types';

type Payload = CreateDiscountDTO;

export const addDiscount = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<number>>(
  `${DISCOUNT_REDUCER_NAME}/addDiscount`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('discounts-createDiscount')(payload);
      analytics.track('add-discount', {
        discountId: 'new',
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
