import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntegrationDocument, RequestStatus } from '@mailingr/data-models';

import { INTEGRATION_REDUCER_NAME, IntegrationReducer } from './types';

const initialState: IntegrationReducer = {
  error: null,
  status: null,
  data: null,
  showProductList: false,
};

const integrationSlice = createSlice({
  name: INTEGRATION_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToIntegrationStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToIntegrationSuccess(state, { payload }: PayloadAction<IntegrationDocument | null>) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToIntegrationFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromIntegration(state) {
      state.status = null;
      state.error = null;
      state.data = null;
    },
  },
});

export const {
  subscribeToIntegrationFailed,
  subscribeToIntegrationStarted,
  subscribeToIntegrationSuccess,
  unsubscribeFromIntegration,
} = integrationSlice.actions;

export default integrationSlice.reducer;
