import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommunitySettings, RequestStatus } from '@mailingr/data-models';

import { COMMUNITY_SETTINGS_REDUCER_NAME, CommunitySettingsReducer } from './types';

const initialState: CommunitySettingsReducer = {
  data: null,
  error: null,
  requestStatus: null,
};

const communitySettingsSlice = createSlice({
  name: COMMUNITY_SETTINGS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToCommunitySettingsStarted(state) {
      state.requestStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToCommunitySettingsSuccess(
      state,
      { payload }: PayloadAction<CommunitySettings | null>
    ) {
      state.requestStatus = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToCommunitySettingsFailed(state, { payload }: PayloadAction<Error>) {
      state.requestStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromCommunitySettings(state) {
      state.data = null;
      state.error = null;
    },
  },
});
export const {
  subscribeToCommunitySettingsStarted,
  subscribeToCommunitySettingsSuccess,
  subscribeToCommunitySettingsFailed,
  unsubscribeFromCommunitySettings,
} = communitySettingsSlice.actions;
export default communitySettingsSlice.reducer;
