import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  BATCH_SIZE_LIMIT,
  FIRESTORE_COLLECTION,
  NotificationStatus,
  NotificationType,
} from '@mailingr/data-models';
import { splitIntoChunks } from '@mailingr/utils';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../../product/types';

export const markAllAsRead = createAsyncThunk<void, void, AsyncThunkCreator<number>>(
  `${PRODUCT_REDUCER_NAME}/markAllAsRead`,
  async (_, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }
      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.NOTIFICATIONS)
        .where('status', '==', NotificationStatus.UNREAD)
        .where('type', 'in', [NotificationType.NewsletterInfo, NotificationType.ClientAction]);

      const allUnread = await ref.get();

      const chunks = splitIntoChunks(allUnread.docs, BATCH_SIZE_LIMIT);

      for (const chunk of chunks) {
        const batch = db.batch();
        chunk.forEach((doc) => {
          batch.update(doc.ref, {
            status: NotificationStatus.READ,
          });
        });

        await batch.commit();
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
