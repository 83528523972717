import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderDocument, RequestStatus } from '@mailingr/data-models';

import { ORDERS_REDUCER_NAME, OrdersReducer } from './types';

const initialState: OrdersReducer = {
  orders: [],
  ordersStatus: null,
  productId: null,
  searchTerm: null,
  startMonth: null,
  endMonth: null,
  error: null,
};

const ordersSlice = createSlice({
  name: ORDERS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToOrdersStarted(
      state,
      { payload }: PayloadAction<{ startMonth: string; endMonth: string }>
    ) {
      state.ordersStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.startMonth = payload.startMonth;
      state.endMonth = payload.endMonth;
    },
    subscribeToOrdersSuccess(state, { payload }: PayloadAction<OrderDocument[]>) {
      state.ordersStatus = RequestStatus.SUBSCRIBED;
      state.orders = payload;
    },
    subscribeToOrdersFailed(state, { payload }: PayloadAction<Error>) {
      state.ordersStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromOrders() {
      return initialState;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
});

export const {
  subscribeToOrdersFailed,
  subscribeToOrdersStarted,
  subscribeToOrdersSuccess,
  unsubscribeFromOrders,
  clearSearchMode,
  setSearchTerm,
} = ordersSlice.actions;

export default ordersSlice.reducer;
