import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import { CreatorStatsDocument, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { ADMIN_REDUCER_NAME } from '../types';

export const fetchProductsOwners: AsyncThunk<
  CreatorStatsDocument[],
  void,
  AsyncThunkCreator<string>
> = createAsyncThunk<CreatorStatsDocument[], void, AsyncThunkCreator<string>>(
  `${ADMIN_REDUCER_NAME}/fetchProductsOwners`,
  async (_, { extra: { db } }) => {
    const ref = db
      .collection(FIRESTORE_COLLECTION.REPORTS)
      .orderBy('totalNumberOfTransactions', 'desc')
      .withConverter(getDocumentConverter<CreatorStatsDocument>());

    const data = await ref.get();

    return data.docs.map((doc) => doc.data());
  }
);
