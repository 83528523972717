import {
  ProductClientReferrals,
  ProductReferralsDocument,
  RequestStatus,
} from '@mailingr/data-models';

export const PRODUCT_REFERRALS_REDUCER_NAME = 'ProductReferrals';

export interface ProductReferralsReducer {
  data: ProductReferralsDocument | null;
  status: null | RequestStatus;
  error: null | string;
  ranking: ProductClientReferrals[];
  rankingStatus: null | RequestStatus;
}
