import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Community, CommunityId, RequestStatus } from '@mailingr/data-models';

import { COMMUNITY_REDUCER_NAME, CommunityReducer } from './types';

const initialState: CommunityReducer = {
  requestStatus: null,
  error: null,
  community: null,
  communityId: null,
};

const communitiesSlice = createSlice({
  name: COMMUNITY_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToCommunityStarted(state, { payload }: PayloadAction<CommunityId>) {
      state.requestStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.communityId = payload || null;
    },
    subscribeToCommunitySuccess(state, { payload }: PayloadAction<Community | null>) {
      state.requestStatus = RequestStatus.SUBSCRIBED;
      state.community = payload;
    },
    subscribeToCommunityFailed(state, { payload }: PayloadAction<Error>) {
      state.requestStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromCommunity() {
      return initialState;
    },
  },
});

export const {
  subscribeToCommunityFailed,
  subscribeToCommunitySuccess,
  subscribeToCommunityStarted,
  unsubscribeFromCommunity,
} = communitiesSlice.actions;

export default communitiesSlice.reducer;
