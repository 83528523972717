import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, UpdateCommunityGroupDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';

export const updateCommunityGroup = createAsyncThunk<
  void,
  ActionParams<UpdateCommunityGroupDTO>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITY_GROUPS_REDUCER_NAME}/updateCommunityGroup`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('User is not logged in');
      }

      const communityRef = db.collection(FIRESTORE_COLLECTION.COMMUNITIES).doc(payload.communityId);
      const groupRef = communityRef
        .collection(FIRESTORE_COLLECTION.COMMUNITY_GROUPS)
        .doc(payload.id);

      const { id, communityId, ...updateData } = payload;

      await groupRef.update({
        ...updateData,
        updatedAt: new Date(),
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
