import firebase from 'firebase/compat';

import { FIRESTORE_COLLECTION, UserActivityStatus, UserDocument } from '@mailingr/data-models';

import { AppThunk } from '../../../index';
import { getUserDetailsFailed, getUserDetailsStarted, getUserDetailsSuccess } from '../reducer';
import { logOutUser } from './logOutUser';
import { setUserPresence } from './setUserPresence';

export const getUserDetails =
  (uid: string): AppThunk =>
  async (dispatch, getState, { db, database, firestoreSubscriptions, analytics, auth }) => {
    dispatch(getUserDetailsStarted());
    try {
      const ref = db.collection(FIRESTORE_COLLECTION.USERS).doc(uid);

      if (firestoreSubscriptions.userDetailsListener) {
        firestoreSubscriptions.userDetailsListener();
      }

      const idTokenResult = await auth().currentUser?.getIdTokenResult();
      const isImpersonated = idTokenResult?.claims?.impersonated || false;

      firestoreSubscriptions.userDetailsListener = ref.onSnapshot(
        (snapshot) => {
          const data = snapshot.data() as
            | (UserDocument & {
                createdAt: firebase.firestore.Timestamp;
                updatedAt: firebase.firestore.Timestamp;
              })
            | undefined;
          dispatch(
            getUserDetailsSuccess(
              data
                ? {
                    ...data,
                    createdAt: data.createdAt?.toDate(),
                    updatedAt: data.updatedAt?.toDate(),
                    systemRole: idTokenResult?.claims?.systemRole || null,
                    isImpersonated,
                  }
                : undefined
            )
          );
          if (!isImpersonated) {
            dispatch(setUserPresence(database ? undefined : UserActivityStatus.Online));
          }
          if (data) {
            analytics.updateUserProperties(uid, {
              roles: data.allowedRoles,
              email: data?.email,
              name: data.firstName ? `${data.firstName} ${data.lastName ?? ''}` : undefined,
            });
          }
        },
        () => {
          dispatch(getUserDetailsFailed());
          dispatch(logOutUser());
        }
      );
    } catch (e) {
      dispatch(getUserDetailsFailed());
    }
  };
