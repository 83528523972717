import {
  ClientProductCourseProgressDocument,
  FreeAccessDocument,
  OrderDocument,
  ProductAccessResponse,
  ProductClientDocument,
  ProductClientImports,
  ProductDocumentV2,
  ProductInfoResponse,
  ProductPriceAction,
  RequestStatus,
} from '@mailingr/data-models';

export const PRODUCT_REDUCER_NAME = 'Product';

export interface ProductReducer {
  productDetailsStatus: null | RequestStatus;
  details: ProductDocumentV2 | null;
  productError: null | Error;
  productCourseProgress: ClientProductCourseProgressDocument[];
  productCourseProgressStatus: RequestStatus | null;
  productCourseProgressSize: number;
  productCourseProgressPage: number;
  isLastPageForProgress: boolean;
  productClientsListStatus: RequestStatus | null;
  productClientsListSize: number;
  productClients: ProductClientDocument[];
  productClientsPage: number;
  productClientsActiveTotal: number;
  productOrdersPage: number;
  productOrders: OrderDocument[];
  productOrdersListSize: number;
  isLastPage: boolean;
  productId: string | null;
  freeAccessList: FreeAccessDocument[];
  freeAccessPage: number;
  freeAccessListSize: number;
  freeAccessListStatus: null | RequestStatus;
  addingNewInvitation: boolean;
  freeAccessIsLastPage: boolean;
  productOrdersStatus: RequestStatus | null;
  productPricesActions: null | { id: string; action: ProductPriceAction }[];
  checkoutProductInfo: null | ProductInfoResponse;
  checkoutProductInfoStatus: null | RequestStatus;
  checkAccessInfo: null | ProductAccessResponse;
  checkAccessInfoStatus: null | RequestStatus;
  isLastOrdersPage: boolean;
  productOrdersClientEmail: string | null;
  productArchivedClients: ProductClientDocument[];
  productArchivedClientsListSize: number;
  productArchivedListStatus: RequestStatus | null;
  productArchivedPage: number;
  isLastPageForArchived: boolean;
  searchStatus: RequestStatus | null;
  searchTerm: string;
  productClientImportsPage: number;
  productClientImportsListStatus: RequestStatus | null;
  productClientImportsListSize: number;
  productClientImports: ProductClientImports[];
  isLastProductClientImportsPage: boolean;
  productClientTotalImports: number;
}
