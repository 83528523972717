import { Community, CommunityId, RequestStatus } from '@mailingr/data-models';

export const COMMUNITY_REDUCER_NAME = 'Community';

export interface CommunityReducer {
  requestStatus: null | RequestStatus;
  community: Community | null;
  communityId: CommunityId | null;
  error: null | Error;
}
