import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductClientDocument, RequestStatus } from '@mailingr/data-models';

import { NEWSLETTERS_REDUCER_NAME, NewslettersReducer } from './types';

const initialState: NewslettersReducer = {
  list: [],
  listStatus: null,
  listError: null,
  searchTerm: null,
};

const newslettersSlice = createSlice({
  name: NEWSLETTERS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToNewslettersStarted(state) {
      state.listStatus = RequestStatus.SUBSCRIBING;
      state.listError = null;
    },
    subscribeToNewslettersSuccess(state, { payload }: PayloadAction<ProductClientDocument[]>) {
      state.listStatus = RequestStatus.SUBSCRIBED;
      state.list = payload;
    },
    subscribeToNewslettersFailed(state, { payload }: PayloadAction<Error>) {
      state.listStatus = RequestStatus.FAILED;
      state.listError = payload;
    },
    unsubscribeFromNewsletters(state) {
      state.list = [];
      state.listStatus = null;
      state.listError = null;
    },
    clearSearchMode(state) {
      state.searchTerm = '';
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
});

export const {
  subscribeToNewslettersFailed,
  subscribeToNewslettersStarted,
  subscribeToNewslettersSuccess,
  unsubscribeFromNewsletters,
  clearSearchMode,
  setSearchTerm,
} = newslettersSlice.actions;
export default newslettersSlice.reducer;
