import { createSelector, Selector } from '@reduxjs/toolkit';

import { ProgressStatistics } from '@mailingr/data-models';
import { getClientsProgress as getClientsProgressHelper } from '@mailingr/utils';

import { AppStore } from '../../../index';

const getProductContent = (store: AppStore) => store.productContent.data;
const getProductCourseProgressList = (store: AppStore) => store.product.productCourseProgress;

export const getClientsProgress: Selector<AppStore, ProgressStatistics[]> = createSelector(
  [getProductContent, getProductCourseProgressList],
  (productContent, productCourseProgressList): ProgressStatistics[] => {
    if (!productContent || !productCourseProgressList) {
      return [];
    }

    return getClientsProgressHelper(productContent, productCourseProgressList);
  }
);
