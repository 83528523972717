import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FreeProductStatsDocument,
  PaidProductStatsDocument,
} from '@mailingr/data-models';

import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { AsyncThunkCreator } from '../../../index';
import { STATISTICS_REDUCER_NAME } from '../types';

export const fetchProductStats = createAsyncThunk<
  PaidProductStatsDocument | FreeProductStatsDocument | null,
  { productId: string; ownerId?: string },
  AsyncThunkCreator<number>
>(
  `${STATISTICS_REDUCER_NAME}/fetchProductStats`,
  async ({ productId, ownerId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .doc(ownerId || user.uid)
        .collection(FIRESTORE_COLLECTION.STATS_PER_PRODUCT)
        .doc(productId);

      const doc = await ref.get();

      return doc.exists
        ? firestoreDateMapper<PaidProductStatsDocument | FreeProductStatsDocument>(doc)
        : null;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
