import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type GetVideoDownloadLinkResponse = {
  downloadLink: string;
};

export const getVideoDownloadLink = createAsyncThunk<
  GetVideoDownloadLinkResponse,
  { ownerId?: string; productId: string; lessonId: string; quality: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/getVideoDownloadLink`,
  async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
      const res = await functions.httpsCallable('videos-createVideoDownloadLink')(payload);

      return res.data.body as GetVideoDownloadLinkResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
