import { Group, RequestStatus } from '@mailingr/data-models';

export interface CommunityGroupsReducer {
  requestStatus: null | RequestStatus;
  data: Group[];
  adding: boolean;
  error: null | Error;
  searchTerm: string | null;
}

export const COMMUNITY_GROUPS_REDUCER_NAME = 'CommunityGroups';
