import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_REDUCER_NAME } from '../types';

export const findFreeAccessAccountByEmail = createAsyncThunk<
  boolean,
  { productId: string; email: string },
  AsyncThunkCreator<number>
>(
  `${PRODUCT_REDUCER_NAME}/findFreeAccessAccountByEmail`,
  async ({ productId, email }, { rejectWithValue, extra: { auth, db } }) => {
    try {
      const user = auth().currentUser;
      if (!user) {
        throw new Error('user-is-not-logged');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS)
        .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_FREE_ACCESS)
        .where('email', '==', email);

      const doc = await ref.get();

      return !doc.empty;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
