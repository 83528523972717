import { createAsyncThunk } from '@reduxjs/toolkit';

import { EndAccessStrategy, FIRESTORE_COLLECTION } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { NEWSLETTER_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  clientEmail: string;
  updatedAccessStrategy: EndAccessStrategy;
};

export const updateNewsletterEndAccessStrategy = createAsyncThunk<
  void,
  Payload,
  AsyncThunkCreator<string>
>(
  `${NEWSLETTER_REDUCER_NAME}/updateNewsletterEndAccessStrategy`,
  async (payload, { extra: { db, auth } }) => {
    const user = auth().currentUser;

    if (!user || !user.email) {
      throw new Error('user-is-not-logged');
    }

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(payload.productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CLIENTS)
      .doc(payload.clientEmail);

    await ref.update({
      'accessPeriod.endAccessStrategy': payload.updatedAccessStrategy,
    });
  }
);
