import { ProductClientDocument, RequestStatus } from '@mailingr/data-models';

export const NEWSLETTERS_REDUCER_NAME = 'Newsletters';

export interface NewslettersReducer {
  list: ProductClientDocument[];
  listStatus: null | RequestStatus;
  listError: null | Error;
  searchTerm: string | null;
}
