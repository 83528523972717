import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';

type Payload = {
  productId: string;
  authorId: string;
  paymentGatewayCustomerId: string;
  paymentMethodId: string;
};

export const updatePaymentMethodForSubscription = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<number>
>(
  `${NEWSLETTERS_REDUCER_NAME}/updatePaymentMethodForSubscription`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;
      await functions.httpsCallable('newsletters-updatePaymentMethod')(payload);
      analytics.track('update_payment_method', {
        productId: payload.productId,
        userId: user?.uid ?? '',
        authorId: payload.authorId,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
