import { OrderDocument, RequestStatus } from '@mailingr/data-models';

export const ORDERS_REDUCER_NAME = 'Orders';

export interface OrdersReducer {
  orders: OrderDocument[];
  startMonth: string | null;
  endMonth: string | null;
  productId: string | null;
  ordersStatus: RequestStatus | null;
  searchTerm: string | null;
  error: Error | null;
}
