import { ClientNotifications, OwnerNotifications, RequestStatus } from '@mailingr/data-models';

export const NOTIFICATIONS_REDUCER_NAME = 'Notifications';

export enum NotificationAction {
  MarkAsRead = 'MARK_AS_READ',
}

export interface NotificationsReducer {
  notifications: (ClientNotifications | OwnerNotifications)[];
  listStatus: null | RequestStatus;
  totalUnread: number;
  filter: 'all' | 'unread';
  performingAction: null | {
    action: NotificationAction;
    notificationId?: string;
  };
}
