import { createAsyncThunk } from '@reduxjs/toolkit';

import { FIRESTORE_COLLECTION, SubscriberIntegrationClient } from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { USER_REDUCER } from '../reducer';

export const removeClientDiscordIntegration = createAsyncThunk<
  void,
  void,
  AsyncThunkCreator<string>
>(
  `${USER_REDUCER}/removeClientDiscordIntegration`,
  async (_, { rejectWithValue, extra: { firestore, auth } }) => {
    try {
      const user = await auth().currentUser;

      if (!user || !user.email) {
        throw new Error('User is not logged');
      }

      await firestore()
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .update({
          [`clientIntegrations.${SubscriberIntegrationClient.Discord}`]:
            firestore.FieldValue?.delete(),
        });
    } catch (e) {
      if (e instanceof Error) {
        throw rejectWithValue(e.message);
      }

      throw rejectWithValue('Unknown error');
    }
  }
);
