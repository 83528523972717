import { CommunityChannel, RequestStatus } from '@mailingr/data-models';

export interface CommunityChannelsReducer {
  requestStatus: null | RequestStatus;
  data: CommunityChannel[];
  adding: boolean;
  error: null | Error;
  searchTerm: string | null;
}

export const COMMUNITY_CHANNELS_REDUCER_NAME = 'CommunityChannels';
