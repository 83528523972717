import { UserActivityStatus } from '@mailingr/data-models';

import { AppThunk } from '../../../index';
import { unsubscribeFromUserReferrals } from '../../userReferrals/actions';
import { logOutFailed, logOutStarted, logOutSuccess } from '../reducer';
import { setUserPresence } from './setUserPresence';
import { unsubscribeFromUserDetails } from './unsubscribeFromUserDetails';

export const logOutUser =
  (callback?: () => void): AppThunk =>
  async (dispatch, getState, { auth }) => {
    dispatch(logOutStarted());
    try {
      await dispatch(setUserPresence(UserActivityStatus.Offline));
      await auth().signOut();
      dispatch(unsubscribeFromUserDetails());
      dispatch(unsubscribeFromUserReferrals());
      dispatch(logOutSuccess());
      callback?.();
    } catch (e) {
      dispatch(logOutFailed(e.code));
    }
  };
