import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  LessonDocument,
  LessonListItem,
  LessonStatus,
  LessonType,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';

type Payload = {
  lessonDto: {
    title: string;
    description: string;
    type: LessonType;
  };
  moduleId: string;
  productId: string;
};

export const addContentLesson = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CONTENT_REDUCER_NAME}/addContentLesson`,
  async (
    { productId, moduleId, lessonDto: { type, ...lessonBase } },
    { extra: { analytics, auth, db }, getState }
  ) => {
    const { data } = getState().productContent;

    const user = auth().currentUser;

    if (!user) {
      throw new Error('invalid-user');
    }

    if (!data || !data.modules.some((module) => module.id === moduleId)) {
      throw new Error('invalid-module');
    }

    const now = dayjs();
    const lessonId = uuid();

    const lesson: LessonDocument = {
      ...lessonBase,
      id: lessonId,
      productId,
      ownerId: user.uid,
      content: '',
      editorContent: '',
      blockEditor: false,
      status: LessonStatus.Draft,
      moduleId,
      createdAt: now.toDate(),
      updatedAt: now.toDate(),
      thumbnail: null,
      author: null,
      tags: [],
      attachments: [],
      ...(type === LessonType.Video || type === LessonType.ExternalVideo
        ? { video: null, type }
        : { type }),
    };

    const lessonItemList: LessonListItem = {
      ...lessonBase,
      type,
      status: LessonStatus.Draft,
      thumbnail: null,
      tags: [],
      id: lessonId,
      moduleId,
      createdAt: now.unix(),
      updatedAt: now.unix(),
      length: null,
      size: null,
    };

    const ref = db
      .collection(FIRESTORE_COLLECTION.USERS)
      .doc(user.uid)
      .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
      .doc(productId)
      .collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT)
      .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);

    const batch = db.batch();

    batch.set(ref.collection(FIRESTORE_COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId), lesson);
    batch.update(ref, {
      modules: data.modules.map((module) =>
        module.id === moduleId
          ? { ...module, lessons: [...module.lessons, lessonItemList] }
          : module
      ),
    });

    await batch.commit();

    analytics.track('add_content_lesson', {
      ownerId: user.uid,
      productId,
      moduleId,
      type,
    });

    return lesson.id;
  }
);
