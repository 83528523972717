import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
  ProductAutomationDocument,
} from '@mailingr/data-models';

import { AsyncThunkCreator } from '../../../index';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  integrations: ProductAutomationDocument['productIntegrations'];
};

export const updateProductAutomationIntegrations = createAsyncThunk<
  void,
  Payload,
  AsyncThunkCreator<unknown>
>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationIntegrations`,
  async (payload, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error('product automation data not exists');
      }

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_CONFIG)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);

      await ref.update({
        productIntegrations: payload,
        updatedAt: new Date(),
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
