import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArchivedEmailDocument, RequestStatus } from '@mailingr/data-models';

import { removeArchivedEmail } from './actions/removeArchivedEmail';
import { toggleVisibility } from './actions/toggleVisibility';
import { ARCHIVE_REDUCER_NAME, ArchiveReducer } from './types';

const initialState: ArchiveReducer = {
  error: null,
  status: null,
  data: [],
  fetchingFirstPage: false,
  fetchingNextPage: false,
  isLastPage: false,
  pageSize: 200,
};

const archiveSlice = createSlice({
  name: ARCHIVE_REDUCER_NAME,
  initialState,
  reducers: {
    fetchInitArchivePageStarted(state) {
      state.status = RequestStatus.FETCHING;
      state.fetchingFirstPage = true;
      state.error = null;
    },
    fetchInitArchivePageSuccess(state, { payload }: PayloadAction<ArchivedEmailDocument[]>) {
      state.status = RequestStatus.SUCCESS;
      state.data = payload;
      state.fetchingFirstPage = false;
      state.isLastPage = payload.length < state.pageSize;
    },
    fetchInitArchivePageFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
      state.fetchingFirstPage = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toggleVisibility.fulfilled, (state, { payload }) => {
      state.data = state.data.map((item) =>
        item.id !== payload.id
          ? item
          : 'status' in payload
            ? { ...item, status: payload.status }
            : { ...item, landingPageVisibility: payload.landingPageVisibility }
      );
    });
    builder.addCase(removeArchivedEmail.pending, (state, { meta }) => {
      state.data = state.data.filter((item) => item.id !== meta.arg.archivedEmailId);
    });
  },
});

export const {
  fetchInitArchivePageStarted,
  fetchInitArchivePageSuccess,
  fetchInitArchivePageFailed,
} = archiveSlice.actions;

export default archiveSlice.reducer;
