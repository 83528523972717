import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
  FIRESTORE_COLLECTION,
  InAppResourcePayload,
  ProductResourceDocument,
} from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { PRODUCT_RESOURCES_REDUCER_NAME } from '../types';

type Payload = {
  productId: string;
  model: InAppResourcePayload;
};

export const addProductResource = createAsyncThunk<
  string,
  ActionParams<Payload, string>,
  AsyncThunkCreator<number>
>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/addProductResource`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { db, auth, analytics } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user) {
        throw new Error('invalid-user');
      }

      const model: ProductResourceDocument = {
        ...payload.model,
        createdAt: new Date(),
        updatedAt: new Date(),
        notifications: [],
        id: uuid(),
      };

      const ref = db
        .collection(FIRESTORE_COLLECTION.USERS)
        .doc(user.uid)
        .collection(FIRESTORE_COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(FIRESTORE_COLLECTION.PRODUCT_RESOURCES)
        .doc(model.id);

      await ref.set(model);

      await analytics.track('add_resource_to_product', {
        productId: payload.productId,
        userId: user.uid,
      });

      onSuccess?.();

      return model.id;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
