import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreateCommunityDTO } from '@mailingr/data-models';

import { ActionParams, AsyncThunkCreator } from '../../../index';
import { COMMUNITIES_REDUCER_NAME } from '../types';

export const createCommunity = createAsyncThunk<
  void,
  ActionParams<Omit<CreateCommunityDTO, 'ownerId'>>,
  AsyncThunkCreator<number>
>(
  `${COMMUNITIES_REDUCER_NAME}/createCommunity`,
  async (
    { payload, onSuccess, onFailure },
    { rejectWithValue, extra: { functions, analytics, auth } }
  ) => {
    try {
      const user = auth().currentUser;

      if (!user || !user.email) {
        throw new Error('user-is-not-logged');
      }
      await functions.httpsCallable('communities-createCommunity')({
        ...payload,
      });
      analytics.track('create_community', {
        userId: user?.uid ?? '',
        email: user?.email ?? '',
      });

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  }
);
