import { CreatorStatsDocument, ProductDocumentV2, RequestStatus } from '@mailingr/data-models';

export const ADMIN_REDUCER_NAME = 'admin';

export interface AdminReducer {
  owners: CreatorStatsDocument[];
  productsByOwnerId: Record<string, ProductDocumentV2[]>;
  productsByOwnerIdStatus: Record<string, RequestStatus | null>;
  ownersListStatus: RequestStatus | null;
  searchTerm: string | null;
  searchStatus: RequestStatus | null;
}
